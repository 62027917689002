import { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Grid,
  IconButton,
  Topbar,
} from "../../components";
import "./main-layout.scss";
import { useImportContext } from "../../contexts/import.context";
import { useMediaQuery } from "@mui/material";
import { MenuLateral } from "./components";

const MainLayout = ({ children, match }) => {
  const id = match.params?.id;
  const location = useLocation();
  const {
    openCollapse,
    setOpenCollapse,
    setOpenCadastros,
    setOpenCompras,
    setOpenVendas,
    setOpenFinanceiro,
    setOpenRelatorios,
    initialCollapses,
  } = useImportContext();
  const [open, setOpen] = useState(openCollapse);
  const [currentPathname, setCurrentPathname] = useState("");

  const verificarCaminho = () => {
    setCurrentPathname(location?.pathname);
  };

  const handleDrawerOpen = () => {
    setOpen((state) => {
      setOpenCadastros((x) => {
        return state === false ? x : false;
      });
      setOpenCompras((x) => {
        return state === false ? x : false;
      });
      setOpenVendas((x) => {
        return state === false ? x : false;
      });
      setOpenFinanceiro((x) => {
        return state === false ? x : false;
      });
      setOpenRelatorios((x) => {
        return state === false ? x : false;
      });
      if (state) initialCollapses();
      setOpenCollapse(!state);
      return !state;
    });
  };

  useEffect(() => {
    verificarCaminho();
  }, []);

  const matches = useMediaQuery("(max-width:1024px)");

  return (
    <div className={clsx({ root: true })}>
      <Topbar handleDrawerOpen={handleDrawerOpen} open={open} />
      {open && matches ? (
        <IconButton
          edge="start"
          color="primary"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            borderRadius: 8,
            zIndex: 1200,
          }}
        >
          <i
            className="ph-fill ph-dots-three-outline-vertical"
            style={{ fontSize: "clamp(17px, 4vw, 22px)" }}
          ></i>
        </IconButton>
      ) : null}
      <MenuLateral
        open={open}
        setOpen={setOpen}
        currentPathname={currentPathname}
        matches={matches}
        id={id}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <main className={clsx("content", open && "content-open")}>
                {children}
              </main>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withRouter(MainLayout);
