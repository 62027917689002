export class PerfilUsuario {
  nome = null;
  permisoes = [];

  constructor(perfilUsuario) {
    this.nome = perfilUsuario.nome;
    this.permisoes = perfilUsuario.permisoes.map(
      (permissao) => new Permissao(permissao)
    );
  }
}

export class Permissao {
  pctModuloId = null;
  editar = false;
  cadastrar = false;
  visualizar = false;
  excluir = false;
  cancelar = false;
  editarPrecoVenda = false;
  vendedorObrigatorio = false;
  limparCarrinho = false;
  excluirItemCarrinho = false;
  utilizarCaixaColaborador = false;
  editarDesconto = false;

  constructor(permissao, moduloId) {
    this.pctModuloId =
      permissao.pctModuloId || permissao.pct_modulo_id || moduloId;
    this.editar = permissao.editar || false;
    this.cadastrar = permissao.cadastrar || false;
    this.visualizar = permissao.visualizar || false;
    this.excluir = permissao.excluir || false;
    this.cancelar = permissao.cancelar || false;
    this.editarPrecoVenda = permissao.editarPrecoVenda || false;
    this.vendedorObrigatorio = permissao.vendedorObrigatorio || false;
    this.limparCarrinho = permissao.limparCarrinho || false;
    this.excluirItemCarrinho = permissao.excluirItemCarrinho || false;
    this.utilizarCaixaColaborador = permissao.utilizarCaixaColaborador || false;
    this.editarDesconto = permissao.editarDesconto || false;
  }
}
