const constantes = {
  modalidadeFrete: [
    {
      codigo: 0,
      value: "mfContaEmitenteOumfContaRemetente",
      label: "0 - Contratação do frete por conta do remetente (CIF)",
    },
    {
      codigo: 1,
      value: "mfContaDestinatario",
      label: "1 - Contratação do frete por conta do destinatário (FOB)",
    },
    {
      codigo: 2,
      value: "mfContaTerceiros",
      label: "2 - Contratação do frete por conta de terceiros",
    },
    {
      codigo: 3,
      value: "mfProprioContaRemente",
      label: "3 - Transporte próprio por conta do remetente",
    },
    {
      codigo: 4,
      value: "mfProprioContaDestinatario",
      label: "4 - Transporte próprio por conta do destinatário",
    },
    {
      codigo: 9,
      value: "mfSemFrete",
      label: "9 - Sem ocorrência de transporte",
    },
  ],
};

export default constantes;
