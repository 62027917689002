import { formatPrice, unformatPrice } from "../../../../utils";
import { useEffect } from "react";
import {
  Grid,
  CurrencyInput,
  Typography,
  TextFielDecimalNumbers,
} from "../../../../components";
import {
  calculaFloor,
  calculoDesconto,
  formatProdutosTotalizadores,
  totalVenda,
} from "../add-nf-saida";

const ResumoTotal = ({
  nfSaidaId,
  nfSaida,
  setNfSaida,
  nfSaidaProdutos,
  setNfSaidaProdutos,
  abaValue,
  permissoes,
  setNfSaidaPagamentos,
}) => {
  useEffect(() => {
    setNfSaida((prevState) => ({
      ...prevState,
      descontoPorcentagem: calculoDesconto(nfSaidaProdutos, prevState, false),
    }));
  }, [nfSaidaProdutos]);

  const handleInputAcrescimo = (event) => {
    if (nfSaidaProdutos?.length) {
      const name = "acrescimo";
      const valor = unformatPrice(event.target.value);
      const totalNota = totalVenda(nfSaida, nfSaidaProdutos, name);
      const listItensAcrescimo = formatProdutosTotalizadores(
        name,
        nfSaidaProdutos,
        valor,
        totalNota
      );
      setNfSaidaProdutos(listItensAcrescimo);
      setNfSaidaPagamentos([]);
    }
  };

  const handleInputDespesas = (event) => {
    if (nfSaidaProdutos?.length) {
      const name = "despesas";
      const valor = unformatPrice(event.target.value);
      const totalNota = totalVenda(nfSaida, nfSaidaProdutos, name);
      const listItensDespesas = formatProdutosTotalizadores(
        name,
        nfSaidaProdutos,
        valor,
        totalNota
      );
      setNfSaidaProdutos(listItensDespesas);
      setNfSaidaPagamentos([]);
    }
  };

  const handleInputDesconto = (event) => {
    if (nfSaidaProdutos?.length) {
      const name = "desconto";
      const valor = unformatPrice(event.target.value);
      if (
        parseFloat(nfSaida[event.target.name].toFixed(2)) !==
        calculaFloor(parseFloat(valor), 2)
      ) {
        const totalNota = totalVenda(nfSaida, nfSaidaProdutos, name);
        const nfSaidaTemp = {
          ...nfSaida,
          [event.target.name]: valor || 0,
        };
        if (event.target.name === "desconto") {
          nfSaidaTemp.descontoPorcentagem = calculoDesconto(
            nfSaidaProdutos,
            nfSaidaTemp,
            false
          );
          const listItensDesconto = formatProdutosTotalizadores(
            name,
            nfSaidaProdutos,
            valor,
            totalNota
          );
          setNfSaida(nfSaidaTemp);
          setNfSaidaProdutos(listItensDesconto);
          setNfSaidaPagamentos([]);
        } else {
          nfSaidaTemp.desconto = calculoDesconto(nfSaidaProdutos, nfSaidaTemp);
          const listItensDesconto = formatProdutosTotalizadores(
            name,
            nfSaidaProdutos,
            nfSaidaTemp?.desconto,
            totalNota
          );
          setNfSaida(nfSaidaTemp);
          setNfSaidaProdutos(listItensDesconto);
          setNfSaidaPagamentos([]);
        }
      }
    }
  };

  const nextFocusInput = (event) => {
    if (event.keyCode === 13) {
      const { form } = event.target;
      const arrayInput = Array.from(form.elements).filter(
        (element) => element.tagName == "INPUT"
      );
      const index = arrayInput.indexOf(event.target);
      let element = arrayInput[index + 1];
      if (element?.disabled) {
        element = arrayInput[index + 2];
      }
      if (element) {
        element.focus();
      } else {
        arrayInput[index].blur();
      }
      event.preventDefault();
    }
  };

  return (
    <form>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className="mx-3"
        columns={15}
      >
        <Grid
          item
          xs={2}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Subtotal:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(nfSaida?.totalMercadoria || 0) || "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
        <Grid
          item
          xs={5}
          className="d-flex align-items-center justify-content-end gap-2"
        >
          <span className="me-2">Desconto:</span>
          <TextFielDecimalNumbers
            id="descontoPorcentagem"
            name="descontoPorcentagem"
            variant="outlined"
            disabled={
              nfSaidaId ||
              abaValue !== 0 ||
              (nfSaida?.documentoImportado && !permissoes?.editarDesconto)
            }
            margin="none"
            size="small"
            value={nfSaida?.descontoPorcentagem ?? 0}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
          <CurrencyInput
            id="desconto"
            name="desconto"
            variant="outlined"
            disabled={
              nfSaidaId ||
              abaValue !== 0 ||
              (nfSaida?.documentoImportado && !permissoes?.editarDesconto)
            }
            margin="none"
            size="small"
            value={nfSaida.desconto ?? 0}
            onBlur={handleInputDesconto}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Acréscimo:</span>
          <CurrencyInput
            id="acrescimo"
            name="acrescimo"
            variant="outlined"
            disabled={
              nfSaidaId || abaValue !== 0 || nfSaida?.documentoImportado
            }
            margin="none"
            size="small"
            value={nfSaida?.acrescimo}
            onBlur={handleInputAcrescimo}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className="d-flex align-items-center justify-content-end"
        >
          <span className="me-2">Despesas:</span>
          <CurrencyInput
            id="despesas"
            name="despesas"
            variant="outlined"
            disabled={
              nfSaidaId || abaValue !== 0 || nfSaida?.documentoImportado
            }
            margin="none"
            size="small"
            value={nfSaida?.despesas}
            onBlur={handleInputDespesas}
            onKeyDown={nextFocusInput}
          />
        </Grid>
        <Grid item xs={2} className="d-flex align-items-center">
          <span className="me-2">Total:</span>
          <span className="badge bg-primary bg-gradient bg-gradient text-wrap p-2">
            <Typography color="white" style={{ fontWeight: 700 }}>
              {formatPrice(nfSaida?.totalVenda || 0) || "R$ 0,00"}
            </Typography>
          </span>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResumoTotal;
