import { useState, useEffect } from "react";
import { Dialog, DialogContent, DataGrid } from "../../../components";
import { ListLayout } from "../../../layouts";
import {
  ClassificacaoFinanceiraService,
  notification,
} from "../../../services";
import { responseErros } from "../../../utils";
import AddClassificacaoFinanceira from "../addClassificacaoFinanceira/add-classificacao-financeira.view";
import { FormFiltroAvancado } from "./components";

const ListClassificacaoFinanceira = () => {
  const [id, setId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [classificacoesFinanceiras, setClassificacoesFinanceiras] = useState(
    []
  );

  const colunas = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1000,
      filtrar: true,
    },
    {
      field: "ativado",
      headerName: "Situação",
      flex: 500,
      valueGetter: (params) => (params.value ? "Ativado" : "Desativado"),
    },
  ];
  const classificacaoFinanceiraService = new ClassificacaoFinanceiraService();

  useEffect(() => {
    buscaClassificacaoFinanceira();
  }, []);

  const buscaClassificacaoFinanceira = async function (filter) {
    setLoading(true);
    const result = await classificacaoFinanceiraService.getAll(filter);
    if (!result.isAxiosError) {
      setClassificacoesFinanceiras(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaClassificacaoFinanceira(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickDeleteClassificacaoFinanceira = async function (id) {
    const classificacao = classificacoesFinanceiras.find(
      (item) => item.id === id
    );
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${classificacao.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await classificacaoFinanceiraService.deletar(id);
      if (!result.isAxiosError) {
        buscaClassificacaoFinanceira();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const result = await classificacaoFinanceiraService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setClassificacoesFinanceiras(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <ListLayout
      title="Classificacao Financeira"
      loading={loading}
      onClickCadastrar={handleClickOpenDialog}
      permisaoCadastrar={true}
    >
      <DataGrid
        rows={classificacoesFinanceiras}
        columns={colunas}
        onClickEdit={handleClickOpenDialog}
        onClickDelete={onClickDeleteClassificacaoFinanceira}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <AddClassificacaoFinanceira
            id={id}
            handleCloseDialog={handleCloseDialog}
            openDialog={openDialog}
            buscaListaClassificacaoFinanceira={buscaClassificacaoFinanceira}
          />
        </DialogContent>
      </Dialog>
    </ListLayout>
  );
};

export default ListClassificacaoFinanceira;
