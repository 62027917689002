import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { Grid, Tooltip, Typography, DataGrid } from "../../../components";
import {
  history,
  PedidosService,
  PessoaService,
  notification,
  OperacaoFiscalService,
} from "../../../services";
import { formatDate, formatPrice, responseErros } from "../../../utils";
import { FormFiltroAvancado, ImportarOrcamentoDialog } from "./components";
import shortid from "shortid";
import { format } from "date-fns";
import { filtrosUrl } from "../../../helpers";

const ListPedidosView = () => {
  const [loading, setLoading] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState([]);
  const [openImportarOrcamentoDialog, setOpenImportarOrcamentoDialog] =
    useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [listaPessoas, setListaPessoas] = useState([]);
  const pedidosService = new PedidosService();
  const pessoaService = new PessoaService();
  const operacaoFiscalService = new OperacaoFiscalService();
  const buttonSelectOpcoes = ["Importar Orçamento"];
  const status = [
    {
      id: 1,
      nome: "Aberto",
      cor: "#4FBB53",
    },
    {
      id: 2,
      nome: "Fechado",
      cor: "#004780",
    },
    {
      id: 3,
      nome: "Parcialmente Fechado",
      cor: "#EA9D2B",
    },
    {
      id: 4,
      nome: "Cancelado",
      cor: "#DC3545",
    },
  ];
  const colunas = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        switch (params.value) {
          case "ABERTO":
            return (
              <Tooltip title="Aberto" arrow placement="right">
                <i
                  className="ph-fill ph-circle"
                  style={{ color: "#4FBB53", fontSize: 18 }}
                ></i>
              </Tooltip>
            );
          case "FECHADO":
            return (
              <Tooltip title="Fechado" arrow placement="right">
                <i
                  className="ph-fill ph-circle"
                  style={{ color: "#004780", fontSize: 18 }}
                ></i>
              </Tooltip>
            );
          case "PARCIALMENTE_FECHADO":
            return (
              <Tooltip title="Parcialmente Fechado" arrow placement="right">
                <i
                  className="ph-fill ph-circle"
                  style={{ color: "#EA9D2B", fontSize: 18 }}
                ></i>
              </Tooltip>
            );
          case "CANCELADO":
            return (
              <Tooltip title="Cancelado" arrow placement="right">
                <i
                  className="ph-fill ph-circle"
                  style={{ color: "#DC3545", fontSize: 18 }}
                ></i>
              </Tooltip>
            );
          default:
            return;
        }
      },
    },
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 90,
    },
    {
      field: "numeroDocumento",
      headerName: "Número do Doc",
      flex: 90,
      filtrar: true,
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      flex: 110,
      filtrar: true,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "pessoaClienteId",
      headerName: "Cliente",
      width: 250,
      sortable: false,
      valueGetter: (params) =>
        listaPessoas.find((item) => item.id == params.value)?.nomeRazaoSocial,
    },
    {
      field: "itens",
      headerName: "Total do Pedido",
      flex: 200,
      sortable: false,
      valueGetter: (params) => {
        const totalPedido =
          params.value
            .map((item) => item.valorUnitario * item.quantidade)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalPedido);
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarPedidos(filtros);
    buscarOperacoesFiscais();
  }, []);

  const buscarPedidos = async function (filtro) {
    setLoading(true);
    const result = await pedidosService.getAll(filtro);
    if (!result.isAxiosError) {
      setPedidos(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosPedido(result.data.rows);
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosPedido = (listaDocumento) => {
    const idsPessoas = [
      ...new Set(
        listaDocumento
          .map(({ pessoaClienteId }) => (pessoaClienteId ? pessoaClienteId : 0))
          .filter(
            (pessoaClienteId, index, listaDocumento) =>
              listaDocumento.indexOf(pessoaClienteId) === index
          )
      ),
    ];
    buscarPessoas(idsPessoas);
  };

  const buscarPessoas = (listaIds) => {
    if (listaIds.length) {
      const filtro = {
        id: listaIds,
        nonPaginated: true,
      };
      pessoaService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setListaPessoas(result.data);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarOperacoesFiscais = (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickCadastrarPedidos = function (descricao) {
    switch (descricao) {
      case "Importar Orçamento":
        setOpenImportarOrcamentoDialog(true);
        break;
      default:
        history.push("/faturamento/pedidos/create");
        break;
    }
  };

  const onClickEditPedidos = function (id) {
    history.push(`/faturamento/pedidos/${id}`);
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    const result = await pedidosService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setPedidos(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosPedido(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      delete props.filtros.status;
      buscarPedidos(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickCancelarPedido = async function (id) {
    const pedido = pedidos.find((item) => item.id === id);
    if (pedido) {
      if (pedido.status === "CANCELADO") {
        return notification.alertaGenericos("Esse pedido já está cancelado");
      }
      const retornoAlerta = await notification.confirmacao(
        "Cancelar!",
        "Tem certeza que deseja cancelar esse pedido?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const response = await pedidosService.cancelar(pedido.id);
        setLoading(false);
        if (!response.isAxiosError) {
          notification.alteracaoSucesso();
          buscarPedidos();
        } else {
          responseErros(response);
        }
      }
    }
  };

  return (
    <ListLayout
      title="Pedidos"
      loading={loading}
      onClickCadastrar={onClickCadastrarPedidos}
      buttonSelectOpcoes={buttonSelectOpcoes}
    >
      <Grid container spacing={2}>
        {status.map((status) => (
          <span className="d-flex m-3" key={shortid.generate()}>
            <i
              className="ph-fill ph-circle"
              style={{ color: status.cor, fontSize: 18, marginRight: 8 }}
            ></i>
            <Typography className="me-5">{status.nome}</Typography>
          </span>
        ))}
      </Grid>
      <DataGrid
        rows={pedidos}
        columns={colunas}
        onClickEdit={onClickEditPedidos}
        onClickDelete={onClickCancelarPedido}
        deleteShow={true}
        rowCount={rowCount}
        paginationMode={"server"}
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <ImportarOrcamentoDialog
        openImportarOrcamentoDialog={openImportarOrcamentoDialog}
        setOpenImportarOrcamentoDialog={setOpenImportarOrcamentoDialog}
        setSelecionados={setSelecionados}
        selecionados={selecionados}
        setOrcamentoSelecionado={setOrcamentoSelecionado}
        orcamentoSelecionado={orcamentoSelecionado}
        setLoading={setLoading}
        loading={loading}
      />
    </ListLayout>
  );
};

export default ListPedidosView;
