import { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "../../components";
import { SessionService, history } from "../../services";
import {
  ResumoContasReceber,
  ResumoContasPagar,
  ResumoVendasHoraMes,
} from "./components";
import { PermisoesHelper } from "../../helpers";
import "./main.view.scss";
import store from "../../config/store";

const Main = () => {
  const sessionService = new SessionService();
  const permisoesHelper = new PermisoesHelper();
  const [usuario, setUsuario] = useState("");
  const [permissaoDashboard, setPermissaoDashboard] = useState(false);
  useEffect(() => {
    setUsuario(sessionService.getUsuario());
    getPermissaoDashboard();
  }, []);

  const menu = [
    {
      title: "Pessoas",
      path: "/integracao/pessoas",
      icon: "ph-fill ph-users",
      permission: "pessoas",
    },
    {
      title: "Produtos",
      path: "/estoque/produtos",
      icon: "ph-fill ph-package",
      permission: "produtos",
    },
    {
      title: "Orçamentos",
      path: "/faturamento/orcamentos",
      icon: "ph-fill ph-receipt",
      permission: "orcamentos",
    },
    {
      title: "Pedidos",
      path: "/faturamento/pedidos",
      icon: "ph-fill ph-file-text",
      permission: "pedidos",
    },
    {
      title: "NF Saída",
      path: "/faturamento/nf-saida",
      icon: "ph-fill ph-scroll",
      permission: "nf-saida",
    },
    {
      title: "Caixa",
      path: "/financeiro/caixa",
      icon: "ph-fill ph-desktop",
      permission: "caixa",
    },
  ];

  function getMenusComPermissao(menu) {
    return menu.map((menuItem) => {
      const temPermissao = permisoesHelper.temPermisaoMenu(menuItem.permission);
      return {
        ...menuItem,
        disabled: !temPermissao,
      };
    });
  }

  function getPermissaoDashboard() {
    const temPermissao = permisoesHelper.temPermisaoMenu("dashboard");
    setPermissaoDashboard(temPermissao);
  }

  const images = {
    logo: require("../../assets/images/icon-lite.svg"),
  };

  return (
    <Container>
      <Typography
        variant="subtitle1"
        className="mb-4"
        style={{ color: "#263238" }}
      >
        Olá, {usuario.nome}! Veja como estão as coisas.
      </Typography>
      <Grid container spacing={3} className="mb-4">
        {getMenusComPermissao(menu).map((item, index) => (
          <Grid item xs={4} md={2} key={index} className="atalhos-main-view">
            <Button
              className={`rounded-4 mainAtalhos ${
                item.disabled ? "mainAtalhosDisabled" : ""
              }`}
              fullWidth
              disabled={item.disabled}
              startIcon={
                <i
                  className={`${item.icon} mainAtalhos-icon-main ${
                    item.disabled ? "mainAtalhosDisabled-icon" : ""
                  }`}
                ></i>
              }
              onClick={() => {
                history.push(item.path);
              }}
            >
              <Typography
                style={{ paddingLeft: 10 }}
                className={`mainAtalhos-text-main ${
                  item.disabled ? "mainAtalhosDisabled-text" : ""
                }`}
              >
                {item.title}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
      {permissaoDashboard ? (
        <Grid container spacing={3} className="mb-3">
          <Grid item xs={12}>
            <ResumoVendasHoraMes />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResumoContasReceber />
          </Grid>
          <Grid item xs={12} md={6}>
            <ResumoContasPagar />
          </Grid>
        </Grid>
      ) : (
        <Grid container className="mb-3 py-5 justify-content-center">
          <img src={images.logo.default} className="img-logo-dashboard" />
        </Grid>
      )}
      <footer className="versao">Versão Siaf Lite - {store?.version}</footer>
    </Container>
  );
};

export default Main;
