import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  history,
  ModeloTributacaoService,
  notification,
} from "../../../services";
import { FormFiltroAvancado } from "./components";
import { cacheApi, responseErros } from "../../../utils";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListModeloTributacaoView = () => {
  const modeloTributacaoService = new ModeloTributacaoService();
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [modelosTributacao, setModelosTributacao] = useState([]);
  const { cacheDto, setCacheDto } = useCacheContext();
  const colunas = [
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 1000,
      filtrar: true,
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaModelosTributacao(filtros);
  }, []);

  const buscaModelosTributacao = async function (filter) {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: modeloTributacaoService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setModelosTributacao(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarModeloTributacao = function () {
    history.push("/fiscal/modelos-tributacao/create");
  };

  const onClickEditModeloTributacao = function (id) {
    history.push(`/fiscal/modelos-tributacao/${id}`);
  };

  const onClickDeleteModeloTributacao = async function (id) {
    const data = modelosTributacao.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir o modelo de tributação "${data.descricao}"`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await modeloTributacaoService.deletar(id);
      if (!result.isAxiosError) {
        buscaModelosTributacao();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: modeloTributacaoService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setModelosTributacao(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaModelosTributacao(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Modelo Tributação"
      loading={loading}
      onClickCadastrar={onClickCadastrarModeloTributacao}
    >
      <DataGrid
        rows={modelosTributacao}
        columns={colunas}
        onClickEdit={onClickEditModeloTributacao}
        onClickDelete={onClickDeleteModeloTributacao}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListModeloTributacaoView;
