import { useState, useEffect } from "react";
import { Dialog, DialogContent, DataGrid } from "../../../components";
import { ListLayout } from "../../../layouts";
import { value } from "../../../helpers";
import {
  GrupoPessoasService,
  TiposPessoasService,
  notification,
} from "../../../services";
import { cacheApi, responseErros } from "../../../utils";
import AddGrupoPessoas from "../addGrupoPessoas/addGrupoPessoas.view";
import { FormFiltroAvancado } from "./components";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListGrupoPessoas = () => {
  const [id, setId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [gruposPessoas, setGruposPessoas] = useState([]);
  const { cacheDto, setCacheDto } = useCacheContext();
  const [tiposPessoaOptions, setTiposPessoaOptions] = useState([]);
  const tiposPessoasService = new TiposPessoasService();
  const colunas = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 1000,
      filtrar: true,
    },
    {
      field: "tipoPessoa",
      headerName: "Tipo de Pessoa",
      flex: 500,
      valueGetter: (params) =>
        value.autoComplete(tiposPessoaOptions, params.row.tipoPessoa).descricao,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) => (params.value ? "Ativado" : "Desativado"),
    },
  ];
  const grupoPessoasService = new GrupoPessoasService();

  useEffect(() => {
    buscaGruposPessoas();
    buscaTipoPessoa();
  }, []);

  const buscaTipoPessoa = async () => {
    const cacheBody = {
      timeMinutes: 60,
      apiFunction: tiposPessoasService.getAll(),
      dto: { cacheDto, setCacheDto },
    };
    const res = await cacheApi(tiposPessoasService.urlBase, cacheBody);
    if (!res.isAxiosError) {
      setTiposPessoaOptions(res.data);
    }
  };

  const buscaGruposPessoas = async function (filter) {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: grupoPessoasService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setGruposPessoas(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleClickOpenDialog = (id) => {
    setId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaGruposPessoas(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickDeleteGrupoPessoas = async function (id) {
    const grupo = gruposPessoas.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${grupo.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await grupoPessoasService.deletar(id);
      if (!result.isAxiosError) {
        buscaGruposPessoas();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: grupoPessoasService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setGruposPessoas(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <ListLayout
      title="Grupo de pessoas"
      loading={loading}
      onClickCadastrar={handleClickOpenDialog}
    >
      <DataGrid
        rows={gruposPessoas}
        columns={colunas}
        onClickEdit={handleClickOpenDialog}
        onClickDelete={onClickDeleteGrupoPessoas}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        PaperComponent="div"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <AddGrupoPessoas
            id={id}
            handleCloseDialog={handleCloseDialog}
            openDialog={openDialog}
            buscaGruposPessoas={buscaGruposPessoas}
          />
        </DialogContent>
      </Dialog>
    </ListLayout>
  );
};

export default ListGrupoPessoas;
