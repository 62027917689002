import { useState, useEffect } from "react";
import { DataGrid } from "../../../components";
import { ListLayout } from "../../../layouts";
import {
  history,
  PerfilsUsuariosService,
  notification,
} from "../../../services";
import { responseErros } from "../../../utils";
import { FormFiltroAvancado } from "./components";

const ListPerfilsUsuarios = () => {
  const [perfilUsuarios, setPerfilUsuarios] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const colunas = [
    { field: "nome", headerName: "Nome", flex: 1000, filtrar: true },
  ];
  const perfilsUsuariosService = new PerfilsUsuariosService();

  useEffect(() => {
    const filtros = {
      padrao: "false",
    };
    buscaPerfilUsuarios(filtros);
  }, []);

  const buscaPerfilUsuarios = async (filtro) => {
    setLoading(true);
    const result = await perfilsUsuariosService.getAll(filtro);
    if (!result.isAxiosError) {
      setPerfilUsuarios(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarPerfilUsuario = () => {
    history.push(`perfis-usuarios/create`);
  };

  const onClickEditPerfilUsuario = (id) => {
    const perfilUsuario = perfilUsuarios.find((perfil) => perfil.id == id);
    if (perfilUsuario.padrao) {
      notification.alertaGenericos(
        "Não é possível alterar perfil administrador"
      );
    } else {
      history.push(`perfis-usuarios/${id}`);
    }
  };

  const onClickDeletePerfilUsuario = async (id) => {
    const perfilUsuario = perfilUsuarios.find((perfil) => perfil.id == id);
    if (perfilUsuario.padrao) {
      notification.alertaGenericos(
        "Não é possível remover perfil administrador"
      );
      return;
    }
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir?"
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await perfilsUsuariosService.deletar(id);
      if (!result.isAxiosError) {
        const filtros = {
          padrao: "false",
        };
        buscaPerfilUsuarios(filtros);
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaPerfilUsuarios(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Perfil Usuários"
      loading={loading}
      onClickCadastrar={onClickCadastrarPerfilUsuario}
    >
      <DataGrid
        rows={perfilUsuarios}
        columns={colunas}
        onClickEdit={onClickEditPerfilUsuario}
        onClickDelete={onClickDeletePerfilUsuario}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListPerfilsUsuarios;
