import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  FormControl,
  Button,
  Tooltip,
  Autocomplete,
  Loading,
} from "../../../components";
import { CentroCustoService, notification } from "../../../services";
import { InputErros, value as getInputValue } from "../../../helpers";
import { responseErros } from "../../../utils";

import centroCustoValidator from "./middlewares/centro-custo.validator";
import { CentroCusto } from "../entities";

const AddCentroCusto = ({ id, handleCloseDialog, buscaCentrosCustos }) => {
  const [loading, setLoading] = useState(false);
  const [centroCusto, setCentroCusto] = useState({});
  const [centroSuperiorList, setCentroSuperiorList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [idPaiDisabled, setIdPaiDisabled] = useState(false);
  const inputErros = new InputErros(inputErro, setInputErro);
  const centroCustoService = new CentroCustoService();

  useEffect(() => {
    buscarListaCentrosCusto();
    buscarCentroCusto();
  }, []);

  const buscarCentroCusto = async () => {
    if (!id) return;
    setLoading(true);
    const result = await centroCustoService.getById(id);
    if (!result.isAxiosError) {
      setCentroCusto(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarListaCentrosCusto = async (filtros) => {
    const filtro = { ...filtros, nonPaginated: true };
    const result = await centroCustoService.getAll(filtro);
    if (!result.isAxiosError) {
      if (id) setIdPaiDisabled(true);
      const filteredList = result.data.filter((item) => item?.id !== id);
      setCentroSuperiorList(filteredList);
    } else responseErros(result);
  };

  const handleInputChange = (event) => {
    setCentroCusto((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    centroCustoValidator
      .validate(centroCusto, { abortEarly: false })
      .then(async () => {
        const dadosCentroCusto = new CentroCusto(centroCusto);
        if (id) {
          const response = await centroCustoService.atualizar(
            id,
            dadosCentroCusto
          );
          setLoading(false);
          if (!response.isAxiosError) {
            buscaCentrosCustos();
            handleCloseDialog();
            setIdPaiDisabled(false);
            notification.alteracaoSucesso();
          } else {
            responseErros(response);
          }
        } else {
          const response = await centroCustoService.cadastrar(dadosCentroCusto);
          setLoading(false);
          if (!response.isAxiosError) {
            buscaCentrosCustos();
            handleCloseDialog();
            setIdPaiDisabled(false);
            notification.cadastroSucesso();
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setCentroCusto({});
    setCentroSuperiorList([]);
    handleCloseDialog();
  };

  return (
    <>
      <Card className="p-3">
        <CardHeader
          title={id ? "Editar Centro de Custo" : "Cadastrar Centro de Custo"}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="descricao"
                name="descricao"
                label="Descricao"
                variant="outlined"
                value={centroCusto?.descricao ? centroCusto.descricao : ""}
                onChange={(event) => {
                  setInputErro([]);
                  handleInputChange(event);
                }}
                fullWidth
                required
                error={inputErros.get("descricao")}
              />
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title="Selecione o Centro de Custo superior (opcional)"
                arrow
                placement="top"
              >
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    id="centroSuperior"
                    name="centroSuperior"
                    options={centroSuperiorList}
                    autoHighlight
                    disabled={idPaiDisabled}
                    getOptionLabel={(option) =>
                      option?.descricao ? option.descricao : ""
                    }
                    noOptionsText="Sem opções"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Centro de Custo Pai"
                        variant="outlined"
                      />
                    )}
                    onChange={(_, newValue) => {
                      setCentroCusto((prevState) => ({
                        ...prevState,
                        idPai: newValue?.id,
                      }));
                    }}
                    value={getInputValue.autoComplete(
                      centroSuperiorList,
                      centroCusto?.idPai
                    )}
                  />
                </FormControl>
              </Tooltip>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Salvar
          </Button>
          <Button color="padrao" variant="contained" onClick={handleCancelar}>
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </Card>
      <Loading loading={loading} />
    </>
  );
};

export default AddCentroCusto;
