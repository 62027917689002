import { useReactToPrint } from "react-to-print";
import { MainPrintLayout } from ".";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Loading,
  MiniPrintLayoutFaturamento,
} from "../../../../components";
import shortid from "shortid";
import { useRef, useState } from "react";
import { notification } from "../../../../services";

const MenuAddActions = ({
  id,
  anchorEl,
  setAnchorEl,
  nfSaida,
  onClickCancelarNfSaida,
  onClickCartaCorrecao,
  onClickBaixarXml,
  permissoes,
}) => {
  const [isImpressaoA4, setIsImpressaoA4] = useState(true);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const opcoesMenu = [
    <MenuItem
      className="mb-1 mt-3"
      onClick={escolhaImpressao}
      sx={{ height: 20, borderRadius: 5 }}
      name="imprimirResumo"
      key={shortid.generate()}
    >
      <ListItemIcon style={{ minWidth: 42 }}>
        <i className="ph-fill ph-printer" style={{ fontSize: 16 }}></i>
      </ListItemIcon>
      <span style={{ fontSize: 12 }}>Imprimir Resumo DF-e</span>
    </MenuItem>,
    <MenuItem
      className="mb-1 mt-3"
      onClick={() => onClickCancelarNfSaida(nfSaida)}
      name="cancelarDfe"
      disabled={
        nfSaida?.status !== "ENVIADA" &&
        nfSaida?.status !== "FATURADA" &&
        nfSaida?.status !== "PENDENTE"
      }
      sx={{ height: 20, borderRadius: 5 }}
      key={shortid.generate()}
    >
      <ListItemIcon style={{ minWidth: 42 }}>
        <i className="ph-bold ph-x-circle" style={{ fontSize: 16 }}></i>
      </ListItemIcon>
      <span style={{ fontSize: 12 }}>Cancelar DF-e</span>
    </MenuItem>,
    <MenuItem
      className="mb-1 mt-3"
      onClick={onClickCartaCorrecao}
      name="cartaCorrecao"
      disabled={
        nfSaida.status !== "ENVIADA" || nfSaida.modeloDocumento !== "55"
      }
      sx={{ height: 20, borderRadius: 5 }}
      key={shortid.generate()}
    >
      <ListItemIcon style={{ minWidth: 42 }}>
        <i className="ph-bold ph-envelope-simple" style={{ fontSize: 16 }}></i>
      </ListItemIcon>
      <span style={{ fontSize: 12 }}>Carta de Correção</span>
    </MenuItem>,
    <MenuItem
      className="mb-1 mt-3"
      onClick={onClickBaixarXml}
      name="baixarXml"
      disabled={nfSaida?.status !== "ENVIADA"}
      sx={{ height: 20, borderRadius: 5 }}
      key={shortid.generate()}
    >
      <ListItemIcon style={{ minWidth: 42 }}>
        <i className="ph-bold ph-file-arrow-down" style={{ fontSize: 16 }}></i>
      </ListItemIcon>
      <span style={{ fontSize: 12 }}>Baixar XML</span>
    </MenuItem>,
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  async function escolhaImpressao() {
    handleAddActionsClose();
    const retornoAlerta = await notification.confirmacaoGenericos({
      title: "Selecione um modelo de impressão",
      icon: "info",
      confirmButtonText: `
        <i class="ph-fill ph-article" style="font-size:40px; color: white;"></i> <p style="margin: 0">A4</p>
      `,
      cancelButtonText: `
        <i class="ph-fill ph-scroll" style="font-size:40px; color: white;"></i> <p style="margin: 0">80mm</p>
      `,
      showCancelButton: true,
    });
    setLoading(true);
    if (retornoAlerta.isConfirmed) {
      setIsImpressaoA4(true);
      setTimeout(() => {
        setLoading(false);
        handlePrint();
      }, 1000);
      return;
    }
    if (retornoAlerta.dismiss === "cancel") {
      setIsImpressaoA4(false);
      setTimeout(() => {
        setLoading(false);
        handlePrint();
      }, 1000);
      return;
    }
    setLoading(false);
  }

  function handleAddActionsClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        getContentAnchorEl={null}
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        autoFocus={false}
        open={isMenuOpen}
        onClose={handleAddActionsClose}
        className="m-3"
      >
        {opcoesMenu
          .map((op) => op)
          .filter(
            ({ props }) => permissoes?.cancelar || props.name !== "cancelarDfe"
          )}
      </Menu>
      {id && (
        <div className="d-none">
          <div ref={componentRef}>
            {isImpressaoA4 ? (
              <MainPrintLayout data={nfSaida} />
            ) : (
              <MiniPrintLayoutFaturamento
                data={nfSaida}
                descricaoTipoDocumento="nf-saida"
              />
            )}
          </div>
        </div>
      )}
      <button
        id="botaoMenuAction"
        className="d-none"
        onClick={handlePrint}
      ></button>
      <Loading loading={loading} />
    </div>
  );
};

export default MenuAddActions;
