import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItemIcon,
  Typography,
  Card,
  CardContent,
  IconButton,
  Tooltip,
} from "../../components";

const AcordeonComponent = (props) => {
  const [listChildren, setListChildren] = useState([]);
  useEffect(() => {
    if (props.items.length) {
      const lista = props.items.filter((item) => item.idPai === props.item.id);
      setListChildren(lista);
    }
  }, [props.items]);

  const handleExpandChange = (idItem, isExpanded) => {
    const expandedTemp = { ...props.expanded };
    expandedTemp[idItem] = isExpanded;
    props.setExpanded(expandedTemp);
  };

  return (
    <Accordion
      className="shadow"
      onChange={(event, isExpanded) =>
        handleExpandChange(props.item.id, isExpanded)
      }
      expanded={props.expanded[props.item.id] ? true : false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        className="px-3 d-flex"
        expandIcon={
          <i
            className="ph-fill ph-caret-down"
            style={{ color: "#494C62", fontSize: 18 }}
          ></i>
        }
        aria-label="Expand"
        aria-controls={`panel${props.item.id}a-content`}
        id={`panel${props.item.id}a-header`}
      >
        <Typography
          style={{ paddingLeft: `${props.nivel}px` }}
          className={`pt-1 w-100 justify-content-start align-items-center`}
        >
          {`${props.item.codigo} - ${props.item.descricao}`}
        </Typography>

        <ListItemIcon>
          <span className="me-2">
            <IconButton
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                props.handleEditItem(props.item.id);
              }}
            >
              <Tooltip title="Editar" arrow placement="right">
                <i
                  className="ph-fill ph-pencil"
                  style={{
                    fontSize: 18,
                  }}
                ></i>
              </Tooltip>
            </IconButton>
          </span>
          <span className="me-2">
            <IconButton
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                props.handleDeleteItem(props.item.id);
              }}
            >
              <Tooltip title="Excluir" arrow placement="right">
                <i
                  className="ph-fill ph-trash"
                  style={{
                    fontSize: 18,
                  }}
                ></i>
              </Tooltip>
            </IconButton>
          </span>
        </ListItemIcon>
      </AccordionSummary>
      <AccordionDetails className="d-flex flex-column p-0">
        {listChildren.length > 0 &&
          listChildren.map((item) => {
            return (
              <AcordeonComponent
                key={item.id}
                item={item}
                items={props.items}
                nivel={props.nivel + 10}
                handleEditItem={props.handleEditItem}
                handleDeleteItem={props.handleDeleteItem}
                expanded={props.expanded}
                setExpanded={props.setExpanded}
              />
            );
          })}
      </AccordionDetails>
    </Accordion>
  );
};

const AcordeonListComponent = (props) => {
  const [acordeonList, setAcordeonList] = useState([]);

  useEffect(() => {
    if (props.items.length) {
      const lista = props.items.filter(
        (item) => item.idPai === 0 || item.idPai === null
      );
      setAcordeonList(lista);
    }
  }, [props.items]);

  return (
    <>
      {acordeonList.length ? (
        acordeonList.map((item) => {
          return (
            <AcordeonComponent
              key={item.id}
              item={item}
              nivel={0}
              items={props.items}
              handleEditItem={props.handleEditItem}
              handleDeleteItem={props.handleDeleteItem}
              expanded={props.expanded}
              setExpanded={props.setExpanded}
            />
          );
        })
      ) : (
        <Card className="p-2">
          <CardContent>Nenhum Item Cadastrado</CardContent>
        </Card>
      )}
    </>
  );
};

export default AcordeonListComponent;
