import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  history,
  NfEntradaService,
  PessoaService,
  notification,
  ContasPagarService,
  CaixaService,
} from "../../../services";
import { formatDate, responseErros, formatPrice } from "../../../utils";
import {
  FormFiltroAvancado,
  ImportarXmlDialog,
  UsoConsumoDialog,
} from "./components";
import { totalCompra } from "../add-nf-entrada/add-nf-entrada";
import { filtrosUrl } from "../../../helpers";

const ListNfEntrada = () => {
  const [loading, setLoading] = useState(false);
  const [nfsEntrada, setNfsEntrada] = useState([]);
  const [listaPessoas, setListaPessoas] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [openImportarXMLDialog, setOpenImportarXMLDialog] = useState(false);
  const [openUsoConsumoDialog, setOpenUsoConsumoDialog] = useState(false);
  const nfEntradaService = new NfEntradaService();
  const contasPagarService = new ContasPagarService();
  const caixaService = new CaixaService();
  const pessoaService = new PessoaService();
  const buttonSelectOpcoes = ["Importar XML", "Nota de Uso e Consumo"];

  const colunas = [
    {
      field: "numeroNota",
      headerName: "Nº Nota",
      flex: 300,
      filtrar: true,
    },
    {
      field: "fornecedorId",
      headerName: "Fornecedor",
      flex: 500,
      valueGetter: (params) => {
        const fornecedor = listaPessoas.find((item) => item.id == params.value);
        if (!fornecedor) return params.value;
        return fornecedor.nomeRazaoSocial;
      },
    },
    {
      field: "dataEmissao",
      headerName: "Data Emissão",
      flex: 300,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "dataChegada",
      headerName: "Data Chegada",
      flex: 300,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "totalNfEntrada",
      headerName: "Total Compra",
      flex: 300,
      valueGetter: (params) => {
        const total = totalCompra(
          params.row,
          params.row.nfEntradaItens,
          params.row.nfEntradaTributos
        );
        return formatPrice(total);
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarNfsEntrada(filtros);
    buscarPessoas();
  }, []);

  const buscarNfsEntrada = async function (filtro) {
    setLoading(true);
    const result = await nfEntradaService.getAll(filtro);
    if (!result.isAxiosError) {
      setNfsEntrada(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarPessoas = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      if (!res.isAxiosError) {
        setListaPessoas(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const onClickCadastrarNfEntrada = function (descricao) {
    switch (descricao) {
      case "Importar XML":
        setOpenImportarXMLDialog(true);
        break;
      case "Nota de Uso e Consumo":
        setOpenUsoConsumoDialog(true);
        break;
      default:
        history.push("/estoque/nf-entrada/create");
        break;
    }
  };

  const onClickEditarNfEntrada = function (id) {
    history.push(`/estoque/nf-entrada/${id}`);
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    const result = await nfEntradaService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setNfsEntrada(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarNfsEntrada(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const onClickRemoverNfEntrada = async (id) => {
    setLoading(true);
    const listaContasPagar = await contasPagarService.getAll({
      nfEntradaId: id,
    });
    setLoading(false);
    if (listaContasPagar.data?.count > 0) {
      if (listaContasPagar.data.rows[0].status !== "ABERTO") {
        const confirmacaoAlerta = await notification.confirmacao(
          "Confirmação!",
          "Constatamos titulo(s) já baixado(s). Deseja remover esse documento de entrada mesmo assim?"
        );
        if (confirmacaoAlerta.isConfirmed) {
          const caixaAberto = await caixaService.caixaAberto(
            listaContasPagar.data.rows[0].dataLancamento,
            listaContasPagar.data.rows[0].bancoCaixaId
          );
          if (caixaAberto.data) {
            removerNfEntrada(id);
          } else {
            notification.alertaGenericos(
              "Alteração não permitida, duplicata vinculada a caixa fechado"
            );
          }
        }
      } else {
        removerNfEntrada(id);
      }
    } else {
      removerNfEntrada(id);
    }
    buscarNfsEntrada();
  };

  const removerNfEntrada = async (id) => {
    setLoading(true);
    const response = await nfEntradaService.remover(id);
    setLoading(false);
    if (!response.isAxiosError) {
      notification.deletadoSucesso();
      buscarNfsEntrada();
      buscarPessoas();
      return;
    } else {
      responseErros(response);
    }
  };

  return (
    <ListLayout
      title="NF's de Entrada"
      loading={loading}
      onClickCadastrar={onClickCadastrarNfEntrada}
      buttonSelectOpcoes={buttonSelectOpcoes}
    >
      <DataGrid
        rows={nfsEntrada}
        columns={colunas}
        onClickEdit={onClickEditarNfEntrada}
        onClickDelete={onClickRemoverNfEntrada}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <ImportarXmlDialog
        openImportarXMLDialog={openImportarXMLDialog}
        setOpenImportarXMLDialog={setOpenImportarXMLDialog}
        loading={loading}
        setLoading={setLoading}
      />
      <UsoConsumoDialog
        openUsoConsumoDialog={openUsoConsumoDialog}
        setOpenUsoConsumoDialog={setOpenUsoConsumoDialog}
        setOpenImportarXMLDialog={setOpenImportarXMLDialog}
      />
    </ListLayout>
  );
};

export default ListNfEntrada;
