import { useEffect, useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import { responseErros } from "../../../utils";
import {
  FormFiltroAvancadoCaixa,
  FormFiltroAvancadoContasPagar,
  FormFiltroAvancadoContasReceber,
} from "./components";
import {
  CaixaService,
  ConfiguracoesService,
  ContasPagarService,
  ContasReceberService,
} from "../../../services";
import {
  PrintCaixaDetalhado,
  PrintContasPagarDetalhado,
  PrintContasReceberDetalhado,
} from "../layouts";
const contasPagarService = new ContasPagarService();
const contasReceberService = new ContasReceberService();
const caixaService = new CaixaService();
const configuracaoService = new ConfiguracoesService();

const RelatoriosFinanceiro = () => {
  const [loading, setLoading] = useState(false);
  const [configuracoes, setConfiguracoes] = useState({});
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [dadosRelatorio, setDadosRelatorio] = useState({});

  const subgruposRelatoriosFinanceiros = [
    {
      subgrupo: "Contas a Receber",
      propriedades: [
        {
          name: "601 - Contas a Receber Detalhado",
          layoutImpressao: PrintContasReceberDetalhado,
          funcaoBuscarDadosRelatorio: buscarContasReceberDetalhado,
          formulario: FormFiltroAvancadoContasReceber,
        },
      ],
    },
    {
      subgrupo: "Contas a Pagar",
      propriedades: [
        {
          name: "701 - Contas a Pagar Detalhado",
          layoutImpressao: PrintContasPagarDetalhado,
          funcaoBuscarDadosRelatorio: buscarContasPagarDetalhado,
          formulario: FormFiltroAvancadoContasPagar,
        },
      ],
    },
    {
      subgrupo: "Caixa",
      propriedades: [
        {
          name: "801 - Caixa Diário",
          layoutImpressao: PrintCaixaDetalhado,
          funcaoBuscarDadosRelatorio: buscarRelatorioCaixaDetalhado,
          formulario: FormFiltroAvancadoCaixa,
        },
      ],
    },
  ];

  useEffect(() => {
    buscarConfiguracoes();
  }, []);

  function buscarConfiguracoes() {
    configuracaoService.get().then((result) => {
      if (!result.isAxiosError) {
        setConfiguracoes((prevState) => ({
          ...prevState,
          jurosPorcentagem: result.data.rows[0]?.jurosPorcentagem ?? 0,
          multaPorcentagem: result.data.rows[0]?.multaPorcentagem ?? 0,
          carencia: result.data.rows[0]?.carencia ?? 0,
        }));
      }
    });
  }

  async function buscarContasReceberDetalhado(filtros) {
    setLoading(true);
    const result = await contasReceberService.getRelatorioDetalhado({
      ...filtros,
      ...configuracoes,
    });
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarContasPagarDetalhado(filtros) {
    setLoading(true);
    const result = await contasPagarService.getRelatorioDetalhado(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioCaixaDetalhado(filtros) {
    setLoading(true);
    const result = await caixaService.getRelatorioDetalhado(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorio(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      title="Relatório Contas a receber"
      subgruposRelatorios={subgruposRelatoriosFinanceiros}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={dadosRelatorio}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosFinanceiro;
