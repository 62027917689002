import { useState, useEffect } from "react";
import { Dialog, AcordeonList } from "../../../components";
import { ListLayout } from "../../../layouts";
import { CentroCustoService, notification } from "../../../services";
import { responseErros } from "../../../utils";
import AddCentroCusto from "../add-centro-custo/add-centro-custo.view";

const ListCentroCusto = () => {
  const centroCustoService = new CentroCustoService();
  const [loading, setLoading] = useState(false);
  const [centroCustoId, setCentroCustoId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [centrosCustos, setCentrosCustos] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    buscaCentrosCustos();
  }, []);

  const buscaCentrosCustos = async (query) => {
    const filter = {
      nonPaginated: true,
      ...query,
    };
    setLoading(true);
    const result = await centroCustoService.getAll(filter);
    if (!result.isAxiosError) {
      setCentrosCustos(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleOpenDialog = (id) => {
    setCentroCustoId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCentroCustoId(null);
    setOpenDialog(false);
  };

  const handleDeleteCentroCusto = async (id) => {
    const centroCusto = centrosCustos.find((item) => item.id === id);
    const existeSubordinados = centrosCustos.some((item) => item.idPai === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir o centro de custo ${centroCusto.codigo} - ${centroCusto.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      if (existeSubordinados) {
        notification.alertaGenericos(
          "Precisa ser removido os centros de custo antes do superior"
        );
        return;
      }
      setLoading(true);
      const result = await centroCustoService.deletar(id);
      if (!result.isAxiosError) {
        buscaCentrosCustos();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  return (
    <ListLayout
      title="Centros de Custos"
      loading={loading}
      onClickCadastrar={handleOpenDialog}
      permisaoCadastrar={true}
    >
      <AcordeonList
        items={centrosCustos}
        handleEditItem={handleOpenDialog}
        handleDeleteItem={handleDeleteCentroCusto}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <AddCentroCusto
          id={centroCustoId}
          handleCloseDialog={handleCloseDialog}
          buscaCentrosCustos={buscaCentrosCustos}
        />
      </Dialog>
    </ListLayout>
  );
};

export default ListCentroCusto;
