import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { history, UsuarioService, notification } from "../../../services";
import { FormFiltroAvancado } from "./components";
import { responseErros } from "../../../utils";

const ListUsuariosView = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const colunas = [
    { field: "email", headerName: "E-mail", flex: 300 },
    { field: "nome", headerName: "Nome", flex: 350, filtrar: true },
    {
      field: "perfilUsuario",
      headerName: "Perfil Usuário",
      flex: 250,
      valueGetter: (params) => params.value?.nome,
    },
  ];
  const usuarioService = new UsuarioService();

  useEffect(() => {
    buscaUsuarios();
  }, []);

  const buscaUsuarios = async (filter) => {
    setLoading(true);
    const result = await usuarioService.getAll(filter);
    if (!result.isAxiosError) {
      setUsuarios(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarUsuario = function () {
    history.push("./usuarios/create");
  };

  const onClickEditUsuario = function (id) {
    history.push(`./usuarios/${id}`);
  };

  const onClickDeleteUsuario = async function (id) {
    const pessoa = usuarios.find((item) => item.id === id);
    if (pessoa.padrao) {
      notification.alertaGenericos("Não é possível remover usuário principal");
      return;
    }
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${pessoa.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await usuarioService.deletar(id);
      if (!result.isAxiosError) {
        buscaUsuarios();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const filtrosAvancadosTemp = {
      ...filter,
      restritiva: true,
      porIntervalo: [],
    };
    setLoading(true);
    const result = await usuarioService.getAllFiltroAvancado(
      filtrosAvancadosTemp
    );
    if (!result.isAxiosError) {
      setUsuarios(result.data.rows);
      setRowCount(result.data.count);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaUsuarios(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Cadastro de Usuários"
      loading={loading}
      onClickCadastrar={onClickCadastrarUsuario}
    >
      <DataGrid
        rows={usuarios}
        columns={colunas}
        onClickEdit={onClickEditUsuario}
        onClickDelete={onClickDeleteUsuario}
        rowCount={rowCount}
        paginationMode={"server"}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListUsuariosView;
