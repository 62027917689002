import { Grid, TextareaAutosize } from "../../../../../components";
import "./informacoes.styles.css";

const NfSaidaInformacoes = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextareaAutosize
          className="text-area w-100 p-3 mt-3"
          aria-label="minimum height"
          name="informacoesAdicionais"
          id="informacoesAdicionais"
          disabled={props.nfSaidaId}
          onChange={props.handleInputChange}
          value={
            props.nfSaida?.informacoesAdicionais
              ? props.nfSaida.informacoesAdicionais
              : ""
          }
          minRows={5}
          placeholder="Informações adicionais"
        />
      </Grid>
      <Grid item xs={6}>
        <TextareaAutosize
          className="text-area w-100 p-3 mt-3"
          aria-label="minimum height"
          name="informacoesFisco"
          id="informacoesFisco"
          disabled={props.nfSaidaId}
          onChange={props.handleInputChange}
          value={
            props.nfSaida?.informacoesFisco
              ? props.nfSaida.informacoesFisco
              : ""
          }
          minRows={5}
          placeholder="Informações fisco"
        />
      </Grid>
    </Grid>
  );
};

export default NfSaidaInformacoes;
