import { useEffect, useState } from "react";
import { CabecalhoMainPrint, Grid } from "..";
import { formatPrice } from "../../utils";
import "./table-print-detalhado.css";

const TablePrintDetalhadoComponent = ({
  filtrosAplicadosImpressao,
  relatoriosTotais,
  totais,
  tituloRelatorio,
  print,
  columns,
  headerRelatorio,
  footerTotalizador,
  alturaLimite,
}) => {
  const [paginas, setPaginas] = useState([]);
  const arrayIdsRelatorio = Object.keys(relatoriosTotais);
  const altura = alturaLimite || 315;
  useEffect(() => {
    montaPaginas();
  }, []);

  const montaPaginas = () => {
    let alturaMaxima = 0;
    let itemIndexTemp = 0;
    let relatorioIndexTemp = 0;
    let elementosPagina = [];
    let paginasTemp = [];
    let relatorioItens = [];
    let cabecalhoTabela = true;
    while (relatorioIndexTemp < arrayIdsRelatorio.length) {
      const idRelatorio = arrayIdsRelatorio[relatorioIndexTemp];
      const relatorio = relatoriosTotais[idRelatorio];
      const ultimoItem = itemIndexTemp === relatorio.itens.length;
      const ultimaInstancia =
        idRelatorio === arrayIdsRelatorio[arrayIdsRelatorio.length - 1] &&
        ultimoItem;
      const itens = relatorio.itens.slice(itemIndexTemp);
      if (cabecalhoTabela) {
        alturaMaxima += 15;
      }
      itens.forEach((item) => {
        relatorioItens.push(item);
        alturaMaxima += 10;
        itemIndexTemp++;
        if (alturaMaxima >= altura) {
          elementosPagina.push(
            tabela(idRelatorio, relatorioItens, itemIndexTemp, cabecalhoTabela)
          );
          paginasTemp.push(pagina(elementosPagina));
          alturaMaxima = 0;
          relatorioItens = [];
          elementosPagina = [];
          cabecalhoTabela = false;
        }
      });
      if (relatorioItens.length > 0 && alturaMaxima < altura) {
        elementosPagina.push(
          tabela(idRelatorio, relatorioItens, itemIndexTemp, cabecalhoTabela)
        );
        alturaMaxima += itemIndexTemp === relatorio.itens.length ? 55 : 45;
        if (footerTotalizador && ultimaInstancia) {
          alturaMaxima += 75;
        }
        if (alturaMaxima >= altura) {
          paginasTemp.push(pagina(elementosPagina));
          elementosPagina = [];
          alturaMaxima = 0;
        }
      }
      if (itemIndexTemp === relatorio.itens.length) {
        relatorioIndexTemp++;
        itemIndexTemp = 0;
        relatorioItens = [];
        cabecalhoTabela = true;
      }
    }
    if (elementosPagina.length > 0) {
      paginasTemp.push(pagina(elementosPagina));
    }
    setPaginas((prevPaginas) => [...prevPaginas, ...paginasTemp]);
  };

  const tabela = (idRelatorio, itens, itemIndex, cabecalhoTabela) => {
    const relatorio = relatoriosTotais[idRelatorio];
    const ultimoItem = itemIndex === relatorio.itens.length;
    const ultimaInstancia =
      idRelatorio === arrayIdsRelatorio[arrayIdsRelatorio.length - 1] &&
      ultimoItem;
    return (
      <>
        {cabecalhoTabela ? headerRelatorio(relatorio) : null}
        <Grid item md={12} xs={12}>
          <table id="table-relatorio" className="table table-borderless">
            <thead className="table-primary">
              <tr>
                {columns.map((column, index) => (
                  <th
                    key={index}
                    style={{
                      textAlign: column.align || "start",
                    }}
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {itens.map((item, index) => (
                <>
                  <tr key={index}>
                    {columns.map((column, columnIndex) => (
                      <td key={columnIndex}>
                        <div
                          style={{
                            textAlign: column.align || "start",
                          }}
                        >
                          {column.render
                            ? column.render(item)
                            : item[column.field]}
                        </div>
                      </td>
                    ))}
                  </tr>
                  {index + 1 === itens.length &&
                    ultimoItem &&
                    totais[idRelatorio] && (
                      <tr className="table-primary">
                        {columns.map((column, columnIndex) => (
                          <td key={columnIndex}>
                            <div
                              style={{
                                textAlign: column.align || "start",
                              }}
                            >
                              {totais[idRelatorio][column.fieldTotal] >= 0 ? (
                                <b>
                                  {column.renderTotal
                                    ? column.renderTotal(totais[idRelatorio])
                                    : formatPrice(
                                        totais[idRelatorio][column.fieldTotal],
                                        false
                                      )}
                                </b>
                              ) : null}
                            </div>
                          </td>
                        ))}
                      </tr>
                    )}
                </>
              ))}
            </tbody>
          </table>
          <RodapeTabela totalGeral={ultimaInstancia && footerTotalizador} />
        </Grid>
      </>
    );
  };

  const pagina = (elementosPagina) => {
    return (
      <div className={!print ? "report-container" : "print-container"}>
        {elementosPagina.map((elem, index) => (
          <div key={`elem-${index}`}>{elem}</div>
        ))}
      </div>
    );
  };

  const RodapeTabela = ({ totalGeral }) => {
    return (
      <Grid container spacing={2} className="d-flex justify-content-end">
        <Grid item xs={6}>
          {totalGeral}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {paginas.map((pagina, index) => {
        const paginacaoInicial = filtrosAplicadosImpressao?.numeroPagina || 0;
        pagina.props.children.unshift(
          <CabecalhoMainPrint
            data={{
              tituloRelatorio,
              filtrosAplicadosImpressao,
              print,
              pagina: `${index + 1 + paginacaoInicial}/${
                paginas?.length + paginacaoInicial
              }`,
            }}
          />
        );
        return <div key={index}>{pagina}</div>;
      })}
    </>
  );
};

export default TablePrintDetalhadoComponent;
