import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import {
  ConfiguracoesService,
  history,
  MeioPagamentoService,
  notification,
} from "../../../services";
import { DataGrid } from "../../../components";
import { FormFiltroAvancado } from "./components";
import { cacheApi, responseErros } from "../../../utils";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListRegraDesconto = () => {
  const { cacheDto, setCacheDto } = useCacheContext();
  const [loading, setLoading] = useState(false);
  const [meiosPagamento, setMeiosPagamento] = useState([]);
  const [configuracoes, setConfiguracoes] = useState(null);
  const [rowCount, setRowCount] = useState(null);
  const configuracoesService = new ConfiguracoesService();
  const meioPagamentoService = new MeioPagamentoService();
  const colunas = [
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 700,
      filtrar: true,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) =>
        params.value == true ? "Ativado" : "Desativado",
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaMeiosPagamento(filtros);
    buscarConfiguracao();
  }, []);

  const buscaMeiosPagamento = async function (filter) {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: meioPagamentoService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setMeiosPagamento(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarConfiguracao = () => {
    configuracoesService.get().then((result) => {
      if (!result.isAxiosError) {
        setConfiguracoes(result.data.rows[0]);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickCadastrarMeioPagamento = function () {
    history.push("/faturamento/meios-pagamento/create");
  };

  const onClickEditMeioPagamento = function (id) {
    history.push(`/faturamento/meios-pagamento/${id}`);
  };

  const configuracaoDelete = (idMeioPagamento) => {
    const pagamentosConfigurados = Object.keys(configuracoes)
      .filter((key) => key.toLowerCase().includes("pagamento"))
      .map((key) => configuracoes[key]);
    return pagamentosConfigurados.some((valor) => valor === idMeioPagamento);
  };

  const onClickDeleteMeioPagamento = async function (id) {
    const data = meiosPagamento.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      const configuracaoValida = configuracaoDelete(id);
      setLoading(true);
      if (!configuracaoValida) {
        const result = await meioPagamentoService.deletar(id);
        if (!result.isAxiosError) {
          buscaMeiosPagamento();
          notification.deletadoSucesso();
        } else {
          responseErros(result);
        }
      } else {
        notification.alertaGenericos(
          "Só é permitido excluir meios de pagamento sem vínculo em parâmetros."
        );
      }
      setLoading(false);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaMeiosPagamento(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: meioPagamentoService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setMeiosPagamento(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  return (
    <ListLayout
      title="Meios de pagamento"
      loading={loading}
      onClickCadastrar={onClickCadastrarMeioPagamento}
    >
      <DataGrid
        rows={meiosPagamento}
        columns={colunas}
        onClickEdit={onClickEditMeioPagamento}
        onClickDelete={onClickDeleteMeioPagamento}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListRegraDesconto;
