import { useRef, useState } from "react";
import { Menu, MenuItem, ListItemIcon } from "../../../components";
import { SuprimentoSangriaDialog, TranferenciaSaldoDialog } from "../dialogs";
import shortid from "shortid";
import { useReactToPrint } from "react-to-print";
import RelatorioCaixaDiario from "../components/nfce/modelo-nfce.component";
import { totalSangria, totalSuprimento } from "../caixa";

const MenuAddActions = ({
  anchorEl,
  setAnchorEl,
  acessoCaixa,
  listaContasUsuario,
  listaCaixas,
  totaisCaixa,
  formCaixa,
  setFormCaixa,
  transferenciaCaixa,
  setTransferenciaCaixa,
  setTotaisCaixa,
  buscarCaixa,
}) => {
  const componentRelatorioRef = useRef(null);
  const [tranferenciaSaldoOpenDialog, setTranferenciaSaldoOpenDialog] =
    useState(false);
  const [openSangriaSuprimento, setOpenSangriaSuprimento] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRelatorioRef.current,
    pageStyle: `
        @page {
            size: auto;
            margin: 8mm;
        }
    `,
  });

  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";

  const handleAddActionsClose = () => {
    setAnchorEl(null);
  };

  const handleTranferenciaSaldoOpen = () => {
    setTransferenciaCaixa({ dataMovimentacao: formCaixa?.data });
    setTranferenciaSaldoOpenDialog(true);
  };

  const handleSuprimentoSangriaOpen = () => {
    setOpenSangriaSuprimento(true);
  };

  return (
    <>
      <div className="d-none">
        <div ref={componentRelatorioRef}>
          <RelatorioCaixaDiario
            body={{
              ...totaisCaixa,
              ...formCaixa,
              totalSangria: totalSangria(listaCaixas),
              totalSuprimento: totalSuprimento(listaCaixas),
              contaLista: listaContasUsuario,
              listaCaixas,
            }}
          />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        getContentAnchorEl={null}
        id={menuId}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        autoFocus={false}
        open={isMenuOpen}
        onClose={handleAddActionsClose}
        className="m-3"
      >
        {[
          <MenuItem
            className="mb-1"
            onClick={handleTranferenciaSaldoOpen}
            disabled={acessoCaixa}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i
                className="ph-fill ph-arrow-elbow-left"
                style={{ fontSize: 23, marginRight: 16, rotate: "180deg" }}
              ></i>
            </ListItemIcon>
            Transferência de saldo
          </MenuItem>,
          <MenuItem
            className="mb-1"
            onClick={handleSuprimentoSangriaOpen}
            disabled={!formCaixa.bancoCaixaId}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i
                className="ph-bold ph-arrows-down-up"
                style={{ fontSize: 23, marginRight: 16 }}
              ></i>
            </ListItemIcon>
            Suprimento e Sangria
          </MenuItem>,
          <MenuItem
            className="mb-1"
            onClick={handlePrint}
            disabled={!(formCaixa.bancoCaixaId && formCaixa.data)}
            key={shortid.generate()}
          >
            <ListItemIcon style={{ minWidth: 42 }}>
              <i
                className="ph-fill ph-monitor"
                style={{ fontSize: 23, marginRight: 16 }}
              ></i>
            </ListItemIcon>
            Relatório Caixa Diário
          </MenuItem>,
        ]}
      </Menu>
      <TranferenciaSaldoDialog
        open={tranferenciaSaldoOpenDialog}
        setOpen={setTranferenciaSaldoOpenDialog}
        transferenciaCaixa={transferenciaCaixa}
        setTransferenciaCaixa={setTransferenciaCaixa}
        contasUsuario={listaContasUsuario}
        setTotaisCaixa={setTotaisCaixa}
        setFormCaixa={setFormCaixa}
      />
      <SuprimentoSangriaDialog
        open={openSangriaSuprimento}
        setOpen={setOpenSangriaSuprimento}
        totaisCaixa={totaisCaixa}
        formCaixa={formCaixa}
        buscarCaixa={buscarCaixa}
      />
    </>
  );
};

export default MenuAddActions;
