import { useState, useEffect } from "react";
import { ListLayout } from "../../layouts";
import { DataGrid } from "../../components";
import { notification, GruposTributarioService, CsoSimplesService } from "../../services";
import { DialogAddGrupoTributario, FormFiltroAvancado } from "./components";
import { cacheApi, responseErros } from "../../utils";
import { filtrosUrl } from "../../helpers";
import { useCacheContext } from "../../contexts/cache.context";

const ListGruposTributario = () => {
  const [grupoTributario, setGrupoTributario] = useState({ ativado: true });
  const [gruposTributario, setGruposTributario] = useState([]);
  const [csoSimplesList, setCsoSimplesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { cacheDto, setCacheDto } = useCacheContext();
  const colunas = [
    {
      field: "nome",
      headerName: "Nome",
      flex: 500,
      filtrar: true,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) => (params.value ? "Ativado" : "Desativado"),
    },
  ];

  const gruposTributarioService = new GruposTributarioService();
  const csoSimplesService = new CsoSimplesService();

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarGruposTributario(filtros);
    buscaCsoSimples();
  }, []);

  const buscarGruposTributario = async (query) => {
    const label = filtrosUrl.getUrl(query);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: gruposTributarioService.getAll(query),
    };
    const res = await cacheApi(label, cacheBody);
    if (!res.isAxiosError) {
      setGruposTributario(res.data.rows);
      setRowCount(res.data.count);
      filtrosUrl.set(query);
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const buscarGruposTributarioById = async (id) => {
    setLoading(true);
    const { data } = await gruposTributarioService.getById(id);
    setGrupoTributario(data);
    setLoading(false);
  };

  const buscaCsoSimples = () => {
    csoSimplesService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setCsoSimplesList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickGrupoTributario = function (id) {
    setId(id);
    setOpenDialog(true);
    setGrupoTributario({ ativado: true });
    if (!id) return;
    buscarGruposTributarioById(id);
  };

  const onClickDeleteGrupoTributario = async function (id) {
    const grupoTributario = gruposTributario.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${grupoTributario.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await gruposTributarioService.deletar(id);
      if (!result.isAxiosError) {
        buscarGruposTributario();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const cadastrarGrupoTributario = async (body) => {
    setOpenDialog(false);
    setLoading(true);
    const res = await gruposTributarioService.cadastrar(body);
    if (!res.isAxiosError) {
      buscarGruposTributario();
      notification.cadastroSucesso();
    } else {
      setLoading(false);
      responseErros(res);
    }
  };

  const atualizarGrupoTributario = async (idGrupo, body) => {
    setOpenDialog(false);
    setLoading(true);
    const res = await gruposTributarioService.atualizar(idGrupo, body);
    if (!res.isAxiosError) {
      buscarGruposTributario();
      notification.alteracaoSucesso();
    } else {
      setLoading(false);
      responseErros(res);
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: gruposTributarioService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setGruposTributario(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarGruposTributario(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Grupos Tributários"
      loading={loading}
      onClickCadastrar={onClickGrupoTributario}
    >
      <DataGrid
        rows={gruposTributario}
        columns={colunas}
        onClickEdit={onClickGrupoTributario}
        onClickDelete={onClickDeleteGrupoTributario}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <DialogAddGrupoTributario
        id={id}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        cadastrarGrupoTributario={cadastrarGrupoTributario}
        gruposTributario={gruposTributario}
        setGruposTributario={setGruposTributario}
        grupoTributario={grupoTributario}
        setGrupoTributario={setGrupoTributario}
        atualizarGrupoTributario={atualizarGrupoTributario}
        csoSimplesList={csoSimplesList}
      />
    </ListLayout>
  );
};

export default ListGruposTributario;
