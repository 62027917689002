import { Grid, FormControlLabel, Switch } from "../../../../components";

const PermissoesSwitch = ({ valueSwitch, onChangeSwitch, modulo }) => {
  const { nome } = modulo;
  const switchPermissoes = [
    {
      key: "editar",
      label: "Editar",
      condicao: !["saldo-inicial", "dashboard", "pdv"].includes(nome),
      xs: 2,
    },
    {
      key: "cadastrar",
      label: "Cadastrar",
      condicao: !["dashboard", "pdv"].includes(nome),
      xs: 2,
    },
    { key: "visualizar", label: "Visualizar", condicao: true, xs: 2 },
    {
      key: "excluir",
      label: "Excluir",
      condicao: ![
        "orcamentos",
        "pedidos",
        "nf-saida",
        "saldo-inicial",
        "dashboard",
        "pdv",
      ].includes(nome),
      xs: 2,
    },
    {
      key: "cancelar",
      label: "Cancelar",
      condicao: ["orcamentos", "pedidos", "nf-saida"].includes(nome),
      xs: 2,
    },
    {
      key: "editarPrecoVenda",
      label: "Editar Preço de Venda",
      condicao: nome === "nf-saida",
      xs: 4,
    },
    {
      key: "vendedorObrigatorio",
      label: "Vendedor Obrigatorio",
      condicao: ["pedidos", "nf-saida", "pdv"].includes(nome),
      xs: 3,
    },
    {
      key: "limparCarrinho",
      label: "Limpar Carrinho",
      condicao: nome == "pdv",
      xs: 3,
    },
    {
      key: "excluirItemCarrinho",
      label: "Excluir Item Carrinho",
      condicao: nome == "pdv",
      xs: 3,
    },
    {
      key: "utilizarCaixaColaborador",
      label: "Utilizar Caixa por Colaborador",
      condicao: nome == "caixa",
      xs: 4,
    },
    {
      key: "editarDesconto",
      label: "Editar Desconto",
      condicao: nome === "nf-saida",
      xs: 3,
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      columns={17}
      className="d-flex justify-content-center"
    >
      {switchPermissoes.map(
        ({ key, label, condicao, xs }) =>
          condicao && (
            <Grid key={key} item xs={xs}>
              <FormControlLabel
                value={key}
                control={
                  <Switch
                    color="primary"
                    checked={valueSwitch(key)}
                    name={key}
                    onChange={onChangeSwitch}
                  />
                }
                label={label}
                labelPlacement="top"
              />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default PermissoesSwitch;
