import { CurrencyInput, Grid } from "../../../../../components";
import { unformatPrice } from "../../../../../utils";

const NfSaidaTributos = ({ nfSaida, setNfSaida }) => {
  const handleInputChange = (event) => {
    setNfSaida((prevState) => ({
      ...prevState,
      [event.target.name]: unformatPrice(event.target.value),
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseIcms"
            name="baseIcms"
            label="Base ICMS"
            margin="normal"
            disabled
            value={nfSaida?.baseIcmsTotal || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIcms"
            name="valorIcms"
            label="Valor ICMS"
            margin="normal"
            disabled
            value={nfSaida?.valorIcmsTotal || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIpi"
            name="valorIpi"
            label="Valor IPI"
            margin="normal"
            disabled
            value={nfSaida?.valorIpiTotal || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="baseIcmsSt"
            name="baseIcmsSt"
            label="Base ICMS ST"
            margin="normal"
            disabled
            value={nfSaida?.baseIcmsSt || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valorIcmsSt"
            name="valorIcmsSt"
            label="Valor ICMS ST"
            margin="normal"
            disabled
            value={nfSaida?.valorIcmsSt || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="despesasAcessorias"
            name="despesasAcessorias"
            label="Despesas Acessorias"
            margin="normal"
            value={nfSaida?.despesasAcessorias || "0"}
            onBlur={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NfSaidaTributos;
