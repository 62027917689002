import { useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import {
  FormFiltroAvancado,
  FormFiltroAvancadoDetalhado,
  FormFiltroAvancadoEstoqueFinanceiro,
  FormFiltroAvancadoInventario,
  FormFiltroAvancadoNfEntrada,
} from "./components";
import { NfEntradaService, ProdutosService } from "../../../services";
import { responseErros } from "../../../utils";
import {
  PrintEstoque,
  PrintEstoqueDetalhado,
  PrintEstoqueFinanceiro,
  PrintEstoqueInventario,
  PrintNfEntradaResumido,
} from "../layouts";
const produtosService = new ProdutosService();
const nfEntradaService = new NfEntradaService();

const RelatoriosEstoque = () => {
  const [loading, setLoading] = useState(false);
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [relatorioEstoque, setRelatorioEstoque] = useState({});

  const subgruposRelatorios = [
    {
      subgrupo: "NF Entrada",
      propriedades: [
        {
          name: "100 - NF Entrada Resumido",
          layoutImpressao: PrintNfEntradaResumido,
          funcaoBuscarDadosRelatorio: buscarRelatorioNfEntradaResumido,
          formulario: FormFiltroAvancadoNfEntrada,
        },
      ],
    },
    {
      subgrupo: "Produtos",
      propriedades: [
        {
          name: "200 - Movimentação de Produto Resumido",
          layoutImpressao: PrintEstoque,
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueResumido,
          formulario: FormFiltroAvancado,
        },
        {
          name: "201 - Movimentação de Produto Detalhado",
          layoutImpressao: PrintEstoqueDetalhado,
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueDetalhado,
          formulario: FormFiltroAvancadoDetalhado,
        },
        {
          name: "202 - Inventário de Estoque",
          layoutImpressao: PrintEstoqueInventario,
          funcaoBuscarDadosRelatorio: buscarInventarioDeEstoque,
          formulario: FormFiltroAvancadoInventario,
        },
        {
          name: "203 - Estoque Financeiro",
          layoutImpressao: PrintEstoqueFinanceiro,
          funcaoBuscarDadosRelatorio: buscarRelatorioEstoqueFinanceiro,
          formulario: FormFiltroAvancadoEstoqueFinanceiro,
        },
      ],
    },
  ];

  async function buscarRelatorioNfEntradaResumido(filtros) {
    setLoading(true);
    const result = await nfEntradaService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueResumido(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueDetalhado(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioDetalhado(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarInventarioDeEstoque(filtros) {
    setLoading(true);
    const result = await produtosService.getInventarioDeEstoque(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarRelatorioEstoqueFinanceiro(filtros) {
    setLoading(true);
    const result = await produtosService.getRelatorioEstoqueFinanceiro(filtros);
    if (!result.isAxiosError) {
      setRelatorioEstoque(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      subgruposRelatorios={subgruposRelatorios}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={relatorioEstoque}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosEstoque;
