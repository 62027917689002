import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  DatePicker,
  DataGrid,
} from "../../components";
import { format } from "date-fns";
import { Minimal } from "../../layouts";
import { NfSaidaService } from "../../services";
import { value } from "../../helpers";
import { formatDate, formatPrice, responseErros } from "../../utils";
import "./monitor-fiscal.style.scss";

const MonitorFiscalView = () => {
  const colunas = [
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      valueGetter: () => {
        return "PENDENTE";
      },
    },
    {
      field: "serieDocumento",
      headerName: "Série/N Doc.",
      flex: 1000,
      valueGetter: (params) =>
        `${params.row.serieDocumento}/${params.row.numeroDocumento}`,
    },
    {
      field: "dataEmissao",
      headerName: "Dt.Emissão",
      flex: 1000,
      valueGetter: (params) => {
        return format(formatDate.received(params.value), "dd/MM/yyyy");
      },
    },
    {
      field: "clienteId",
      headerName: "Cliente",
      flex: 1000,
    },
    {
      field: "nfSaidaItens",
      headerName: "Vr. Total",
      flex: 1000,
      valueGetter: (params) => {
        const totalNfSaida =
          params.value
            .map((item) => item.subtotal)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.frete +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        return formatPrice(totalNfSaida);
      },
    },
    {
      field: "retorno",
      headerName: "Retorno",
    },
    {
      field: "protocoloFfe",
      headerName: "Protocolo NFe",
    },
    {
      field: "reciboNfe",
      headerName: "Recibo NFe",
    },
    {
      field: "dataAutorizacao",
      headerName: "DT Autorização",
    },
    {
      field: "chaveNfe",
      headerName: "Chave NFe",
    },
  ];
  const nfSaidaService = new NfSaidaService();
  const [monitorNf, setMonitorNf] = useState({});
  const [nfList, setNfList] = useState([]);
  const [loading, setLoading] = useState(false);
  const listaModelosDocumento = [
    {
      value: "55",
      label: "55 - Nota Fiscal Eletrônica (NF-e)",
    },
    {
      value: "65",
      label: "65 - Nota Fiscal Eletrônica ao Consumidor",
    },
  ];
  const listaStatusDocumento = [
    {
      value: "01",
      label: "Pendente",
      color: "#083d77",
    },
    {
      value: "02",
      label: "Enviados",
      color: "#92D050",
    },
    {
      value: "03",
      label: "Cancelados",
      color: "#FF0100",
    },
    {
      value: "04",
      label: "Contigências",
      color: "#BFBFBF",
    },
    {
      value: "05",
      label: "Denegada",
      color: "#70309F",
    },
  ];

  useEffect(() => {
    buscarNfs();
  }, []);

  const buscarNfs = async (filtros) => {
    setLoading(true);
    if (!filtros) {
      const result = await nfSaidaService.getAll();
      if (result.isAxiosError) return responseErros(result);
      setNfList(result.data.rows);
    } else {
      const result = await nfSaidaService.getAllFiltroAvancado(filtros);
      if (result.isAxiosError) return responseErros(result);
      setNfList(result.data.rows);
    }
    setLoading(false);
  };

  const onChangeDate = (name, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setMonitorNf({
      ...monitorNf,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    });
  };

  const handleInputChange = (event) => {
    setMonitorNf((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleBuscarNfs = (event) => {
    event.preventDefault();
    const porIntervalo = [];
    const filtrosAvancados = {
      ...monitorNf,
      restritiva: true,
    };
    if (filtrosAvancados.dataEmissaoStart && filtrosAvancados.dataEmissaoEnd) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancados.dataEmissaoStart,
        ate: filtrosAvancados.dataEmissaoEnd,
      });
    }
    delete filtrosAvancados.dataEmissaoStart;
    delete filtrosAvancados.dataEmissaoEnd;
    buscarNfs({
      ...filtrosAvancados,
      porIntervalo,
    });
    setMonitorNf({});
  };

  return (
    <Minimal title="Monitor Fiscal" loading={loading}>
      <form onSubmit={handleBuscarNfs}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <DatePicker
              id="dataEmissaoStart"
              name="dataEmissaoStart"
              label="Data Inicial"
              variant="outlined"
              margin
              format="dd/MM/yyyy"
              value={
                monitorNf?.dataEmissaoStart ? monitorNf.dataEmissaoStart : null
              }
              onChange={(date) => onChangeDate("dataEmissaoStart", date)}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              id="dataEmissaoEnd"
              name="dataEmissaoEnd"
              label="Data Final"
              variant="outlined"
              margin
              format="dd/MM/yyyy"
              value={
                monitorNf?.dataEmissaoEnd ? monitorNf.dataEmissaoEnd : null
              }
              onChange={(date) => onChangeDate("dataEmissaoEnd", date)}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              id="modeloDocumento"
              margin="normal"
              name="modeloDocumento"
              value={value.text(monitorNf?.modeloDocumento)}
              onChange={handleInputChange}
            >
              {listaModelosDocumento.map((modelo) => (
                <MenuItem key={modelo.value} value={modelo.value}>
                  {modelo.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <TextField
              id="serieDocumento"
              name="serieDocumento"
              label="Série"
              margin="normal"
              variant="outlined"
              onChange={handleInputChange}
              value={monitorNf.serieDocumento ?? ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Select
              id="statusDocumento"
              name="statusDocumento"
              label="Status do Documento"
              margin="normal"
              value={value.text(monitorNf?.statusDocumento)}
              onChange={handleInputChange}
            >
              <MenuItem value="">
                <p></p>
              </MenuItem>
              {listaStatusDocumento.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className="ph-fill ph-circle"
                      style={{
                        color: status.color,
                        marginRight: 10,
                        paddingTop: 2,
                      }}
                    />
                    <Typography>{status.label}</Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              startIcon={
                <i
                  className="ph-bold ph-magnifying-glass"
                  style={{ fontSize: 18, color: "#fff" }}
                ></i>
              }
              type="submit"
              className="mt-4"
              fullWidth
              color="primary"
            >
              buscar
            </Button>
          </Grid>
        </Grid>
        <hr className="mt-4" />
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-check"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              type="submit"
              className="mt-2 tipografy"
              fullWidth
              variant="contained"
            >
              Validar DF-e
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-file"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              type="submit"
              variant="contained"
              className="mt-2 tipografy"
              fullWidth
            >
              Carta de Correção
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-file-arrow-up"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              variant="contained"
              type="submit"
              className="mt-2 tipografy"
              fullWidth
            >
              Exibir DANFE
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-x-circle"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              type="submit"
              variant="contained"
              className="mt-2 tipografy"
              fullWidth
            >
              Cancelar Doc.
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-file-search"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              type="submit"
              variant="contained"
              className="mt-2 tipografy"
              fullWidth
            >
              Consultar DF-e
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-file-arrow-down"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              variant="contained"
              type="submit"
              className="mt-2 tipografy"
              fullWidth
            >
              Gerar PDF
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-prohibit-inset"
                  style={{
                    fontSize: 18,
                    color: "#FFF",
                  }}
                ></i>
              }
              type="submit"
              variant="contained"
              className="mt-2 tipografy"
              fullWidth
            >
              Inutilizar Numeração
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-cloud-arrow-up"
                  style={{
                    fontSize: 22,
                    color: "#494C62",
                  }}
                ></i>
              }
              type="submit"
              className="mt-2 tipografy"
              fullWidth
              variant="contained"
              color="primary"
            >
              GERAR E ENVIAR DF-E
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={
                <i
                  className="ph-fill ph-cloud-arrow-down"
                  style={{
                    fontSize: 22,
                    color: "#494C62",
                  }}
                ></i>
              }
              type="submit"
              className="mt-2 tipografy"
              fullWidth
              variant="contained"
              color="primary"
            >
              DOWNLOAD XML
            </Button>
          </Grid>
        </Grid>
      </form>
      <hr className="mt-4" />
      <Grid container spacing={1} className="mt-4">
        <DataGrid
          checkboxSelection
          rows={nfList}
          columns={colunas}
          paginationMode={"server"}
          disableAcoes
          noFilter
        />
      </Grid>
    </Minimal>
  );
};

export default MonitorFiscalView;
