class NfSaidaCancelamento {
  justificativa = null;
  operacaoFiscal = null;
  devolucoesClienteIds =  null;
  devolucoesFornecedorIds =  null;
  constructor(nfSaida, justificativa, operacaoFiscal) {
    this.justificativa = justificativa;
    this.operacaoFiscal = operacaoFiscal;
    this.devolucoesClienteIds = nfSaida?.devolucoesClienteIds || null;
    this.devolucoesFornecedorIds = nfSaida?.devolucoesFornecedorIds || null;
  }
}

export default NfSaidaCancelamento;
