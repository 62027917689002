import { useState } from "react";
import { RelatoriosLayout } from "../../../layouts";
import {
  FormFiltroAvancado,
  FormFiltroAvancadoComissaoVendedores,
  FormFiltroAvancadoContador,
  FormFiltroAvancadoNfSaida,
} from "./components";
import {
  NfSaidaService,
  OrcamentosService,
  PedidosService,
} from "../../../services";
import {
  PrintOrcamentoResumido,
  PrintNfSaidaResumido,
  PrintPedidoResumido,
  PrintComissaoVendedores,
  PrintNfSaidaResumidoContador,
} from "../layouts";
import { responseErros } from "../../../utils";
const orcamentosService = new OrcamentosService();
const pedidosService = new PedidosService();
const nfSaidaService = new NfSaidaService();

const RelatoriosFaturamento = () => {
  const [loading, setLoading] = useState(false);
  const [filtrosAplicadosImpressao, setFiltrosAplicadosImpressao] = useState(
    {}
  );
  const [filtrosAvancados, setFiltrosAvancados] = useState({});
  const [dadosRelatorioResumido, setDadosRelatorioResumido] = useState({});

  const subgruposRelatorios = [
    {
      subgrupo: "Orçamento",
      propriedades: [
        {
          name: "300 - Orçamento Resumido",
          layoutImpressao: PrintOrcamentoResumido,
          funcaoBuscarDadosRelatorio: buscarOrcamentoResumido,
          formulario: FormFiltroAvancado,
        },
      ],
    },
    {
      subgrupo: "Pedido",
      propriedades: [
        {
          name: "400 - Pedido Resumido",
          layoutImpressao: PrintPedidoResumido,
          funcaoBuscarDadosRelatorio: buscarPedidoResumido,
          formulario: FormFiltroAvancado,
        },
      ],
    },
    {
      subgrupo: "NF Saída",
      propriedades: [
        {
          name: "500 - NF Saída Resumido",
          layoutImpressao: PrintNfSaidaResumido,
          funcaoBuscarDadosRelatorio: buscarNfSaidaResumido,
          formulario: FormFiltroAvancadoNfSaida,
        },
        {
          name: "502 - Comissão de Vendedores",
          layoutImpressao: PrintComissaoVendedores,
          funcaoBuscarDadosRelatorio: buscarComissaoVendedores,
          formulario: FormFiltroAvancadoComissaoVendedores,
        },
        {
          name: "503 - NF Saída (Contador)",
          layoutImpressao: PrintNfSaidaResumidoContador,
          funcaoBuscarDadosRelatorio: buscarNfSaidaResumidoContador,
          formulario: FormFiltroAvancadoContador,
        },
      ],
    },
  ];

  async function buscarOrcamentoResumido(filtros) {
    setLoading(true);
    const result = await orcamentosService.relatorioResulmido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorioResumido(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarPedidoResumido(filtros) {
    setLoading(true);
    const result = await pedidosService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorioResumido(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarNfSaidaResumido(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioResumido(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorioResumido(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarComissaoVendedores(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioComissaoVendedores(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorioResumido(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  async function buscarNfSaidaResumidoContador(filtros) {
    setLoading(true);
    const result = await nfSaidaService.getRelatorioResumidoContador(filtros);
    if (!result.isAxiosError) {
      setDadosRelatorioResumido(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
    return result;
  }

  return (
    <RelatoriosLayout
      title="Relatórios de Vendas"
      subgruposRelatorios={subgruposRelatorios}
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      dadosRelatorioResumido={dadosRelatorioResumido}
      setFiltrosAplicadosImpressao={setFiltrosAplicadosImpressao}
      filtrosAvancados={filtrosAvancados}
      setFiltrosAvancados={setFiltrosAvancados}
      loading={loading}
    />
  );
};

export default RelatoriosFaturamento;
