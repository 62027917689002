import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../../components";
import PermissoesSwitch from "./permissoes-switch.component";

const ModulosAcordionComponente = ({ modulo, setPerfil, perfil }) => {
  const onChangeSwitch = (event) => {
    if (perfil.permisoes?.length) {
      const permisao = perfil.permisoes.find(
        (item) => item.pctModuloId == modulo.id
      );
      if (permisao) {
        const permisoes = perfil.permisoes.map((permisaoItem) => {
          if (permisaoItem.pctModuloId == modulo.id) {
            permisaoItem[event.target.name] = event.target.checked;
          }
          return permisaoItem;
        });
        setPerfil({
          ...perfil,
          permisoes,
        });
      } else {
        setPerfil({
          ...perfil,
          permisoes: [
            ...perfil.permisoes,
            {
              moduloId: modulo.id,
              [event.target.name]: event.target.checked,
            },
          ],
        });
      }
    } else {
      setPerfil({
        ...perfil,
        permisoes: [
          {
            moduloId: modulo.id,
            [event.target.name]: event.target.checked,
          },
        ],
      });
    }
  };

  const valueSwitch = (nome) => {
    if (perfil.permisoes?.length) {
      const permisao = perfil.permisoes.find(
        (item) => item.pctModuloId == modulo.id
      );
      if (permisao) {
        return permisao[nome] ? true : false;
      }
    }
    return false;
  };

  return (
    <Grid item xs={12}>
      <Accordion>
        <AccordionSummary
          expandIcon={
            <i
              className="ph-fill ph-caret-down"
              style={{ color: "#494C62", fontSize: 18 }}
            ></i>
          }
        >
          <h6 className="text-capitalize">{modulo.descricao}</h6>
        </AccordionSummary>
        <AccordionDetails>
          <PermissoesSwitch
            valueSwitch={valueSwitch}
            onChangeSwitch={onChangeSwitch}
            modulo={modulo}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default ModulosAcordionComponente;
