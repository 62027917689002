/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import {
  history,
  OperacaoFiscalService,
  notification,
  NfSaidaService,
  NfEntradaService,
} from "../../../services";
import { FormFiltroAvancado } from "./components";
import { cacheApi, responseErros } from "../../../utils";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListOperacoesFiscais = ({ match }) => {
  const operacaoFiscalService = new OperacaoFiscalService();
  const nfSaidaService = new NfSaidaService();
  const nfEntradaService = new NfEntradaService();
  const { cacheDto, setCacheDto } = useCacheContext();
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [operacoesFiscais, setOperacoesFiscais] = useState([]);
  const colunas = [
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 450,
      filtrar: true,
    },
    {
      field: "tipoOperacao",
      headerName: "Tipo de Operação",
      flex: 250,
    },
    {
      field: "serieDocumento",
      headerName: "Série documento",
      flex: 250,
    },
    {
      field: "modeloDocumento",
      headerName: "Modelo documento",
      flex: 250,
      valueGetter: (params) => params.value,
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaOperacoesFiscais(filtros);
  }, []);

  const buscaOperacoesFiscais = async function (filter) {
    setLoading(true);
    const result = await operacaoFiscalService.getAll(filter);
    if (!result.isAxiosError) {
      setOperacoesFiscais(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarNfSaida = async (operacaoFiscalId) => {
    setLoading(true);
    const response = await nfSaidaService.getVinculoOperacaoFiscal(
      operacaoFiscalId
    );
    setLoading(false);
    return alertaRetornoEmitirNota(response);
  };

  const buscarNfEntrada = async (operacaoFiscalId) => {
    setLoading(true);
    const response = await nfEntradaService.getVinculoOperacaoFiscal(
      operacaoFiscalId
    );
    setLoading(false);
    return alertaRetornoEmitirNota(response);
  };

  function alertaRetornoEmitirNota(response) {
    if (!response.isAxiosError) {
      return response?.data;
    } else {
      responseErros(response);
    }
    return false;
  }

  async function buscarUsoOperacaoFiscal(operacaoFiscalId) {
    const nfSaida = await buscarNfSaida(operacaoFiscalId);
    if (nfSaida?.id) return nfSaida;
    const nfEntrada = await buscarNfEntrada(operacaoFiscalId);
    if (nfEntrada?.id) return nfEntrada;
    return false;
  }

  const onClickCadastrarOperacaoFiscal = function () {
    history.push("/fiscal/operacoes-fiscais/create");
  };

  const onClickEditOperacaoFiscal = function (id) {
    history.push(`/fiscal/operacoes-fiscais/${id}`);
  };

  const onClickDeleteOperacaoFiscal = async function (id) {
    const data = operacoesFiscais.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.descricao}`
    );
    if (retornoAlerta.isConfirmed) {
      const isUsed = await buscarUsoOperacaoFiscal(id);
      if (!isUsed) {
        setLoading(true);
        const result = await operacaoFiscalService.deletar(id);
        if (!result.isAxiosError) {
          buscaOperacoesFiscais();
          notification.deletadoSucesso();
        } else {
          setLoading(false);
          responseErros(result);
        }
      } else {
        notification.alertaGenericos(
          "A Operação Fiscal está sendo utilizada em documentos do sistema"
        );
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const result = await operacaoFiscalService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setOperacoesFiscais(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaOperacoesFiscais(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Operações fiscais"
      loading={loading}
      onClickCadastrar={onClickCadastrarOperacaoFiscal}
    >
      <DataGrid
        rows={operacoesFiscais}
        columns={colunas}
        onClickEdit={onClickEditOperacaoFiscal}
        paginationMode={"server"}
        rowCount={rowCount}
        onClickDelete={onClickDeleteOperacaoFiscal}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListOperacoesFiscais;
