import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Loading,
  MenuCustom,
  BotaoVoltar,
  CardActions,
} from "../../components";

const Minimal = ({ children, loading, ButtonMenu, title, cardAction }) => {
  return (
    <Container>
      <MenuCustom className="d-flex justify-content-end">
        <BotaoVoltar />
        <div>{ButtonMenu}</div>
      </MenuCustom>
      <Card
        className="p-2"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <CardHeader title={title} />
        <CardContent>{children}</CardContent>
        {cardAction && <CardActions>{cardAction}</CardActions>}
      </Card>
      <Loading loading={loading} />
    </Container>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
};

export default Minimal;
