import { useState, useEffect } from "react";
import { AddLayout } from "../../../layouts";
import { Grid, TextField, Autocomplete, Button } from "../../../components";
import { InputErros } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import {
  PerfilsUsuariosService,
  ModulosService,
  notification,
  history,
} from "../../../services";
import { ModulosAcordionComponent } from "./components";
import { perfilUsuarioValidator } from "./middlewares";
import { useImportContext } from "../../../contexts/import.context";
import { PerfilUsuario, Permissao } from "./entities";

const AddPerfilUsuario = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [perfil, setPerfil] = useState({});
  const [perfilUsuarios, setPerfilUsuarios] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const perfilsUsuariosService = new PerfilsUsuariosService();
  const modulosService = new ModulosService();

  async function buscarDadosIniciais() {
    const perfilResult = await buscaPerfil(id);
    if (id) {
      document.getElementById("nome").value = perfilResult.nome;
    }
    buscarModulos(perfilResult);
    buscaPerfilUsuarios();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  const buscaPerfilUsuarios = () => {
    if (!id) {
      const filtros = {
        padrao: "false",
      };
      perfilsUsuariosService.getAll(filtros).then((result) => {
        if (!result.isAxiosError) {
          setPerfilUsuarios(result.data.rows);
        }
      });
    }
  };

  const buscaPerfil = async (perfilUsuarioId) => {
    if (perfilUsuarioId) {
      setLoading(true);
      const result = await perfilsUsuariosService.getById(perfilUsuarioId);
      if (!result.isAxiosError) {
        if (!result.data) {
          initialCollapses();
          voltar();
          return;
        }
        setPerfil(result.data);
        return result.data;
      } else {
        responseErros(result);
      }
      setLoading(false);
    }
  };

  const buscarModulos = async (perfilUsuario) => {
    setLoading(true);
    const result = await modulosService.getAll();
    if (!result.isAxiosError) {
      setModulos(result.data);
      adicionarPermisoesModulosEmPerfil(result.data, perfilUsuario);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const adicionarPermisoesModulosEmPerfil = (listaModulos, perfilUsuario) => {
    const modulos = perfilUsuario?.permisoes?.length
      ? [...perfilUsuario.permisoes, ...listaModulos]
      : listaModulos;
    const idsUnicos = new Set();
    const permisoes = [];
    modulos.forEach((modulo) => {
      const moduloId = modulo.pctModuloId || modulo.id;
      if (!idsUnicos.has(moduloId)) {
        idsUnicos.add(moduloId);
        permisoes.push(new Permissao(modulo, modulo.id));
      }
    });
    setPerfil({
      ...perfilUsuario,
      permisoes,
    });
  };

  const onClickSalvarPerfil = async (indiceSelecionado) => {
    const perfilData = {
      ...perfil,
      nome: document.getElementById("nome")?.value,
    };
    const perfilUsuarioDto = new PerfilUsuario(perfilData);
    perfilUsuarioValidator
      .validate(perfilUsuarioDto, { abortEarly: false })
      .then(async () => {
        if (id) {
          atualizarPerfil(perfilUsuarioDto);
        } else {
          salvarPerfil(indiceSelecionado, perfilUsuarioDto);
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const salvarPerfil = async (indiceSelecionado, perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.cadastrar(perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.cadastroSucesso();
      if (indiceSelecionado === 0) {
        history.goBack();
      } else {
        adicionarPermisoesModulosEmPerfil(modulos);
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarPerfil = async (perfilUsuarioDto) => {
    setLoading(true);
    const res = await perfilsUsuariosService.atualizar(id, perfilUsuarioDto);
    setLoading(false);
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      history.goBack();
    } else {
      responseErros(res);
    }
  };

  const onChangePerfilUsuarioCopia = async (event, newValue) => {
    if (newValue) {
      setLoading(true);
      const perfilResult = await buscaPerfil(newValue.id);
      await buscarModulos(perfilResult);
    }
  };

  function onclickAdicionarTodasPermisoes() {
    const permisoes = perfil.permisoes.map((permisoes) => {
      for (const key in permisoes) {
        if (key != "pctModuloId") {
          permisoes[key] = true;
        }
      }
      return permisoes;
    });
    setPerfil({
      permisoes,
    });
    notification.sucessoGenericos('Permissões Adicionadas');
  }

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Perfil Usuario" : "Cadastrar Perfil Usuario"}
      onClickSalvar={onClickSalvarPerfil}
      loading={loading}
      userLog={id ? {} : null}
      codigo={id ? `Código Nº ${id}` : null}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="nome"
            name="nome"
            label="Nome"
            variant="outlined"
            fullWidth
            required
            error={inputErros.get("nome")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="perfilUsuarioCopia"
            name="perfilUsuarioCopia"
            disabled={id ? true : false}
            getOptionLabel={(option) => option?.nome ?? ""}
            options={perfilUsuarios}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Perfil de Usuário Copia"
                variant="outlined"
              />
            )}
            onChange={onChangePerfilUsuarioCopia}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            type="button"
            onClick={() => onclickAdicionarTodasPermisoes()}
            variant="contained"
            className="button-add"
            color="primary"
          >
            ADICIONAR TODAS PERMISSÕES
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {modulos.map((modulo) => (
          <ModulosAcordionComponent
            key={modulo.id}
            modulo={modulo}
            setPerfil={setPerfil}
            perfil={perfil}
          />
        ))}
      </Grid>
    </AddLayout>
  );
};

export default AddPerfilUsuario;
