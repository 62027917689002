import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid, Grid, Typography, Tooltip, Icon } from "../../../components";
import {
  history,
  NfSaidaService,
  OperacaoFiscalService,
  PessoaService,
  MeioPagamentoService,
  CondicoesPagamentoService,
} from "../../../services";
import { formatDate, formatPrice, responseErros } from "../../../utils";
import {
  FormFiltroAvancado,
  ImportarOrcamentoDialog,
  ImportarPedidoDialog,
  DevolverMercadoriaDialog,
  DevolverMercadoriaFornecedorDialog,
  NfeComplementarDialog,
  ButtonMenu,
  ImportarDocumentoDialog,
  BaixarXmlDialog,
  InutilizacaoDfeDialog,
} from "./components";
import { format } from "date-fns";
import { value } from "../../../helpers";
import { useImportContext } from "../../../contexts/import.context";
import { filtrosUrl } from "../../../helpers";
const nfSaidaService = new NfSaidaService();
const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const meiosDePagamentoService = new MeioPagamentoService();
const condicoesDeVendaService = new CondicoesPagamentoService();

const ListNfSaidaView = () => {
  const { setNfSaidaDto } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [nfsSaida, setNfsSaida] = useState([]);
  const [nfSaida, setNfSaida] = useState({});
  const [listaPessoas, setListaPessoas] = useState([]);
  const [linhasSelecionadas, setLinhasSelecionadas] = useState([]);
  const [listaOperacoesFiscais, setListaOperacoesFiscais] = useState([]);
  const [listaMeiosPagamento, setListaMeiosPagamento] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [listaCondicoesVenda, setListaCondicoesVenda] = useState([]);
  const [documentoSelectionModel, setDocumentoSelectionModel] = useState([]);
  const [openImportarOrcamentoDialog, setOpenImportarOrcamentoDialog] =
    useState(false);
  const [openImportarPedidoDialog, setOpenImportarPedidoDialog] =
    useState(false);
  const [openDevolverMercadoriaDialog, setOpenDevolverMercadoriaDialog] =
    useState(false);
  const [openImportarDocumentoDialog, setOpenImportarDocumentoDialog] =
    useState(false);
  const [
    openDevolverMercadoriaFornecedorDialog,
    setOpenDevolverMercadoriaFornecedorDialog,
  ] = useState(false);
  const [filtrosDialog, setFiltrosDialog] = useState({});
  const [openNfeComplementarDialog, setOpenNfeComplementarDialog] =
    useState(false);
  const [openInutilizacaoDfeDialog, setOpenInutilizacaoDfeDialog] =
    useState(false);
  const [openBaixarXmlDialog, setOpenBaixarXmlDialog] = useState(false);
  const status = [
    {
      id: 1,
      nome: "Enviada",
      cor: "#4FBB53",
    },
    {
      id: 2,
      nome: "Faturada",
      cor: "#004780",
    },
    {
      id: 3,
      nome: "Pendente",
      cor: "#EA9D2B",
    },
    {
      id: 4,
      nome: "Cancelada",
      cor: "#DC3545",
    },
    {
      id: 5,
      nome: "Em Contigência",
      cor: "#BFBFBF",
    },
    {
      id: 6,
      nome: "Denegada",
      cor: "#70309F",
    },
    {
      id: 7,
      nome: "Em Processamento",
      cor: "#A0522D",
    },
  ];

  const buttonSelectOpcoes = [
    "Importar Orçamento",
    "Importar Pedido",
    "Importar Documento",
    "Devolução de Mercadoria do Cliente",
    "Devolução de Mercadoria ao Fornecedor",
    "NF-e Complementar",
    "NF-e de Ajuste",
    "Operação de Entrada",
    "Inutilização de DF-e",
  ];

  const colunas = [
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      flex: 50,
      renderCell: (params) => obterIconeStatus(params.value),
    },
    {
      field: "serieDocumento",
      headerName: "Série",
      width: 80,
      valueGetter: (params) => value.text(params.value),
    },
    {
      field: "numeroDocumento",
      headerName: "Nº Documento",
      flex: 90,
      filtrar: true,
    },
    {
      field: "operacaoFiscalId",
      headerName: "Operação Fiscal",
      flex: 110,
      valueGetter: (params) =>
        listaOperacoesFiscais.find((item) => item.id == params.value)
          ?.descricao,
    },
    {
      field: "dataEmissao",
      headerName: "Data de Emissão",
      width: 150,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "clienteNome",
      headerName: "Cliente",
      width: 250,
      sortable: false,
      valueGetter: (params) =>
        params.value ??
        listaPessoas.find((item) => item.id == params.row.clienteId)
          ?.nomeRazaoSocial,
    },
    {
      field: "nfSaidaItens",
      headerName: "Total da Venda",
      flex: 200,
      sortable: false,
      valueGetter: (params) => {
        const totalItensDespesasNfSaida =
          params.value
            .map((item) => item.subtotal)
            .reduce((acumulador, total) => acumulador + total, 0) +
          params.row.acrescimo -
          params.row.desconto +
          params.row.despesas;
        const totalNfSaida = params.row.freteCompoeNota
          ? totalItensDespesasNfSaida + params.row.frete
          : totalItensDespesasNfSaida;
        return formatPrice(totalNfSaida);
      },
    },
  ];

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscarNfSaida(filtros);
  }, []);

  function obterIconeStatus(status) {
    switch (status) {
      case "PENDENTE":
        return (
          <Tooltip title="Pendente" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#EA9D2B", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "ENVIADA":
        return (
          <Tooltip title="Enviada" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#4FBB53", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "CONTINGENCIA":
        return (
          <Tooltip title="Em Contigência" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#BFBFBF", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "CANCELADA":
        return (
          <Tooltip title="Cancelada" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#DC3545", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "DENEGADA":
        return (
          <Tooltip title="Denegada" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#70309F", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "EM PROCESSAMENTO":
        return (
          <Tooltip title="Em Processamento" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#A0522D", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
      case "FATURADA":
        return (
          <Tooltip title="Em Processamento" arrow placement="right">
            <Icon>
              <i
                className="ph-fill ph-circle"
                style={{ color: "#004780", fontSize: 18 }}
              ></i>
            </Icon>
          </Tooltip>
        );
    }
  }

  const buscarNfSaida = async function (filtro) {
    setLoading(true);
    const result = await nfSaidaService.getAll(filtro);
    if (!result.isAxiosError) {
      setNfsSaida(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosNfsSaida(result.data.rows);
      filtrosUrl.set(filtro);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarRelacionamentosNfsSaida = (listaDocumentos) => {
    const idsPessoas = [];
    const idsOperacoesFiscais = [];
    const idsMeiosPagamento = [];
    const idsCondicoesVenda = [];
    for (const objeto of listaDocumentos) {
      idsOperacoesFiscais.push(objeto.operacaoFiscalId);
      if (objeto.clienteId) idsPessoas.push(objeto.clienteId);
      if (objeto.condicaoPagamentoId)
        idsCondicoesVenda.push(objeto.condicaoPagamentoId);
      if (objeto.meioPagamentoId)
        idsMeiosPagamento.push(objeto.meioPagamentoId);
    }
    const idsUnicosOperacoesFiscais = [...new Set(idsOperacoesFiscais)];
    const idsUnicosPessoas = [...new Set(idsPessoas)];
    const idsUnicosCondicoesVenda = [...new Set(idsPessoas)];
    const idsUnicosMeiosPagamento = [...new Set(idsPessoas)];
    buscarOperacoesFiscais(idsUnicosOperacoesFiscais);
    buscarPessoas(idsUnicosPessoas);
    buscarMeiosPagamento(idsUnicosMeiosPagamento);
    buscarCondicoesVenda(idsUnicosCondicoesVenda);
  };

  const buscarOperacoesFiscais = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    operacaoFiscalService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaOperacoesFiscais(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarPessoas = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        const listaPessoas = result.data;
        setListaPessoas(listaPessoas);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    meiosDePagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaMeiosPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarCondicoesVenda = (listaIds) => {
    const filtro = {
      id: listaIds.length > 0 ? listaIds : undefined,
      nonPaginated: true,
    };
    condicoesDeVendaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaCondicoesVenda(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const onClickCadastrarNfSaida = function (descricao) {
    switch (descricao) {
      case "Importar Orçamento":
        setOpenImportarOrcamentoDialog(true);
        break;
      case "Importar Pedido":
        setOpenImportarPedidoDialog(true);
        break;
      case "Importar Documento":
        setOpenImportarDocumentoDialog(true);
        break;
      case "Devolução de Mercadoria do Cliente":
        setOpenDevolverMercadoriaDialog(true);
        break;
      case "Devolução de Mercadoria ao Fornecedor":
        setOpenDevolverMercadoriaFornecedorDialog(true);
        break;
      case "NF-e Complementar":
        setNfSaida({
          finalidade: "finalNfeComplementar",
        });
        setOpenNfeComplementarDialog(true);
        break;
      case "Inutilização de DF-e":
        setOpenInutilizacaoDfeDialog(true);
        break;
      case "NF-e de Ajuste":
        setNfSaida({
          finalidade: "finalNfeAjuste",
        });
        setOpenNfeComplementarDialog(true);
        break;
      case "Operação de Entrada":
        setNfSaidaDto({
          operacaoEntrada: true,
        });
        history.push("/faturamento/nf-saida/create");
        break;
      default:
        history.push("/faturamento/nf-saida/create");
        break;
    }
  };

  const onClickEditNfSaida = function (id) {
    history.push(`/faturamento/nf-saida/${id}`);
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataEmissaoStart &&
      filtrosAvancadosTemp.dataEmissaoEnd
    ) {
      porIntervalo.push({
        coluna: "dataEmissao",
        de: filtrosAvancadosTemp.dataEmissaoStart,
        ate: filtrosAvancadosTemp.dataEmissaoEnd,
      });
    }
    if (filtrosAvancadosTemp.totalStart && filtrosAvancadosTemp.totalEnd) {
      porIntervalo.push({
        coluna: "totalVenda",
        de: parseFloat(filtrosAvancadosTemp.totalStart),
        ate: parseFloat(filtrosAvancadosTemp.totalEnd),
      });
    }
    delete filtrosAvancadosTemp.dataEmissaoStart;
    delete filtrosAvancadosTemp.dataEmissaoEnd;
    delete filtrosAvancadosTemp.totalStart;
    delete filtrosAvancadosTemp.totalEnd;
    setLoading(true);
    const result = await nfSaidaService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setNfsSaida(result.data.rows);
      setRowCount(result.data.count);
      buscarRelacionamentosNfsSaida(result.data.rows);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarNfSaida(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Nf's de Saida"
      loading={loading}
      onClickCadastrar={onClickCadastrarNfSaida}
      buttonSelectOpcoes={buttonSelectOpcoes}
      ButtonMenu={
        <ButtonMenu
          data={linhasSelecionadas}
          setOpenBaixarXmlDialog={setOpenBaixarXmlDialog}
        />
      }
    >
      <Grid container spacing={2}>
        {status.map((status) => (
          <span className="d-flex m-3" key={status.id}>
            <i
              className="ph-fill ph-circle"
              style={{ color: status.cor, fontSize: 18, marginRight: 8 }}
            ></i>
            <Typography className="me-1">{status.nome}</Typography>
          </span>
        ))}
      </Grid>
      <DataGrid
        checkboxSelection={true}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setLinhasSelecionadas(newRowSelectionModel);
        }}
        rows={nfsSaida}
        columns={colunas}
        onClickEdit={onClickEditNfSaida}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={sendServerDatagrid}
        FormFiltroAvancado={FormFiltroAvancado}
      />
      <ImportarOrcamentoDialog
        openImportarOrcamentoDialog={openImportarOrcamentoDialog}
        setOpenImportarOrcamentoDialog={setOpenImportarOrcamentoDialog}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
      />
      <ImportarPedidoDialog
        openImportarPedidoDialog={openImportarPedidoDialog}
        setOpenImportarPedidoDialog={setOpenImportarPedidoDialog}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
      />
      <ImportarDocumentoDialog
        openImportarDocumentoDialog={openImportarDocumentoDialog}
        setOpenImportarDocumentoDialog={setOpenImportarDocumentoDialog}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        buscarNfSaida={buscarNfSaida}
        buscarNfSaidaAvancado={buscarPesquisaAvancada}
        listaPessoas={listaPessoas}
        listaCondicoesVenda={listaCondicoesVenda}
        listaMeiosPagamento={listaMeiosPagamento}
        listaOperacoesFiscaisIniciais={listaOperacoesFiscais}
        setLoading={setLoading}
        documentoSelectionModel={documentoSelectionModel}
        setDocumentoSelectionModel={setDocumentoSelectionModel}
      />
      <DevolverMercadoriaDialog
        openDevolverMercadoriaDialog={openDevolverMercadoriaDialog}
        setOpenDevolverMercadoriaDialog={setOpenDevolverMercadoriaDialog}
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
        buscarNfSaida={buscarNfSaida}
        buscarNfSaidaAvancado={buscarPesquisaAvancada}
        listaPessoas={listaPessoas}
        listaCondicoesVenda={listaCondicoesVenda}
        listaMeiosPagamento={listaMeiosPagamento}
        nfsSaida={nfsSaida}
        listaOperacoesFiscaisIniciais={listaOperacoesFiscais}
      />
      <DevolverMercadoriaFornecedorDialog
        openDevolverMercadoriaFornecedorDialog={
          openDevolverMercadoriaFornecedorDialog
        }
        setOpenDevolverMercadoriaFornecedorDialog={
          setOpenDevolverMercadoriaFornecedorDialog
        }
        filtrosDialog={filtrosDialog}
        setFiltrosDialog={setFiltrosDialog}
      />
      <NfeComplementarDialog
        openNfeComplementarDialog={openNfeComplementarDialog}
        setOpenNfeComplementarDialog={setOpenNfeComplementarDialog}
        nfSaida={nfSaida}
        setNfSaida={setNfSaida}
        setLoading={setLoading}
      />
      <InutilizacaoDfeDialog
        openInutilizacaoDfeDialog={openInutilizacaoDfeDialog}
        setOpenInutilizacaoDfeDialog={setOpenInutilizacaoDfeDialog}
        setLoading={setLoading}
      />
      <BaixarXmlDialog
        openBaixarXmlDialog={openBaixarXmlDialog}
        setOpenBaixarXmlDialog={setOpenBaixarXmlDialog}
      />
    </ListLayout>
  );
};

export default ListNfSaidaView;
