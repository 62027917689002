import { useState, useEffect } from "react";
import {
  Autocomplete,
  CardContent,
  Grid,
  FormControlLabel,
  FormControl,
  Switch,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  InputAdornment,
} from "../../../components";
import PasswordStrengthBar from "react-password-strength-bar";
import { AddLayout } from "../../../layouts";
import {
  notification,
  UsuarioService,
  PerfilsUsuariosService,
} from "../../../services";
import { InputErros, value } from "../../../helpers";
import { responseErros, voltar } from "../../../utils";
import { usuarioValidator, usuarioUpdateValidator } from "./middlewares";
import "./add-usuarios.css";
import { useImportContext } from "../../../contexts/import.context";
import { Usuario } from "../entities";

const AddUsuariosView = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [perfilList, setPerfilList] = useState([]);
  const [usuario, setUsuario] = useState({ ativado: true });
  const [senhaScore, setSenhaScore] = useState();
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const [emailValido, setEmailValido] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const usuarioService = new UsuarioService();
  const perfilsUsuariosService = new PerfilsUsuariosService();

  useEffect(() => {
    buscaPerfilList();
    if (id) {
      buscaUsuario();
    }
  }, []);

  const buscaPerfilList = async () => {
    const filtros = {
      nonPaginated: true,
    };
    perfilsUsuariosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setPerfilList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaUsuario = async () => {
    setLoading(true);
    const result = await usuarioService.getById(id);
    if (!result.isAxiosError) {
      if (!result.data) {
        initialCollapses();
        voltar();
        return;
      }
      setUsuario(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const handleUserInputChange = (e, name) => {
    if (e.target.name == "senha" || e.target.name == "confirmarSenha") {
      return setUsuario((prevState) => ({
        ...prevState,
        [e.target.name]: String(e.target.value).trim(),
      }));
    }
    setUsuario((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const validarSenha = (usuarioDto) => {
    const isStrong = usuarioDto?.verificarSenhaForte(usuario, senhaScore);
    if (!isStrong) {
      inputErros.gerar("senha");
      notification.alertaGenericos("Senha fraca");
    }
    return isStrong;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const validarEmailFormat = (usuarioDto) => {
    const isValid = usuarioDto.validarEmail();
    return isValid;
  };

  const handleSubmitUsuario = (indiceSelecionado) => {
    const usuarioDto = new Usuario(usuario);
    const passwordIsValid = validarSenha(usuarioDto);
    if (id) {
      if (!passwordIsValid) return;
      atualizarUsuario(usuarioDto);
    } else {
      if (!emailValido) {
        return notification.alertaGenericos("E-mail já cadastrado");
      }
      if (passwordIsValid) {
        cadastrarUsuario(indiceSelecionado, usuarioDto);
      }
    }
  };

  const atualizarUsuario = async (usuarioDto) => {
    const formatEmailValid = validarEmailFormat(usuarioDto);
    if (!formatEmailValid) return;
    usuarioUpdateValidator
      .validate(usuarioDto, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const result = await usuarioService.atualizar(id, usuarioDto);
        if (!result.isAxiosError) {
          notification.alteracaoSucesso();
          initialCollapses();
          voltar();
        } else {
          responseErros(result);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const cadastrarUsuario = async (acaoCastro, usuarioDto) => {
    const formatEmailValid = validarEmailFormat(usuarioDto);
    if (!formatEmailValid) return;
    usuarioValidator
      .validate(usuarioDto, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const result = await usuarioService.cadastrar(usuarioDto);
        if (!result.isAxiosError) {
          notification.cadastroSucesso();
          if (acaoCastro == 0) {
            initialCollapses();
            voltar();
          } else {
            setUsuario({});
          }
        } else responseErros(result);
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const handleChangeSwitch = (event) => {
    setUsuario({
      ...usuario,
      [event.target.name]: event.target.checked,
    });
  };

  const onChangeAutocomplete = (name, value) => {
    setUsuario({
      ...usuario,
      [name]: value ? value.id : undefined,
    });
  };

  const validarEmail = () => {
    usuarioService.validarEmail(usuario.email).then((result) => {
      if (!result.isAxiosError) {
        if (result.data) {
          setEmailValido(false);
          inputErros.gerar("email");
          notification.alertaGenericos("E-mail já cadastrado");
        } else {
          setEmailValido(true);
          inputErros.set(null);
        }
      }
    });
  };

  return (
    <AddLayout
      id={id}
      onClickSalvar={handleSubmitUsuario}
      loading={loading}
      codigo={id ? `Código Nº ${id}` : null}
    >
      <CardContent>
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={6}>
            <CardHeader title={id ? "Editar Usuário" : "Cadastrar Usuário"} />
          </Grid>
          <Grid item xs={6} className="d-flex justify-content-end">
            <FormControlLabel
              control={
                <Switch
                  id="ativado"
                  name="ativado"
                  checked={
                    usuario?.ativado === true || usuario?.ativado === "true"
                      ? true
                      : false
                  }
                  onChange={handleChangeSwitch}
                  color="primary"
                />
              }
              label={
                usuario?.ativado === true || usuario?.ativado === "true"
                  ? "Ativado"
                  : "Desativado"
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="formPadding">
          <Grid item xs={4}>
            <TextField
              id="email-usuario"
              name="email-usuario"
              label="E-mail"
              variant={id ? "filled" : "outlined"}
              value={value.text(usuario?.email)}
              onChange={(e) => handleUserInputChange(e, "email")}
              onBlur={() => validarEmail()}
              fullWidth
              required
              disabled={id ? true : false}
              error={inputErros.get("email")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              value={value.text(usuario?.nome)}
              onChange={(e) => handleUserInputChange(e, "nome")}
              fullWidth
              required
              error={inputErros.get("nome")}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                id="pctPerfilUsuarioId"
                name="pctPerfilUsuarioId"
                options={perfilList}
                noOptionsText="Sem opções"
                autoHighlight
                disabled={usuario.padrao}
                getOptionLabel={(option) => (option.nome ? option.nome : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Perfil"
                    required
                    variant={usuario.padrao ? "filled" : "outlined"}
                    error={inputErros.get("pctPerfilUsuarioId")}
                  />
                )}
                onChange={(e, value) =>
                  onChangeAutocomplete("pctPerfilUsuarioId", value)
                }
                value={value.autoComplete(
                  perfilList,
                  usuario?.pctPerfilUsuarioId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="formPadding">
          <Grid item xs={5}>
            <TextField
              id="senha"
              name="senha"
              label="Senha"
              variant="outlined"
              margin="normal"
              onChange={(e) => handleUserInputChange(e, "senha")}
              fullWidth
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <i
                          className="ph-fill ph-eye-slash"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      ) : (
                        <i
                          className="ph-fill ph-eye"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={usuario?.senha ? usuario.senha : ""}
              error={inputErros.get("senha")}
            />
            {senhaScore < 2 ? (
              <Typography className="passwordErrorInput">
                Deve ter de 6 a 20 caracteres e no mínimo razoável
              </Typography>
            ) : (
              ""
            )}
            <PasswordStrengthBar
              scoreWords={[
                "senha fraca",
                "senha fraca",
                "senha razoável",
                "senha boa",
                "senha forte",
              ]}
              shortScoreWord={"senha curta"}
              password={usuario?.senha}
              onChangeScore={(score) => {
                setSenhaScore(score);
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="confirmarSenha"
              name="confirmarSenha"
              label="Confirmar senha"
              variant="outlined"
              margin="normal"
              onChange={(e) => handleUserInputChange(e, "confirmarSenha")}
              fullWidth
              type={showPassword ? "text" : "password"}
              autocomplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <i
                          className="ph-fill ph-eye-slash"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      ) : (
                        <i
                          className="ph-fill ph-eye"
                          style={{ color: "#494C62", fontSize: 18 }}
                        ></i>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={usuario?.confirmarSenha ? usuario.confirmarSenha : ""}
              error={inputErros.get("confirmarSenha")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </AddLayout>
  );
};

export default AddUsuariosView;
