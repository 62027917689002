import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { history, ModeloGradeService, notification } from "../../../services";
import { responseErros } from "../../../utils";
import { FormFiltroAvancado } from "./components";

const ListModelosGrade = () => {
  const modeloGradeService = new ModeloGradeService();
  const [loading, setLoading] = useState(false);
  const [modelosGrade, setModelosGrade] = useState([]);
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 500,
      filtrar: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1500,
    },
  ];

  useEffect(() => {
    buscarModelosGrade();
  }, []);

  const buscarModelosGrade = async function (filter) {
    setLoading(true);
    const result = await modeloGradeService.getAll(filter);
    if (!result.isAxiosError) {
      setModelosGrade(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarModeloGrade = function () {
    history.push("modelos-grade/create");
  };

  const onClickEditModeloGrade = function (id) {
    history.push(`modelos-grade/${id}`);
  };

  const onClickDeleteModeloGrade = async function (id) {
    const modeloGrade = modelosGrade.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${modeloGrade.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await modeloGradeService.deletar(id);
      if (!result.isAxiosError) {
        buscarModelosGrade();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const result = await modeloGradeService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setModelosGrade(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarModelosGrade(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Modelos de grade"
      loading={loading}
      onClickCadastrar={onClickCadastrarModeloGrade}
    >
      <DataGrid
        rows={modelosGrade}
        columns={colunas}
        onClickEdit={onClickEditModeloGrade}
        onClickDelete={onClickDeleteModeloGrade}
        paginationMode={"server"}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListModelosGrade;
