import {
  Grid,
  TextFielDecimalNumbers,
  TextField,
} from "../../../../../../components";
import { formatPrice } from "../../../../../../utils";
import { nfSaidaTotalTributos } from "../../../entities";

const ImpostoProdutoIcmsStTab = ({
  produto,
  produtoEditado,
  onChangeInput,
  nfSaida,
}) => {
  return (
    <>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={3}>
          <TextField
            id="baseIcmsSt"
            name="baseIcmsSt"
            label="Base de ICMS ST"
            variant="outlined"
            margin="normal"
            disabled={true}
            value={formatPrice(
              nfSaidaTotalTributos.calculaBaseIcmsSt(
                { ...produto, ...produtoEditado },
                nfSaida
              )
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaIcmsCredito"
            name="aliquotaIcmsCredito"
            label="Alíquota ICMS Crédito"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.aliquotaIcmsCredito
                ? produtoEditado.aliquotaIcmsCredito
                : produto?.aliquotaIcmsCredito
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaIcmsDebito"
            name="aliquotaIcmsDebito"
            label="Alíquota ICMS Débito"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.aliquotaIcmsDebito
                ? produtoEditado.aliquotaIcmsDebito
                : produto?.aliquotaIcmsDebito
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="aliquotaMva"
            name="aliquotaMva"
            label="Alíquota MVA"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.aliquotaMva
                ? produtoEditado.aliquotaMva
                : produto?.aliquotaMva
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="impostoIcmsStAliquota"
            name="impostoIcmsStAliquota"
            label="Alíquota ICMS ST"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.impostoIcmsStAliquota
                ? produtoEditado.impostoIcmsStAliquota
                : produto?.impostoIcmsStAliquota
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFielDecimalNumbers
            id="impostoIcmsStReducao"
            name="impostoIcmsStReducao"
            label="% Redução ICMS ST"
            margin="normal"
            variant="outlined"
            fullWidth
            disabled={!nfSaida?.documentoDevolucaoFornecedor}
            value={
              produtoEditado?.impostoIcmsStReducao
                ? produtoEditado.impostoIcmsStReducao
                : produto?.impostoIcmsStReducao
            }
            onBlur={onChangeInput}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="valorIcms"
            name="valorIcms"
            label="Valor de ICMS ST"
            variant="outlined"
            margin="normal"
            disabled
            value={formatPrice(
              nfSaidaTotalTributos.calculaValorIcmsSt(
                { ...produto, ...produtoEditado },
                nfSaida
              )
            )}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ImpostoProdutoIcmsStTab;
