import React, { useState, useEffect } from "react";
import { AddLayout } from "../../../layouts";
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  CurrencyInput,
  DataGrid,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  Radio,
  TextField,
  TextFielDecimalNumbers,
  Typography,
  IconButton,
  InputAdornment,
} from "../../../components";
import {
  history,
  notification,
  TabelaPrecoService,
  CondicoesPagamentoService,
  ProdutosService,
  GrupoProdutosService,
} from "../../../services";
import { value, InputErros } from "../../../helpers";
import {
  responseErros,
  formatPrice,
  formatPercentage,
  timeoutBuscaAutocomplete,
  unformatPrice,
  voltar,
} from "../../../utils/";
import PesquisaAvancada from "./components/pesquisaAvancada.view";
import tabelaPrecoValidator from "./middlewares/tabela-preco.validator";
import "./add-tabela-preco.css";
import { useImportContext } from "../../../contexts/import.context";

const tabelaPrecoService = new TabelaPrecoService();
const condicaoPagamentoService = new CondicoesPagamentoService();
const produtosService = new ProdutosService();
const grupoProdutosService = new GrupoProdutosService();

const AddTabelaPreco = ({ match }) => {
  const { id } = match.params;
  const { initialCollapses } = useImportContext();
  const [loading, setLoading] = useState(false);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [initialTabelaPrecoState, setInitialTabelaPrecoState] = useState(null);
  const [tabelaPreco, setTabelaPreco] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [grupoProdutos, setGrupoProdutos] = useState([]);
  const [listaCondicoesPagamento, setListaCondicoesPagamento] = useState([]);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaGruposProdutos, setListaGruposProdutos] = useState([]);
  const [listaTabelaPrecoProdutos, setListaTabelaPrecoProdutos] = useState([]);
  const [deletedTabelaPrecoProdutoIds, setDeletedTabelaPrecoProdutoIds] =
    useState([]);
  const [totalListaProdutos, setTotalListaProdutos] = useState(0);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openDescontoEditDialog, setOpenDescontoEditDialog] = useState(false);
  const [produtoIdDesconto, setProdutoIdDesconto] = useState(null);
  const [descontoInputError, setDescontoInputError] = useState(false);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 500,
    },
    {
      field: "descricao",
      headerName: "Produto",
      flex: 800,
    },
    {
      field: "precoVenda",
      headerName: "Preço atual",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
    {
      field: "descontoTabela",
      headerName: "Desconto",
      flex: 500,
      valueGetter: (params) => {
        const data = tabelaPreco?.tabelaPrecoProdutos.find(
          (tabelaPrecoProduto) => tabelaPrecoProduto.id === params.id
        );
        if (data) {
          return data.aplicarDescontoEm === "Valor"
            ? formatPrice(parseFloat(params.value))
            : formatPercentage(params.value);
        }
      },
    },
    {
      field: "precoTabela",
      headerName: "Preço tabela",
      flex: 500,
      valueGetter: (params) => formatPrice(params.value),
    },
  ];

  async function buscarDadosIniciais() {
    await buscaTabelaPreco();
    buscaCondicoesPagamento();
    buscaGruposProdutos();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  const buscaTabelaPreco = async () => {
    if (!id) return;
    setLoading(true);
    const { data } = await tabelaPrecoService.getById(id);
    if (!data) {
      initialCollapses();
      voltar();
      return;
    }
    setInitialTabelaPrecoState(data);
    setTabelaPreco(data);
    buscaRelacaoProdutos(data.tabelaPrecoProdutos);
    setLoading(false);
  };

  const buscaRelacaoProdutos = (tabelaPreco) => {
    if (!tabelaPreco) return;
    const produtosIds = new Set(tabelaPreco.map(({ produtoId }) => produtoId));
    const produtosFiltrados = produtos.filter((item) =>
      produtosIds.has(item.id)
    );
    setListaTabelaPrecoProdutos((prevState) =>
      prevState.map((tabelaPrecoProduto) => {
        const index = produtosFiltrados.findIndex(
          (produto) => produto.id === tabelaPrecoProduto.produtoId
        );
        if (index !== -1) {
          const { codigo, descricao, precoVenda } = produtosFiltrados[index];
          return { ...tabelaPrecoProduto, codigo, descricao, precoVenda };
        }
        return tabelaPrecoProduto;
      })
    );
  };

  const buscaCondicoesPagamento = async (query) => {
    const params = {
      ...query,
      ativado: true,
      nonPaginated: true,
    };
    const result = await condicaoPagamentoService.getAll(params);
    if (!result.isAxiosError) {
      setListaCondicoesPagamento(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscaGruposProdutos = async () => {
    const result = await grupoProdutosService.getAllAssociations();
    if (!result.isAxiosError) {
      setListaGruposProdutos(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarProdutos = (filtros) => {
    const filtro = {
      ...filtros,
      ativado: true,
      limite: 20,
    };
    produtosService.getAll(filtro).then((result) => {
      setLoadingAutoComplete(false);
      if (!result.isAxiosError) {
        setListaProdutos(result.data.rows);
      } else {
        responseErros(result);
      }
    });
  };

  const handleInputChange = (e) => {
    e.persist();
    setTabelaPreco((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleInputBlur = (e) => {
    e.persist();
    setTabelaPreco((prevState) => ({
      ...prevState,
      [e.target.name]: unformatPrice(e.target.value),
    }));
  };

  const handleRadioButtonTipoTabela = (e) => {
    setTabelaPreco((prevState) => ({
      ...prevState,
      tipoTabela: e.target.value,
    }));
  };

  const handleRadioAplicarDescontoEm = (e) => {
    setTabelaPreco((prevState) => ({
      ...prevState,
      aplicarDescontoEm: e.target.value,
    }));
  };

  const handleOpenDialog = () => {
    setOpenFilterDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleAplicarFiltro = async () => {
    if (!tabelaPreco?.descontoTabela) {
      setDescontoInputError(true);
      return notification.erroValidacao("Campo desconto tabela");
    }
    if (produtos.length === 0 && grupoProdutos.length === 0) {
      return notification.alertaGenericos(
        "Selecione ao menos um produto ou grupo de produtos."
      );
    }

    grupoProdutos.forEach((grupo) => {
      grupo?.produtos.forEach((produto) => {
        const produtoExists = listaTabelaPrecoProdutos.find(
          (item) => item.id === produto.id
        );
        if (produtoExists) {
          notification.alertaGenericos("Produto já adicionado");
        } else {
          setListaTabelaPrecoProdutos((prevState) => [
            ...prevState,
            {
              id: produto.id,
              produtoId: produto.id,
              tabelaPrecoId: tabelaPreco?.id,
              codigo: produto.codigo,
              descricao: produto.descricao,
              precoVenda: produto.precoVenda,
              aplicarDescontoEm: tabelaPreco.aplicarDescontoEm,
              descontoTabela: tabelaPreco.descontoTabela,
              precoTabela:
                tabelaPreco.aplicarDescontoEm === "Percentual"
                  ? produto.precoVenda -
                    produto.precoVenda * (tabelaPreco.descontoTabela / 100)
                  : produto.precoVenda - tabelaPreco.descontoTabela,
            },
          ]);
        }
      });
    });

    produtos.forEach((produto) => {
      const produtoExists = listaTabelaPrecoProdutos.find(
        (item) => item.id === produto.id
      );
      if (produtoExists) {
        notification.alertaGenericos("Produto já adicionado");
      } else {
        setListaTabelaPrecoProdutos((prevState) => [
          ...prevState,
          {
            id: produto.id,
            produtoId: produto.id,
            tabelaPrecoId: tabelaPreco?.id,
            codigo: produto.codigo,
            descricao: produto.descricao,
            precoVenda: produto.precoVenda,
            aplicarDescontoEm: tabelaPreco.aplicarDescontoEm,
            descontoTabela: tabelaPreco.descontoTabela,
            precoTabela:
              tabelaPreco.aplicarDescontoEm === "Percentual"
                ? produto.precoVenda -
                  produto.precoVenda * (tabelaPreco.descontoTabela / 100)
                : produto.precoVenda - tabelaPreco.descontoTabela,
          },
        ]);
      }
    });

    setGrupoProdutos([]);
    setProdutos([]);
  };

  const openEditDescontoDialog = async (id) => {
    tabelaPreco.descontoTabela = "";
    setOpenDescontoEditDialog(true);
    setProdutoIdDesconto(id);
  };

  const closeEditDescontoDialog = async () => {
    setOpenDescontoEditDialog(false);
  };

  const onClickUpdateDescontoProduto = () => {
    const data = tabelaPreco?.tabelaPrecoProdutos.find((produto) => {
      return produto.id === produtoIdDesconto;
    });

    const newDescontoProduto = {
      ...data,
      aplicarDescontoEm: tabelaPreco.aplicarDescontoEm,
      descontoTabela: tabelaPreco.descontoTabela,
      precoTabela:
        tabelaPreco.aplicarDescontoEm === "Percentual"
          ? data.precoVenda -
            data.precoVenda * (tabelaPreco.descontoTabela / 100)
          : data.precoVenda - tabelaPreco.descontoTabela,
    };

    setListaTabelaPrecoProdutos((prevState) => [
      ...prevState,
      newDescontoProduto,
    ]);
    closeEditDescontoDialog();
  };

  const onClickDeleteProduto = async function (id) {
    const data = listaTabelaPrecoProdutos.filter((item) => item.id !== id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir produto?"
    );
    if (retornoAlerta.isConfirmed) {
      setListaTabelaPrecoProdutos(data);
      setDeletedTabelaPrecoProdutoIds((prevState) => [...prevState, id]);
    }
  };

  const onInputChangeProdutos = (id) => {
    const eventValue = document.getElementById(id).value;
    if (eventValue?.length >= 2) {
      const filtros = {
        codigo: eventValue,
        codigoBarra: eventValue,
        descricao: eventValue,
      };
      setLoadingAutoComplete(true);
      timeoutBuscaAutocomplete(buscarProdutos(filtros));
    }
  };

  useEffect(() => {
    let filteredTabelaPrecoProdutos = listaTabelaPrecoProdutos.reduce(
      (acc, current) => {
        const i = acc.findIndex((item) => item?.id === current?.id);
        if (i !== -1) {
          acc.splice(i, 1);
          return acc.concat([current]);
        } else {
          return acc.concat([current]);
        }
      },
      []
    );

    let produtoCount = 0;
    for (const item of listaTabelaPrecoProdutos) {
      if (item !== null) produtoCount++;
    }
    setTotalListaProdutos(produtoCount);

    setTabelaPreco((prevState) => ({
      ...prevState,
      tabelaPrecoProdutos: [...filteredTabelaPrecoProdutos],
      deletedTabelaPrecoProdutoIds:
        filteredTabelaPrecoProdutos.length > 0
          ? [...deletedTabelaPrecoProdutoIds]
          : [],
    }));
  }, [listaTabelaPrecoProdutos, deletedTabelaPrecoProdutoIds]);

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    tabelaPrecoValidator
      .validate(tabelaPreco, { abortEarly: false })
      .then(async () => {
        if (id) {
          const response = await tabelaPrecoService.atualizar(id, tabelaPreco);
          setLoading(false);
          if (!response.isAxiosError) {
            buscaTabelaPreco();
            notification.alteracaoSucesso();
          } else {
            responseErros(response);
          }
        } else {
          const response = await tabelaPrecoService.cadastrar(tabelaPreco);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              history.goBack();
            } else {
              handleCancelar();
            }
          } else {
            responseErros(response);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        inputErros.set(err);
      });
  };

  const handleCancelar = () => {
    setProdutos([]);
    setGrupoProdutos([]);
    setTabelaPreco(initialTabelaPrecoState);
    if (initialTabelaPrecoState?.tabelaPrecoProdutos.length > 0) {
      setListaTabelaPrecoProdutos([]);
      buscaTabelaPreco();
    } else {
      setListaTabelaPrecoProdutos([]);
    }
  };

  return (
    <AddLayout onClickSalvar={handleSubmit} loading={loading}>
      <CardHeader
        title={
          tabelaPreco?.id
            ? "Editar tabela de preço"
            : "Cadastrar tabela de preço"
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              margin="normal"
              value={tabelaPreco?.nome ? tabelaPreco.nome : ""}
              onChange={(e) => {
                setInputErro([]);
                handleInputChange(e);
              }}
              fullWidth
              required
              error={inputErros.get("nome")}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                required
                id="condicaoPagamento"
                name="condicaoPagamento"
                options={listaCondicoesPagamento}
                noOptionsText="Sem opções"
                autoHighlight
                getOptionLabel={(option) => option?.descricao ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Condição de Pagamento"
                    variant="outlined"
                  />
                )}
                onChange={(e, newValue) => {
                  e.persist();
                  setTabelaPreco((prevState) => ({
                    ...prevState,
                    condicaoPagamentoId: newValue?.id,
                  }));
                }}
                value={value.autoComplete(
                  listaCondicoesPagamento,
                  tabelaPreco?.condicaoPagamentoId
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid className="tipoTabelaContainer" container spacing={2}>
          <Grid className="tipoTabela" item>
            <Typography>Tipo de tabela:</Typography>
          </Grid>
          <Grid item>
            <Radio
              checked={tabelaPreco?.tipoTabela === "Por produto"}
              onChange={handleRadioButtonTipoTabela}
              color="primary"
              value="Por produto"
              name="tipo-tabela-por-produto"
            />
          </Grid>
          <Grid className={"typography"} item>
            <Typography>Por produto</Typography>
          </Grid>
          <Grid item>
            <Radio
              checked={tabelaPreco?.tipoTabela === "Todos os produtos"}
              onChange={handleRadioButtonTipoTabela}
              color="primary"
              value="Todos os produtos"
              name="tipo-tabela-por-produto"
            />
          </Grid>
          <Grid className={"typography"} item>
            <Typography>Todos os produtos</Typography>
          </Grid>
        </Grid>
      </CardContent>
      {tabelaPreco?.tipoTabela === "Todos os produtos" ? (
        <>
          <CardHeader title={"Desconto de tabela de preço"} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Aplicar desconto em:</Typography>
              </Grid>
              <Grid item>
                <Radio
                  className={"aplicarDescontoEmRadioButton"}
                  checked={tabelaPreco?.aplicarDescontoEm === "Valor"}
                  onChange={handleRadioAplicarDescontoEm}
                  color="primary"
                  value="Valor"
                  name="tipo-tabela-por-produto"
                />
              </Grid>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Valor</Typography>
              </Grid>
              <Grid item>
                <Radio
                  className={"aplicarDescontoEmRadioButton"}
                  checked={tabelaPreco?.aplicarDescontoEm === "Percentual"}
                  onChange={handleRadioAplicarDescontoEm}
                  color="primary"
                  value="Percentual"
                  name="tipo-tabela-por-produto"
                />
              </Grid>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Percentual</Typography>
              </Grid>
              <Grid className={"descontoTabelaInput"} item xs={4}>
                {tabelaPreco?.aplicarDescontoEm === "Valor" ? (
                  <CurrencyInput
                    id="descontoTabela"
                    name="descontoTabela"
                    label="Desconto Tabela"
                    required={true}
                    value={
                      tabelaPreco?.descontoTabela
                        ? tabelaPreco.descontoTabela
                        : ""
                    }
                    onBlur={(e) => {
                      handleInputBlur(e);
                      setDescontoInputError(false);
                    }}
                    error={inputErros.get("descontoTabela")}
                  />
                ) : (
                  <TextFielDecimalNumbers
                    id="descontoTabela"
                    name="descontoTabela"
                    label="Desconto Tabela"
                    margin="normal"
                    variant="outlined"
                    required={true}
                    fullWidth
                    value={
                      tabelaPreco?.descontoTabela
                        ? tabelaPreco.descontoTabela
                        : ""
                    }
                    onBlur={(e) => {
                      handleInputBlur(e);
                      setDescontoInputError(false);
                    }}
                    error={inputErros.get("descontoTabela")}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </>
      ) : (
        <>
          <hr className="ms-3 me-3 mb-2" />
          <CardHeader
            title={"Filtro por produtos"}
            action={
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
              >
                Filtro avançado
              </Button>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <Autocomplete
                    multiple
                    id="produtoId"
                    name="produtoId"
                    options={listaProdutos}
                    autoHighlight
                    loading={loadingAutoComplete}
                    loadingText="Carregando"
                    noOptionsText="Digite e clique no ícone para buscar"
                    getOptionLabel={(option) => {
                      let descricaoProduto = `${option?.codigo} - ${option?.descricao}`;
                      if (option.codigoBarra) {
                        descricaoProduto += ` - ${option.codigoBarra}`;
                      }
                      return option ? descricaoProduto : "";
                    }}
                    filterSelectedOptions
                    disableClearable
                    onChange={(e, newValue) => {
                      e.persist();
                      setProdutos(newValue);
                      setListaProdutos([]);
                    }}
                    value={produtos}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Buscar produto"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                <IconButton
                                  sx={{
                                    position: "absolute",
                                    right: "1.2em",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                  onClick={() =>
                                    onInputChangeProdutos("produtoId")
                                  }
                                >
                                  <i
                                    className="ph-bold ph-magnifying-glass"
                                    style={{ fontSize: 18 }}
                                  ></i>
                                </IconButton>
                              </InputAdornment>
                              <React.Fragment>
                                {loadingAutoComplete ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" margin="normal" fullWidth>
                  <Autocomplete
                    multiple
                    id="grupoProdutoId"
                    name="grupoProdutoId"
                    options={listaGruposProdutos}
                    noOptionsText="Sem opções"
                    autoHighlight
                    getOptionLabel={(option) => option?.descricao ?? ""}
                    filterSelectedOptions
                    onChange={(e, newValue) => {
                      e.persist();
                      setGrupoProdutos(newValue);
                    }}
                    value={grupoProdutos}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Grupos de produtos"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Tipo de desconto:</Typography>
              </Grid>
              <Grid item>
                <Radio
                  className={"aplicarDescontoEmRadioButton"}
                  checked={tabelaPreco?.aplicarDescontoEm === "Valor"}
                  onChange={handleRadioAplicarDescontoEm}
                  color="primary"
                  value="Valor"
                  name="tipo-tabela-por-produto"
                />
              </Grid>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Valor</Typography>
              </Grid>
              <Grid item>
                <Radio
                  className={"aplicarDescontoEmRadioButton"}
                  checked={tabelaPreco?.aplicarDescontoEm === "Percentual"}
                  onChange={handleRadioAplicarDescontoEm}
                  color="primary"
                  value="Percentual"
                  name="tipo-tabela-por-produto"
                />
              </Grid>
              <Grid className={"aplicarDescontoEmTypography"} item>
                <Typography>Percentual</Typography>
              </Grid>
              <Grid className={"descontoTabelaInput"} item xs={4}>
                {tabelaPreco?.aplicarDescontoEm === "Valor" ? (
                  <CurrencyInput
                    id="descontoTabela"
                    name="descontoTabela"
                    label="Desconto Tabela"
                    required
                    value={
                      tabelaPreco?.descontoTabela
                        ? tabelaPreco.descontoTabela
                        : ""
                    }
                    onBlur={(e) => {
                      setDescontoInputError(false);
                      handleInputBlur(e);
                    }}
                    error={descontoInputError}
                  />
                ) : (
                  <TextFielDecimalNumbers
                    id="descontoTabela"
                    name="descontoTabela"
                    label="Desconto Tabela"
                    required
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    value={
                      tabelaPreco?.descontoTabela
                        ? tabelaPreco.descontoTabela
                        : ""
                    }
                    onBlur={(e) => {
                      setDescontoInputError(false);
                      handleInputBlur(e);
                    }}
                    error={descontoInputError}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleAplicarFiltro}
            >
              <i
                className="ph-fill ph-plus-circle"
                style={{ color: "#fff", fontSize: 22, marginRight: 10 }}
              ></i>
              Adicionar produtos
            </Button>
          </CardActions>
          <CardContent className="py-0">
            <Divider />
            <Grid>
              <Grid item xs={12}>
                <DataGrid
                  rows={listaTabelaPrecoProdutos}
                  columns={colunas}
                  editShow
                  deleteShow
                  rowCount={totalListaProdutos}
                  onClickEdit={openEditDescontoDialog}
                  onClickDelete={onClickDeleteProduto}
                />
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openFilterDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <PesquisaAvancada
            handleCloseDialog={handleCloseDialog}
            setProdutos={setProdutos}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        PaperComponent="div"
        open={openDescontoEditDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Card className={"card"}>
            <CardHeader
              title={
                tabelaPreco?.tabelaPrecoProdutos.find(
                  (produto) => produto?.id === produtoIdDesconto
                )?.nome
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid className={"aplicarDescontoEmTypography"} item>
                  <Typography>Aplicar desconto em:</Typography>
                </Grid>
                <Grid item>
                  <Radio
                    className={"aplicarDescontoEmRadioButton"}
                    checked={tabelaPreco?.aplicarDescontoEm === "Valor"}
                    onChange={handleRadioAplicarDescontoEm}
                    color="primary"
                    value="Valor"
                    name="tipo-tabela-por-produto"
                  />
                </Grid>
                <Grid className={"aplicarDescontoEmTypography"} item>
                  <Typography>Valor</Typography>
                </Grid>
                <Grid item>
                  <Radio
                    className={"aplicarDescontoEmRadioButton"}
                    checked={tabelaPreco?.aplicarDescontoEm === "Percentual"}
                    onChange={handleRadioAplicarDescontoEm}
                    color="primary"
                    value="Percentual"
                    name="tipo-tabela-por-produto"
                  />
                </Grid>
                <Grid className={"aplicarDescontoEmTypography"} item>
                  <Typography>Percentual</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {tabelaPreco?.aplicarDescontoEm === "Valor" ? (
                    <CurrencyInput
                      id="descontoTabelaValue"
                      name="descontoTabela"
                      label="Desconto Tabela"
                      value={tabelaPreco?.descontoTabela || ""}
                      onBlur={handleInputBlur}
                    />
                  ) : (
                    <TextFielDecimalNumbers
                      id="descontoTabelaPercentage"
                      name="descontoTabela"
                      label="Desconto Tabela"
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      value={tabelaPreco?.descontoTabela || ""}
                      onBlur={handleInputBlur}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={onClickUpdateDescontoProduto}
              >
                <i
                  className="ph-fill ph-check-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Aplicar desconto
              </Button>
              <Button
                variant="contained"
                color="default"
                onClick={closeEditDescontoDialog}
              >
                <i
                  className="ph-fill ph-x-circle"
                  style={{ fontSize: 22, marginRight: 10 }}
                ></i>
                Fechar
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </AddLayout>
  );
};

export default AddTabelaPreco;
