import React, { useState, useEffect, useRef } from "react";
import {
  CardContent,
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Autocomplete,
  DatePicker,
  TabPanel,
  FullscreenDialog,
  Button,
} from "../../../components";
import { useTheme } from "@mui/material/styles";
import { AddLayout } from "../../../layouts";
import {
  notification,
  NfSaidaService,
  OperacaoFiscalService,
  EnderecoService,
  PessoaService,
  CondicoesPagamentoService,
  MeioPagamentoService,
  ProdutosService,
  TabelaPrecoService,
  ConfiguracoesService,
  SessionService,
  OrigemMercadoriaService,
  SituacaoPisService,
  SituacaoTributariaIpiService,
  history,
  ContasReceberService,
  ColaboradorService,
} from "../../../services";
import { InputErros, PermisoesHelper, value } from "../../../helpers";
import {
  responseErros,
  formatDate,
  unformatPrice,
  voltar,
  userLogs,
} from "../../../utils";
import { nfSaidaValidator, enderecoValidator } from "./middlewares";
import {
  NfSaidaItens,
  NfSaidaPagamentos,
  NfSaidaDadosClientes,
  NfSaidaInformacoes,
  NfSaidaTributos,
  ButtonMenu,
  ResumoTotal,
  NfSaidaTransporte,
  CancelamentoDialog,
  ErrosDfeDialog,
  IdentificarClienteDialog,
  CartaCorrecaoDialog,
} from "./components";
import { useImportContext } from "../../../contexts/import.context";
import AddPessoas from "../../pessoas/addPessoa/addPessoa.view";
import shortid from "shortid";
import { NfSaida, nfSaidaTotalTributos } from "./entities";
import {
  tabProps,
  totalVenda,
  totalItensLista,
  totalPagamentos,
  // verificarNotasEmitidasHoje,
  verificaBaixaAutomatica,
  mapearEndereco,
  encontrarEnderecoPadrao,
  mapearEnderecos,
  compararPagamentosTotalNfSaida,
  calculaSubtotalParaChave,
  formatProdutosTotalizadoresDto,
  calculoDesconto,
} from "./add-nf-saida";

const configuracoesService = new ConfiguracoesService();
const contasReceberService = new ContasReceberService();
const sessionService = new SessionService();
const nfSaidaService = new NfSaidaService();
const operacaoFiscalService = new OperacaoFiscalService();
const pessoaService = new PessoaService();
const colaboradorService = new ColaboradorService();
const produtosService = new ProdutosService();
const enderecoService = new EnderecoService();
const condicoesPagamentoService = new CondicoesPagamentoService();
const meioPagamentoService = new MeioPagamentoService();
const tabelaPrecoService = new TabelaPrecoService();
const origemMercadoriaService = new OrigemMercadoriaService();
const situacaoPisService = new SituacaoPisService();
const situacaoIpiService = new SituacaoTributariaIpiService();
const permisoesHelper = new PermisoesHelper();

const AddNfSaida = ({ match }) => {
  const theme = useTheme();
  const inputCliente = useRef();
  const { nfSaidaDto, setNfSaidaDto, initialCollapses } = useImportContext();
  const { id } = match.params;
  const dadosEmpresa = sessionService.getEmpresa();
  const dadosUsuario = sessionService.getUsuario();
  const [loading, setLoading] = useState(true);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);
  const [userLog, setUserLog] = useState({});
  const [isCadastroPessoaDialogOpen, setIsCadastroPessoaDialogOpen] =
    useState(false);
  const [identificarClienteDialogOpen, setIdentificarClienteDialogOpen] =
    useState(false);
  const [clienteSemCadastro, setClienteSemCadastro] = useState(false);
  const [errosDfeDialogOpen, setErrosDfeDialogOpen] = useState(false);
  const [errosDfe, setErrosDfe] = useState(null);
  // const [indicadorEdicaoData, setIndicadorEdicaoData] = useState(false);
  // const [nfSaidaEmitidasHoje, setNfSaidaEmitidasHoje] = useState(null);
  const [abaValue, setAbaValue] = useState(0);
  const [nfSaida, setNfSaida] = useState({
    dataEmissao: formatDate.toSend(new Date()),
    modalidadeFrete: "mfSemFrete",
    freteCompoeNota: false,
  });
  const prevStateRef = useRef(nfSaida);
  const [contadorModificacao, setContadorModificacao] = useState(0);
  const [operacaoFiscalList, setOperacaoFiscalList] = useState([]);
  const [operacaoFiscal, setOperacaoFiscal] = useState({});
  const [clienteList, setClienteList] = useState([]);
  const [vendedorList, setVendedorList] = useState([]);
  const [transportadoraList, setTransportadoraList] = useState([]);
  const [cidadeId, setCidadeId] = useState(null);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [transportadoraSelecionada, setTransportadoraSelecionada] =
    useState(null);
  const [tabelaPrecoCliente, setTabelaPrecoCliente] = useState([]);
  const [endereco, setEndereco] = useState({
    padrao: false,
  });
  const [enderecoCliente, setEnderecoCliente] = useState(null);
  const [enderecoClienteDataGrid, setEnderecoClienteDataGrid] = useState([]);
  const [enderecoAleterado, setEnderecoAleterado] = useState(null);
  const [condicaoPagamentoList, setCondicaoPagamentoList] = useState([]);
  const [meioPagamentoList, setMeioPagamentoList] = useState([]);
  const [meioPagamentoSelecionado, setMeioPagamentoSelecionado] = useState({});
  const [produtosSimilares, setProdutosSimilares] = useState([]);
  const [frete, setFrete] = useState(null);
  const [configuracoes, setConfiguracoes] = useState({});
  const [nfSaidaProdutos, setNfSaidaProdutos] = useState([]);
  const [nfSaidaPagamentos, setNfSaidaPagamentos] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);
  const nfSaidaValidatorService = nfSaidaValidator(
    operacaoFiscal,
    meioPagamentoSelecionado
  );
  const [origensMercadoriasList, setOrigensMercadoriasList] = useState([]);
  const [situacaoPisList, setSituacaoPisList] = useState([]);
  const [situacaoIpiList, setSituacaoIpiList] = useState([]);
  const [dadosImpressao, setDadosImpressao] = useState(null);
  const [listaNotasCredito, setListaNotasCredito] = useState([]);
  const [openCancelamentoDialog, setOpenCancelamentoDialog] = useState(false);
  const [openNotasCreditoDialog, setOpenNotasCreditoDialog] = useState(false);
  const [meioPagamentoCredito, setMeioPagamentoCredito] = useState({});
  const [notasCredito, setNotasCredito] = useState([]);
  const [openCartaCorrecaoDialog, setOpenCartaCorrecaoDialog] = useState(false);
  const [colaboradorVendedor, setColaboradorVendedor] = useState(null);
  const [permissoes, setPermissoes] = useState({});
  const tiposPessoa = [
    {
      tipoId: 1,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 2,
      setLista: (dados) => setClienteList(dados),
    },
    {
      tipoId: 3,
      setLista: (dados) => setTransportadoraList(dados),
    },
    {
      tipoId: 4,
      setLista: (dados) => setVendedorList(dados),
    },
  ];

  const buscarConfiguracao = async () => {
    const result = await configuracoesService.get();
    if (!result.isAxiosError) {
      if (!result.data.count) return false;
      const data = result.data.rows[0];
      setConfiguracoes(data);
      if (data.operacaoFiscalVendasPadraoId) {
        buscaDadosOperacoesFiscais(data.operacaoFiscalVendasPadraoId);
        return true;
      }
    } else responseErros(result);
  };

  async function buscaDadosIniciais() {
    await buscarPermisoes();
    await buscarNfSaida();
    buscaDadosNfSaidaDto();
    buscarCondicoesPagamento();
    buscarMeiosPagamento();
    buscarVendedores();
    buscaSituacaoIpi();
    buscarOrigensMercadoria();
    buscaSituacaoPis();
    let operacaoPadrao = null;
    if (!id && !nfSaidaDto.documentoImportado) {
      operacaoPadrao = await buscarConfiguracao();
      await buscarPessoaVendedor();
    }
    if (!operacaoPadrao) {
      buscaDadosOperacoesFiscais(nfSaidaDto.operacaoFiscalId);
    }
  }

  async function buscarPermisoes() {
    const url = window.location.pathname.split("/");
    const modulo = url[2];
    const permisaoModulo = await permisoesHelper.getPermisoesModulo(modulo);
    setPermissoes(permisaoModulo);
  }

  const buscarPessoaVendedor = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      if (colaboradorVendedorUsuario) {
        const nfSaidaTemp = {
          ...nfSaida,
          vendedorId: colaboradorVendedorUsuario.pessoa.id,
        };
        const { utilizarCaixaColaborador } =
          await permisoesHelper.getPermisoesModulo("caixa");
        if (utilizarCaixaColaborador) {
          nfSaidaTemp.vendedorBancoCaixaId =
            colaboradorVendedorUsuario.bancoCaixaId;
        }
        setColaboradorVendedor(colaboradorVendedorUsuario);
        setNfSaida((prevState) => ({
          ...prevState,
          ...nfSaidaTemp,
        }));
      }
    } else {
      responseErros(result);
    }
  };

  function buscaDadosOperacoesFiscais(idOperacaoFiscalVenda) {
    let filtrosOperacoesFiscais = {
      ativado: true,
      nonPaginated: true,
      tipoOperacao: "SAIDA",
      finalidade: [
        "NAO_SE_APLICA",
        "NFE_NORMAL",
        "NFE_COMPLEMENTAR",
        "NFE_AJUSTE",
      ],
      restritiva: true,
      porIntervalo: [],
    };
    if (nfSaidaDto.chaveAcessoReferencia) {
      filtrosOperacoesFiscais.finalidade = nfSaidaDto.finalidade;
    }
    if (nfSaidaDto.documentoDevolucaoCliente) {
      filtrosOperacoesFiscais.tipoOperacao = "ENTRADA";
      filtrosOperacoesFiscais.finalidade = "DEVOLUCAO";
    }
    if (nfSaidaDto.documentoDevolucaoFornecedor) {
      filtrosOperacoesFiscais.finalidade = "DEVOLUCAO";
    }
    if (nfSaidaDto.operacaoEntrada) {
      filtrosOperacoesFiscais.tipoOperacao = "ENTRADA";
      delete filtrosOperacoesFiscais.finalidade;
    }
    buscarOperacoesFiscais(filtrosOperacoesFiscais, idOperacaoFiscalVenda);
  }

  function buscaDadosNfSaidaDto() {
    const documentoImportado = [
      "nfSaidaImportadaId",
      "orcamentoIds",
      "pedidoIds",
      "devolucoesFornecedorIds",
      "devolucoesClienteIds",
    ].some((key) => nfSaidaDto?.[key]);
    if (!id && documentoImportado) {
      setNfSaida(montaObjetoNfSaidaDto(nfSaidaDto, documentoImportado));
      buscarDadosClientes(nfSaidaDto?.clienteId);
      buscarNotasCredito(nfSaidaDto?.clienteId);
      buscaEnderecos(nfSaidaDto?.clienteId);
      setFrete(nfSaidaDto?.frete);
      buscarDadosProdutos(nfSaidaDto?.nfSaidaItens);
      setOperacaoFiscal(nfSaidaDto?.operacaoFiscal);
      if (nfSaidaDto?.nfSaidaPagamentos?.length) {
        const pagamentos = nfSaidaDto.nfSaidaPagamentos.map((obj) => {
          return {
            ...obj,
            id: shortid.generate(),
          };
        });
        setNfSaidaPagamentos(pagamentos);
      }
    }
  }

  const buscarDadosProdutos = (itens) => {
    const listaIds = itens.map(({ produtoId }) => produtoId);
    produtosService.getAll({ id: listaIds, nonPaginated: true }).then((res) => {
      if (!res.isAxiosError) {
        const produtosMap = new Map(
          res.data.map((produto) => [produto.id, produto])
        );
        const itensTemp = itens.map((item) => ({
          ...item,
          ...produtosMap.get(item.produtoId),
          id: shortid.generate(),
          aliquotaMva:
            item.aliquotaMva ?? produtosMap.get(item.produtoId)?.aliquotaMva,
        }));
        setNfSaidaProdutos(itensTemp);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarOrigensMercadoria = () => {
    origemMercadoriaService.getAll().then((res) => {
      if (!res.isAxiosError) {
        setOrigensMercadoriasList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscaSituacaoPis = () => {
    situacaoPisService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setSituacaoPisList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscaSituacaoIpi = () => {
    situacaoIpiService.getAll().then((result) => {
      if (!result.isAxiosError) {
        setSituacaoIpiList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  useEffect(() => {
    buscaDadosIniciais().then(() => {
      setLoading(false);
    });
  }, []);

  const buscarNfSaida = async () => {
    if (!id) return;
    const response = await nfSaidaService.getById(id);
    if (!response.isAxiosError) {
      if (!response.data) {
        initialCollapses();
        voltar();
        return;
      }
      setNfSaida({
        ...response.data,
        baseIcmsTotal: nfSaidaTotalTributos.totalBaseIcms(
          id,
          response.data?.nfSaidaItens
        ),
        baseIcmsSt: nfSaidaTotalTributos.totalBaseIcmsSt(
          id,
          response.data?.nfSaidaItens
        ),
        valorIcmsSt: nfSaidaTotalTributos.totalValorIcmsSt(
          id,
          response.data?.nfSaidaItens
        ),
        valorIcmsTotal: nfSaidaTotalTributos.totalValorIcms(
          id,
          response.data?.nfSaidaItens
        ),
        valorIpiTotal: nfSaidaTotalTributos.totalValorIpi(
          id,
          response.data?.nfSaidaItens
        ),
      });
      const operacaoFiscal = await getOperacaoFiscal(
        response.data?.operacaoFiscalId
      );
      setOperacaoFiscal(operacaoFiscal);
      setFrete(response.data?.frete);
      setNfSaidaProdutos(response.data?.nfSaidaItens);
      setNfSaidaPagamentos(response.data?.nfSaidaPagamentos);
      setEnderecoAleterado(response.data.nfSaidaEnderecoCliente);
      userLogs(response.data).then((logs) => {
        setUserLog(logs);
      });
      if (response.data?.clienteId) {
        buscarDadosClientes(response.data?.clienteId);
        buscaEnderecos(response.data?.clienteId);
      } else if (response.data?.clienteNome || response.data?.clienteCnpjCpf) {
        setClienteSelecionado({
          nomeRazaoSocial: response.data?.clienteNome ?? null,
          cnpjCpf: response.data?.clienteCnpjCpf ?? null,
        });
        setClienteSemCadastro(true);
      }
      if (response.data?.transportadoraId) {
        buscarDadosTransportadora(response.data?.transportadoraId);
      } else if (
        response.data?.transportadoraNome ||
        response.data?.transportadoraCnpjCpf
      ) {
        setTransportadoraSelecionada({
          nomeRazaoSocial: response.data?.transportadoraNome ?? null,
          cnpjCpf: response.data?.transportadoraCnpjCpf ?? null,
        });
      }
      if (
        response.data?.status === "ENVIADA" ||
        response.data?.status === "CANCELADA"
      ) {
        await buscarNfSaidaDadosXml();
      }
    } else {
      responseErros(response);
    }
  };

  const buscarPessoas = async (filtros, tiposPessoaId) => {
    const filtro = {
      ...filtros,
      tiposPessoaId,
      nonPaginated: true,
      ativado: true,
    };
    const tipoPessoaTemp = tiposPessoa.find(
      ({ tipoId }) => tipoId === tiposPessoaId
    );
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoComplete(false);
      if (!res.isAxiosError) {
        tipoPessoaTemp?.setLista(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarVendedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 4,
      vendedor: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setVendedorList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarTabelasPrecoCliente = async (tabelaPrecoId) => {
    if (!tabelaPrecoId) return;
    tabelaPrecoService.getById(tabelaPrecoId).then((result) => {
      if (!result.isAxiosError) {
        setTabelaPrecoCliente(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarOperacoesFiscais = (filtros, id) => {
    operacaoFiscalService.getAllFiltroAvancado(filtros).then((result) => {
      if (!result.isAxiosError) {
        setOperacaoFiscalList(result.data);
        if (id && result.data?.length) {
          const operacaoPadrao = result.data.find(
            (operacao) => operacao.id === id
          );
          if (operacaoPadrao) {
            setOperacaoFiscal(operacaoPadrao);
            setNfSaida((prevState) => ({
              ...prevState,
              operacaoFiscalId: operacaoPadrao.id,
              serieDocumento: operacaoPadrao?.serieDocumento,
              modeloDocumento: operacaoPadrao?.modeloDocumento,
            }));
          }
        }
      } else {
        responseErros(result);
      }
    });
  };

  const getOperacaoFiscal = async (id) => {
    if (!id) return;
    return operacaoFiscalService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        return result.data;
      } else {
        responseErros(result);
        return null;
      }
    });
  };

  const buscarCondicoesPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    condicoesPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setCondicaoPagamentoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarMeiosPagamento = () => {
    const filtro = {
      nonPaginated: true,
      ativado: true,
    };
    if (
      nfSaidaDto.documentoDevolucaoCliente ||
      nfSaidaDto.documentoDevolucaoFornecedor
    ) {
      filtro.nfePagamento = "fpCreditoLoja";
    }
    meioPagamentoService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamentoList(result.data);
        setMeioPagamentoCredito(
          result.data.find((meio) => meio.nfePagamento == "fpCreditoLoja")
        );
      } else {
        responseErros(result);
      }
    });
  };

  const buscarProdutosSimilares = async (produtoId) => {
    if (produtoId) {
      produtosService.getById(produtoId).then((result) => {
        if (!result.isAxiosError) {
          setProdutosSimilares(result.data.similares);
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarDadosClientes = async (clienteId) => {
    if (clienteId) {
      pessoaService.getById(clienteId).then((result) => {
        if (!result.isAxiosError) {
          setClienteSelecionado(result.data);
        } else {
          responseErros(result);
        }
      });
    } else {
      setClienteSelecionado(null);
    }
  };

  const buscarDadosTransportadora = async (transportadoraId) => {
    if (transportadoraId) {
      pessoaService.getById(transportadoraId).then((result) => {
        if (!result.isAxiosError) {
          setTransportadoraSelecionada(result.data);
        } else {
          responseErros(result);
        }
      });
    } else {
      setTransportadoraSelecionada(null);
    }
  };

  // const buscarNfSaidaEmitidasHoje = () => {
  //   const filtro = {
  //     porIntervalo: [
  //       {
  //         coluna: "dataEmissao",
  //         de: new Date(),
  //         ate: new Date(),
  //       },
  //     ],
  //   };
  //   nfSaidaService.getAllFiltroAvancado(filtro).then((result) => {
  //     if (!result.isAxiosError) {
  //       setNfSaidaEmitidasHoje(result.data.rows);
  //     } else {
  //       responseErros(result);
  //     }
  //   });
  // };

  const buscaEnderecos = async (clienteId) => {
    if (!clienteId) return;
    const enderecos = await pessoaService
      .getEnderecos(clienteId)
      .then((result) => {
        if (result.isAxiosError) {
          responseErros(result);
          return [];
        }
        return result.data;
      });
    const enderecoPadrao = encontrarEnderecoPadrao(enderecos);
    let estados = [];
    const estadosRes = await enderecoService.getEstados();
    if (!estadosRes.isAxiosError) {
      estados = estadosRes.data;
    }
    if (enderecoPadrao) {
      const enderecoCliente = await mapearEndereco(estados, enderecoPadrao);
      setEnderecoCliente(enderecoCliente);
    }
    const enderecosMapeados = await mapearEnderecos(estados, enderecos);
    setEnderecoClienteDataGrid(enderecosMapeados);
  };

  const handleInputChange = (e) => {
    if (e.target.name === "acrescimo" || e.target.name === "despesas") {
      setNfSaida((prevState) => ({
        ...prevState,
        [e.target.name]: unformatPrice(e.target.value),
      }));
    } else {
      setNfSaida((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const onSearchChangePessoas = (event, inputRef, tiposPessoaId) => {
    if (event.key === "Enter" && inputRef.current === document.activeElement) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      if (tiposPessoaId === 4) {
        filtros = { ...filtros, vendedor: "true" };
      }
      setLoadingAutoComplete(true);
      buscarPessoas(filtros, tiposPessoaId);
    }
  };

  const handleDateChange = (fieldName, date) => {
    if (!date) {
      date = "Invalid Date";
    }
    setNfSaida((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : undefined,
    }));
  };

  const montaObjetoNfSaida = (itens, pagamentos, frete) => {
    const subtotalProdutos = totalItensLista(itens);
    const desconto = calculaSubtotalParaChave(itens, "", "desconto");
    const acrescimo = calculaSubtotalParaChave(itens, "", "acrescimo");
    const despesas = calculaSubtotalParaChave(itens, "", "despesas");
    const subTotalPagamentos = totalPagamentos(pagamentos);
    let nfSaidaTemp = {
      ...nfSaida,
      ...nfSaidaDto,
      nfSaidaItens: nfSaidaProdutos,
      nfSaidaPagamentos,
      frete,
      desconto,
      acrescimo,
      despesas,
      baseIcmsTotal: nfSaidaTotalTributos.totalBaseIcms(
        id,
        nfSaidaProdutos,
        nfSaidaDto
      ),
      valorIcmsTotal: nfSaidaTotalTributos.totalValorIcms(
        id,
        nfSaidaProdutos,
        nfSaidaDto
      ),
      baseIcmsSt: nfSaidaTotalTributos.totalBaseIcmsSt(
        id,
        nfSaidaProdutos,
        nfSaidaDto
      ),
      valorIcmsSt: nfSaidaTotalTributos.totalValorIcmsSt(
        id,
        nfSaidaProdutos,
        nfSaidaDto
      ),
      valorIpiTotal: nfSaidaTotalTributos.totalValorIpi(
        id,
        nfSaidaProdutos,
        nfSaidaDto
      ),
      totalMercadoria: subtotalProdutos,
      totalVenda: totalVenda(nfSaida, nfSaidaProdutos),
      totalPagamentos: subTotalPagamentos,
    };
    const descontoPorcentagem = calculoDesconto(itens, nfSaidaTemp, false);
    nfSaidaTemp.descontoPorcentagem = descontoPorcentagem;
    setNfSaida(nfSaidaTemp);
    return nfSaidaTemp;
  };

  const montaObjetoNfSaidaDto = (nfSaidaDto, documentoImportado) => {
    const itens = formatProdutosTotalizadoresDto(
      nfSaidaDto.nfSaidaItens,
      totalVenda(nfSaidaDto, nfSaidaDto.nfSaidaItens),
      nfSaidaDto
    );
    const descontoPorcentagem = calculoDesconto(itens, nfSaidaDto, false);
    return {
      ...nfSaidaDto,
      descontoPorcentagem,
      documentoImportado,
      dataEmissao: formatDate.toSend(new Date()),
      modalidadeFrete: "mfSemFrete",
      nfSaidaItens: itens,
      totalMercadoria: totalItensLista(itens),
      totalVenda: totalVenda(nfSaidaDto, itens),
      totalPagamentos: totalPagamentos(nfSaidaPagamentos),
    };
  };

  useEffect(() => {
    montaObjetoNfSaida(nfSaidaProdutos, nfSaidaPagamentos, frete);
  }, [nfSaidaProdutos, nfSaidaPagamentos, frete]);

  useEffect(() => {
    if (!prevStateRef?.current || contadorModificacao <= (id ? 3 : 2)) {
      setContadorModificacao((prevCount) => prevCount + 1);
      prevStateRef.current = nfSaida;
    }
  }, [nfSaida]);

  const handleCloseCadastroCliente = () => {
    buscarPessoas();
    setNfSaida((prevState) => ({
      ...prevState,
      clienteId: null,
    }));
    setIsCadastroPessoaDialogOpen(false);
  };

  const handleDialogErrorDfe = (mensagem, status) => {
    setErrosDfe({ mensagem, status });
    setErrosDfeDialogOpen(true);
  };

  const verificarStatus = (status) => {
    switch (status) {
      case "PENDENTE":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#EA9D2B" }}></i>
        );
      case "ENVIADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#4FBB53" }}></i>
        );
      case "CONTINGENCIA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#BFBFBF" }}></i>
        );
      case "CANCELADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#DC3545" }}></i>
        );
      case "DENEGADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#70309F" }}></i>
        );
      case "EM PROCESSAMENTO":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#A0522D" }}></i>
        );
      case "FATURADA":
        return (
          <i className="ph-fill ph-circle" style={{ color: "#004780" }}></i>
        );
    }
  };

  const salvarEndereco = async () => {
    const body = {
      ...endereco,
      cidadeId: cidadeId,
      pessoaId: nfSaida?.clienteId,
    };
    enderecoValidator
      .validate(body, { abortEarly: false })
      .then(async () => {
        setLoading(true);
        const res = await pessoaService.cadastrarEndereco(
          nfSaida?.clienteId,
          body
        );
        if (!res.isAxiosError) {
          notification.cadastroSucesso();
          buscaEnderecos();
          setEnderecoClienteDataGrid([...enderecoClienteDataGrid, body]);
        } else {
          responseErros(res);
        }
        setLoading(false);
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const inicialFormulario = () => {
    setNfSaida({ freteCompoeNota: false });
    setNfSaidaProdutos([]);
    setNfSaidaPagamentos([]);
  };

  const removerDadosCliente = () => {
    setEnderecoClienteDataGrid([]);
    setEnderecoCliente(null);
    setEnderecoAleterado(null);
  };

  const onChangeAutocomplete = (name, value) => {
    const nfSaidaTemp = {
      ...nfSaida,
      [name]: value ? value.id : null,
    };
    if (name === "clienteId") {
      nfSaidaTemp.clienteEmail = value?.email;
      nfSaidaTemp.clienteCnpjCpf = value?.cnpjCpf;
      nfSaidaTemp.clienteNome = value?.nomeRazaoSocial;
    }
    if (name === "transportadoraId") {
      nfSaidaTemp.transportadoraNome = value?.nomeRazaoSocial;
      nfSaidaTemp.transportadoraCnpjCpf = value?.cnpjCpf;
      nfSaidaTemp.transportadoraIe = value?.inscricaoEstadual;
      nfSaidaTemp.transportadoraEnderecoRua = value?.enderecos[0]?.logradouro;
      nfSaidaTemp.transportadoraEnderecoMunicipio =
        value?.enderecos[0]?.cidade?.nome;
      nfSaidaTemp.transportadoraEnderecoUf =
        value?.enderecos[0]?.cidade?.estado.sigla;
    }
    setNfSaida(nfSaidaTemp);
  };

  const onClickCancelarNfSaida = async () => {
    setOpenCancelamentoDialog(true);
  };

  const onClickValidarNfSaida = async () => {
    if (id) {
      setLoading(true);
      const response = await nfSaidaService.validarNotaFiscal(id);
      setLoading(false);
      if (!response.isAxiosError) {
        notification.sucessoGenericos("Nota validada com sucesso");
      } else {
        const res = response.response;
        if (res.status === 500 && res.data?.message) {
          handleDialogErrorDfe(res.data.message, 500);
          return;
        }
        if (res.status === 400 && res.data?.message) {
          handleDialogErrorDfe(res.data.message, 400);
          return;
        }
        notification.alertaGenericos(response.mensagem);
      }
    }
  };

  const onChangeAutocompleteCliente = (newValue) => {
    setInputErro([]);
    setClienteSemCadastro(false);
    removerDadosCliente();
    onChangeAutocomplete("clienteId", newValue);
    setClienteSelecionado(newValue);
    buscarNotasCredito(newValue?.id);
    buscarDadosClientes(newValue?.id);
    buscarTabelasPrecoCliente(newValue?.clientes[0]?.tabelaPrecoPadraoId);
    buscaEnderecos(newValue?.id);
  };

  const onChangeAutocompleteOperacaoFiscal = (newValue) => {
    setInputErro([]);
    onChangeAutocomplete("operacaoFiscalId", newValue);
    setOperacaoFiscal(newValue);
    setNfSaida((prevState) => ({
      ...prevState,
      serieDocumento: newValue?.serieDocumento,
      modeloDocumento: newValue?.modeloDocumento,
    }));
    // const temNotasEmitidasHoje = verificarNotasEmitidasHoje(
    //   notasEmitidasHoje || [],
    //   newValue?.id
    // );
    // setIndicadorEdicaoData(temNotasEmitidasHoje);
    // if (temNotasEmitidasHoje) {
    //   setNfSaida((prevState) => ({
    //     ...prevState,
    //     dataEmissao: formatDate.toSend(new Date()),
    //   }));
    // }
  };

  const buscarNotasCredito = (pagadorId) => {
    const filtro = {
      pagadorId,
      tipo: "NOTA_CREDITO",
      status: ["ABERTO", "BAIXADO_PARCIAL"],
      restritiva: true,
    };
    contasReceberService.getAllFiltroAvancado(filtro).then((result) => {
      if (!result.isAxiosError) {
        setListaNotasCredito(result.data.rows);
      } else responseErros(result);
    });
  };

  async function alertaNotasCredito() {
    const totalNf =
      (nfSaida.totalVenda || 0) - (nfSaida.totalPagamentos || 0) > 0;
    if (
      listaNotasCredito.length &&
      !nfSaidaDto?.documentoDevolucaoCliente &&
      !nfSaidaDto?.documentoDevolucaoFornecedor &&
      totalNf
    ) {
      const confirmacaoAlerta = await notification.confirmacaoGenericos({
        title: "Cliente com crédito",
        text: `Deseja utilizar nesta compra?`,
        icon: "warning",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO",
        showCancelButton: true,
      });
      if (confirmacaoAlerta.isConfirmed) {
        setOpenNotasCreditoDialog(true);
      }
    }
  }

  const buscarNfSaidaDadosXml = async (idCadastrado) => {
    setLoading(true);
    const result = await nfSaidaService.getDadosXml(id || idCadastrado);
    if (!result.isAxiosError) {
      setDadosImpressao(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCartaCorrecao = () => {
    setOpenCartaCorrecaoDialog(true);
  };

  const criarObjetoNfSaida = async () => {
    const nfSaidaTemp = {
      ...nfSaida,
      operacaoFiscal,
      vendedorObrigatorio: permissoes?.vendedorObrigatorio,
    };
    if (nfSaidaTemp?.nfSaidaItens.length) {
      nfSaidaTemp.grupoTributarios = [
        ...new Set(nfSaida.nfSaidaItens.map((item) => item.grupoTributacaoId)),
      ];
    }
    const endereco =
      enderecoAleterado && nfSaida.clienteId
        ? enderecoAleterado
        : enderecoCliente;
    if (endereco && nfSaida.clienteId) {
      nfSaidaTemp.nfSaidaEnderecoCliente = {
        ...endereco,
        pessoaId: nfSaida.clienteId,
      };
    }
    return nfSaidaTemp;
  };

  const handleSubmit = async (indiceSelecionado) => {
    setLoading(true);
    const nfSaidaCadastrar = await criarObjetoNfSaida();
    const nfSaidaEntity = new NfSaida(nfSaidaCadastrar);
    nfSaidaValidatorService
      .validate(nfSaidaEntity, { abortEarly: false })
      .then(async () => {
        const isValid = validarNfSaida(nfSaidaEntity);
        if (!isValid) return;
        if (id) {
          const response = await nfSaidaService.atualizar(id, nfSaidaEntity);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.alteracaoSucesso();
            initialCollapses();
            voltar();
          } else {
            responseErros(response);
          }
        } else {
          const response = await nfSaidaService.cadastrar(nfSaidaEntity);
          setLoading(false);
          if (!response.isAxiosError) {
            notification.cadastroSucesso();
            if (indiceSelecionado === 0) {
              const nf = { ...nfSaida, ...response.data };
              setNfSaida(nf);
              emitirAposCadastro(nf);
            } else {
              inicialFormulario();
            }
          } else responseErros(response);
        }
        setNfSaidaDto({
          dataEmissao: formatDate.toSend(new Date()),
          nfSaidaItens: [],
          nfSaidaPagamentos: [],
          documentoImportado: false,
        });
      })
      .catch(async (err) => {
        setLoading(false);
        if (err.inner[0].path == "nfSaidaItens") setAbaValue(0);
        if (err.inner[0].path == "nfSaidaPagamentos") {
          await alertaNotasCredito();
          setAbaValue(1);
        }
        inputErros.set(err);
      });
  };

  const validarNfSaida = (nfSaidaDto) => {
    if (
      operacaoFiscal?.modeloDocumento === "55" &&
      operacaoFiscal?.finalidade !== "DEVOLUCAO" &&
      !nfSaidaDto?.nfSaidaEnderecoCliente?.id
    ) {
      notification.alertaGenericos(
        "É necessario cadastro de endereço para salvar"
      );
      setLoading(false);
      return false;
    }
    if (
      !verificaBaixaAutomatica(nfSaida, meioPagamentoList) &&
      !nfSaida.clienteId
    ) {
      notification.alertaGenericos(
        "É necessário informar cliente para vendas a prazo"
      );
      setLoading(false);
      return false;
    }
    const pagamentosTotalNfSaida = compararPagamentosTotalNfSaida(nfSaida);
    if (pagamentosTotalNfSaida) {
      notification.alertaGenericos(
        "É necessário atualizar valor da(s) parcela(s)"
      );
      setLoading(false);
      setAbaValue(1);
      return false;
    }
    if (
      Number(nfSaidaDto?.totalVenda) > 500 &&
      !nfSaidaDto?.clienteCnpjCpf &&
      operacaoFiscal?.modeloDocumento !== "01"
    ) {
      setLoading(false);
      setIdentificarClienteDialogOpen(true);
      notification.alertaGenericos(
        "Vendas de valor superior a R$ 500 devem identificar cliente."
      );
      return false;
    }
    return true;
  };

  const emitirAposCadastro = async (data) => {
    if (data.modeloDocumento == "01") {
      return await imprimeModeloZero(data);
    }
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Documento cadastrado com sucesso",
      text: `#${data.numeroDocumento}`,
      icon: "success",
      confirmButtonText: "EMITIR",
      cancelButtonText: "FECHAR",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      const notaEmitida = await emitirNfSaida(data.id);
      if (notaEmitida && configuracoes?.visualizarImpressaoEmissao) {
        await buscarNfSaidaDadosXml(data.id);
      } else {
        history.push(`/faturamento/nf-saida/${data.id}`);
      }
    } else {
      initialCollapses();
      //voltar();
    }
  };

  const imprimeModeloZero = async (data) => {
    if (!configuracoes?.visualizarImpressaoEmissao) {
      initialCollapses();
      voltar();
      return;
    }
    const confirmacaoAlerta = await notification.confirmacaoGenericos({
      title: "Documento cadastrado com sucesso",
      text: `#${data.numeroDocumento}`,
      icon: "success",
      confirmButtonText: "IMPRIMIR",
      cancelButtonText: "FECHAR",
      showCancelButton: true,
    });
    if (confirmacaoAlerta.isConfirmed) {
      history.push(`/faturamento/nf-saida/${data.id}`);
      setTimeout(() => {
        document.getElementById("botaoMenuAction")?.click();
      }, 2600);
    } else {
      initialCollapses();
      voltar();
    }
  };

  useEffect(() => {
    if (dadosImpressao && !id) {
      history.push(`/faturamento/nf-saida/${nfSaida.id}`);
      setTimeout(() => {
        document.getElementById("impressoraAdd")?.click();
      }, 2555);
    }
  }, [dadosImpressao]);

  const emitirNfSaidaContigencia = async () => {
    setLoading(true);
    const response = await nfSaidaService.enviarNotaFiscalContigencia(id);
    setLoading(false);
    return alertaRetornoEmitirNota(response);
  };

  const emitirNfSaida = async (idNfSaida) => {
    setLoading(true);
    const response = await nfSaidaService.emitirNotaFiscal(idNfSaida);
    setLoading(false);
    return alertaRetornoEmitirNota(response);
  };

  function alertaRetornoEmitirNota(response) {
    if (!response.isAxiosError) {
      notification.sucessoGenericos("Nota Emitida com sucesso");
      return true;
    }
    const res = response.response;
    if (res.status === 500 && res.data?.message) {
      handleDialogErrorDfe(res.data.message, 500);
    } else if (res.status === 400 && res.data?.message) {
      handleDialogErrorDfe(res.data.message, 400);
    } else {
      notification.alertaGenericos(response.mensagem);
    }
    return false;
  }

  const onClickEmitirNfSaidaEdicao = async () => {
    const notaEmitida = await emitirNfSaida(id);
    if (notaEmitida) await buscarNfSaida();
  };

  const onClickEmitirNfSaidaContigenciaEdicao = async () => {
    const notaEmitida = await emitirNfSaidaContigencia();
    if (notaEmitida) await buscarNfSaida();
  };

  const onClickBaixarXml = async () => {
    setLoading(true);
    const result = await nfSaidaService.getArquivoXml(id);
    if (!result.isAxiosError) {
      const blob = new Blob([result.data], { type: "text/xml" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `${nfSaida.chaveAcesso}.xml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  async function onClickConsultarStatus() {
    setLoading(true);
    const result = await nfSaidaService.consultarStatusNotaFiscal(id);
    setLoading(false);
    if (!result.isAxiosError) {
      buscaDadosIniciais();
      notification.alertaGenericos("Status atualizado para Enviada");
    } else {
      alertaRetornoConsultarStatus(result);
    }
  }

  function alertaRetornoConsultarStatus(result) {
    const response = result.response;
    if (response.status == 500) {
      notification.alertaGenericos(response.data.message);
    }
  }

  return (
    <AddLayout
      id={id}
      disableAtualizar={id && nfSaida?.status !== "PENDENTE"}
      title={id ? "Visualizar Nf-Saida" : "Cadastrar Nf-Saida"}
      onClickSalvar={handleSubmit}
      loading={loading}
      codigo={
        id && nfSaida?.numeroDocumento
          ? `Nº Documento ${nfSaida?.numeroDocumento}`
          : ""
      }
      userLog={id ? userLog : null}
      ButtonMenu={
        <ButtonMenu
          id={id}
          nfSaida={{
            ...nfSaida,
            empresa: {
              email: dadosEmpresa.email,
              cnpjCpf: dadosEmpresa.cnpjCpf,
              uf: dadosEmpresa.enderecoUF,
              nome: dadosEmpresa.fantasia ?? dadosEmpresa.razaoSocial,
              telefone: dadosEmpresa.celular ?? dadosEmpresa.telefone,
              emiteNfe: dadosEmpresa.emiteNfe,
              emiteNfce: dadosEmpresa.emiteNfce,
            },
            vendedor: value.autoComplete(vendedorList, nfSaida?.vendedorId),
            cliente: clienteSelecionado,
            enderecoCliente: enderecoAleterado || enderecoCliente,
            estadoCliente: enderecoCliente?.estado,
            pagamentos: nfSaidaPagamentos,
            meioPagamentoList,
          }}
          operacaoFiscal={operacaoFiscal}
          dadosImpressao={dadosImpressao}
          onClickCancelarNfSaida={onClickCancelarNfSaida}
          onClickEmitirNfSaida={onClickEmitirNfSaidaEdicao}
          onClickEmitirNfSaidaContigenciaEdicao={
            onClickEmitirNfSaidaContigenciaEdicao
          }
          onClickValidarNfSaida={onClickValidarNfSaida}
          onClickCartaCorrecao={onClickCartaCorrecao}
          onClickConsultarStatus={onClickConsultarStatus}
          onClickBaixarXml={onClickBaixarXml}
          permissoes={permissoes}
        />
      }
      actionsTotalizadores={
        <ResumoTotal
          nfSaidaId={id}
          nfSaida={nfSaida}
          setNfSaida={setNfSaida}
          totalItensLista={totalItensLista}
          nfSaidaProdutos={nfSaidaProdutos}
          setNfSaidaProdutos={setNfSaidaProdutos}
          abaValue={abaValue}
          permissoes={permissoes}
          setNfSaidaPagamentos={setNfSaidaPagamentos}
        />
      }
      formState={{
        inicial: prevStateRef?.current,
        final: nfSaida,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <DatePicker
            id="dataEmissao"
            name="dataEmissao"
            label="Data Emissão"
            format="dd/MM/yyyy"
            variant="outlined"
            disabled={id}
            // variant={indicadorEdicaoData ? "filled" : "outlined"}
            // disabled={id || indicadorEdicaoData ? true : false}
            required
            value={nfSaida?.dataEmissao ? nfSaida.dataEmissao : null}
            onChange={(date) => {
              setInputErro([]);
              handleDateChange("dataEmissao", date);
            }}
            error={inputErros.get("dataEmissao")}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            required
            id="operacaoFiscalId"
            name="operacaoFiscalId"
            options={operacaoFiscalList}
            noOptionsText="Sem opções"
            autoHighlight
            disableClearable
            getOptionLabel={(option) =>
              option.descricao ? option.descricao : ""
            }
            disabled={id ? true : false}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Operação Fiscal"
                variant={nfSaida.status === "CANCELADA" ? "filled" : "outlined"}
                disabled={id ? true : false}
                error={inputErros.get("operacaoFiscalId")}
                required
              />
            )}
            onChange={(_, newValue) => {
              onChangeAutocompleteOperacaoFiscal(newValue);
            }}
            value={
              operacaoFiscal
                ? operacaoFiscal
                : value.autoComplete(
                    operacaoFiscalList,
                    nfSaida.operacaoFiscalId
                  )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroDocumento"
            name="numeroDocumento"
            type="number"
            label="Nº Documento"
            variant="outlined"
            disabled
            value={nfSaida.numeroDocumento ?? ""}
            InputProps={{ inputProps: { min: 0 } }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="serieDocumento"
            name="serieDocumento"
            label="Série Documento"
            variant="outlined"
            disabled
            value={nfSaida.serieDocumento ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="modeloDocumento"
            name="modeloDocumento"
            label="Modelo Documento"
            variant="outlined"
            disabled
            value={nfSaida.modeloDocumento ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6}>
          <Autocomplete
            id="clienteId"
            name="clienteId"
            disabled={
              (id && nfSaida?.status !== "PENDENTE") || abaValue !== 0
                ? true
                : false
            }
            options={clienteList}
            autoHighlight
            getOptionLabel={(option) => {
              let codigoPessoa = option?.codigo ? `${option.codigo} - ` : "";
              let descricaoPessoa = codigoPessoa + `${option.nomeRazaoSocial}`;
              return option ? descricaoPessoa : "";
            }}
            loading={loadingAutoComplete}
            loadingText="Carregando"
            noOptionsText={
              document.getElementById("clienteId")?.value.length >= 1 && !id ? (
                <Grid container spacing={1}>
                  <Grid item xs={7} className="mt-2" fullWidth>
                    Nenhum cliente encontrado
                  </Grid>
                  <Grid item xs={5} className="d-flex justify-content-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsCadastroPessoaDialogOpen(true)}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                "Digite e pressione Enter"
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !nfSaidaDto?.documentoDevolucaoFornecedor
                    ? "Cliente"
                    : "Fornecedor"
                }
                error={inputErros.get("clienteId")}
                required={
                  operacaoFiscal?.modeloDocumento === "55" ? true : false
                }
                variant={"outlined"}
                onKeyDown={(e) =>
                  onSearchChangePessoas(
                    e,
                    inputCliente,
                    !nfSaidaDto?.documentoDevolucaoFornecedor ? 1 : 2
                  )
                }
                inputRef={inputCliente}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {operacaoFiscal?.modeloDocumento === "65" && !id && (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={id ? true : false}
                            onClick={() =>
                              setIdentificarClienteDialogOpen(true)
                            }
                          >
                            <i
                              className="ph ph-user-plus"
                              style={{ fontSize: 24 }}
                            ></i>
                          </IconButton>
                        </InputAdornment>
                      )}
                      <React.Fragment>
                        {loadingAutoComplete ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, newValue) => onChangeAutocompleteCliente(newValue)}
            value={clienteSelecionado}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="vendedorId"
            name="vendedorId"
            disabled={
              id ||
              !nfSaida?.operacaoFiscalId ||
              colaboradorVendedor ||
              nfSaidaDto.documentoDevolucaoCliente
            }
            options={vendedorList}
            autoHighlight
            getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Vendedor"
                variant={"outlined"}
                required={permissoes?.vendedorObrigatorio}
                error={inputErros.get("vendedorId")}
              />
            )}
            onChange={(_, newValue) => {
              setInputErro([]);
              onChangeAutocomplete("vendedorId", newValue);
            }}
            value={value.autoComplete(vendedorList, nfSaida?.vendedorId)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="status"
            name="status"
            label="Status"
            variant="outlined"
            disabled
            value={nfSaida.status ?? ""}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {verificarStatus(nfSaida.status)}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {(nfSaida.status === "ENVIADA" || nfSaida.status === "CANCELADA") && (
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <TextField
              id="chaveAcesso"
              name="chaveAcesso"
              label="Chave de Acesso"
              variant="outlined"
              disabled
              value={nfSaida.chaveAcesso ?? ""}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="numeroProtoc"
              name="numeroProtoc"
              label="Número Protocolo"
              variant="outlined"
              disabled
              value={nfSaida.numeroProtoc ?? ""}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <hr className="mt-4 mb-2" />
      <Paper>
        <Tabs
          value={abaValue}
          onChange={async (e, value) => {
            e.persist();
            if (value === 1) {
              await alertaNotasCredito();
            }
            setAbaValue(value);
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="ITENS DA NOTA" {...tabProps(0)} />
          <Tab
            label="PAGAMENTOS"
            {...tabProps(1)}
            disabled={nfSaida.chaveAcessoReferencia != null}
          />
          <Tab
            disabled={!clienteSelecionado ? true : false}
            label="DADOS DO CLIENTE"
            {...tabProps(2)}
          />
          <Tab label="TRIBUTOS" {...tabProps(3)} />
          <Tab label="TRANSPORTE" {...tabProps(4)} />
          <Tab label="INFORMAÇÕES ADICIONAIS" {...tabProps(5)} />
        </Tabs>
      </Paper>
      <CardContent>
        <TabPanel value={abaValue} index={0} dir={theme.direction}>
          {abaValue === 0 ? (
            <NfSaidaItens
              nfSaidaId={id}
              nfSaida={nfSaida}
              nfSaidaProdutos={nfSaidaProdutos}
              tabelaPrecoCliente={tabelaPrecoCliente}
              produtosSimilares={produtosSimilares}
              buscarProdutosSimilares={buscarProdutosSimilares}
              setNfSaidaProdutos={setNfSaidaProdutos}
              setNfSaidaPagamentos={setNfSaidaPagamentos}
              setLoading={setLoading}
              clienteSelecionado={clienteSelecionado}
              permitirVendaEstoqueNegativo={configuracoes?.vendaEstoqueNegativo}
              situacaoPisList={situacaoPisList}
              situacaoIpiList={situacaoIpiList}
              origensMercadoriasList={origensMercadoriasList}
              operacaoFiscal={operacaoFiscal}
              permissoes={permissoes}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={1} dir={theme.direction}>
          {abaValue === 1 ? (
            <NfSaidaPagamentos
              nfSaidaId={id}
              nfSaida={nfSaida}
              setNfSaida={setNfSaida}
              nfSaidaPagamentos={nfSaidaPagamentos}
              setNfSaidaPagamentos={setNfSaidaPagamentos}
              meioPagamentoList={meioPagamentoList}
              condicaoPagamentoList={condicaoPagamentoList}
              onChangeAutocomplete={onChangeAutocomplete}
              handleInputChange={handleInputChange}
              meioPagamentoSelecionado={meioPagamentoSelecionado}
              setMeioPagamentoSelecionado={setMeioPagamentoSelecionado}
              listaNotasCredito={listaNotasCredito}
              setListaNotasCredito={setListaNotasCredito}
              setOpenNotasCreditoDialog={setOpenNotasCreditoDialog}
              openNotasCreditoDialog={openNotasCreditoDialog}
              notasCredito={notasCredito}
              setNotasCredito={setNotasCredito}
              meioPagamentoCredito={meioPagamentoCredito}
              buscarNotasCredito={buscarNotasCredito}
              setAbaValue={setAbaValue}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={2} dir={theme.direction}>
          {clienteSelecionado || abaValue === 2 ? (
            <NfSaidaDadosClientes
              nfSaidaId={id}
              nfSaida={nfSaida}
              setNfSaida={setNfSaida}
              enderecoAleterado={enderecoAleterado}
              setEnderecoAleterado={setEnderecoAleterado}
              enderecoCliente={enderecoCliente}
              enderecoClienteDataGrid={enderecoClienteDataGrid}
              buscaEnderecos={buscaEnderecos}
              clienteSelecionado={clienteSelecionado}
              cidadeId={cidadeId}
              setCidadeId={setCidadeId}
              endereco={endereco}
              setEndereco={setEndereco}
              salvarEndereco={salvarEndereco}
              clienteSemCadastro={clienteSemCadastro}
              inputErros={inputErros}
            />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={3} dir={theme.direction}>
          {abaValue === 3 ? (
            <NfSaidaTributos nfSaida={nfSaida} setNfSaida={setNfSaida} />
          ) : null}
        </TabPanel>
        <TabPanel value={abaValue} index={4} dir={theme.direction}>
          <NfSaidaTransporte
            id={id}
            nfSaida={nfSaida}
            setNfSaida={setNfSaida}
            transportadoraList={transportadoraList}
            transportadoraSelecionada={transportadoraSelecionada}
            setTransportadoraSelecionada={setTransportadoraSelecionada}
            frete={frete}
            unformatPrice={unformatPrice}
            setFrete={setFrete}
            inputErros={inputErros}
            setInputErro={setInputErro}
            handleInputChange={handleInputChange}
            onChangeAutocomplete={onChangeAutocomplete}
            onSearchChangePessoas={onSearchChangePessoas}
            buscarDadosTransportadora={buscarDadosTransportadora}
          />
        </TabPanel>
        <TabPanel value={abaValue} index={5} dir={theme.direction}>
          {abaValue === 5 ? (
            <NfSaidaInformacoes
              nfSaidaId={id}
              nfSaida={nfSaida}
              handleInputChange={handleInputChange}
            />
          ) : null}
        </TabPanel>
        <FullscreenDialog
          open={isCadastroPessoaDialogOpen}
          handleClose={handleCloseCadastroCliente}
          content={
            <AddPessoas
              match={{ params: { id: null } }}
              isFullscreenDialog
              handleCloseFullscreenDialog={handleCloseCadastroCliente}
            />
          }
        />
      </CardContent>
      <CancelamentoDialog
        open={openCancelamentoDialog}
        setOpen={setOpenCancelamentoDialog}
        setLoading={setLoading}
        id={id}
        operacaoFiscal={operacaoFiscal}
        nfSaida={nfSaida}
      />
      <ErrosDfeDialog
        open={errosDfeDialogOpen}
        setOpen={setErrosDfeDialogOpen}
        errosDfe={errosDfe}
        handleInputChange={handleInputChange}
      />
      <IdentificarClienteDialog
        open={identificarClienteDialogOpen}
        setOpen={setIdentificarClienteDialogOpen}
        setClienteSelecionado={setClienteSelecionado}
        setNfSaida={setNfSaida}
        setClienteSemCadastro={setClienteSemCadastro}
      />
      <CartaCorrecaoDialog
        openCartaCorrecaoDialog={openCartaCorrecaoDialog}
        setOpenCartaCorrecaoDialog={setOpenCartaCorrecaoDialog}
        id={id}
        setLoading={setLoading}
        nfSaida={nfSaida}
        clienteSelecionado={clienteSelecionado}
        enderecoCliente={enderecoCliente}
      />
    </AddLayout>
  );
};

export default AddNfSaida;
