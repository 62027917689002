import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { Dialog, AcordeonList } from "../../../components";
import {
  GrupoProdutosService,
  notification,
  ProdutosService,
} from "../../../services";
import { cacheApi, responseErros } from "../../../utils";
import AddGrupoProdutos from "../addGrupoProdutos/addGrupoProdutos.view";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListGrupoProdutos = () => {
  const grupoProdutosService = new GrupoProdutosService();
  const produtosService = new ProdutosService();
  const [loading, setLoading] = useState(false);
  const [grupoProdutosId, setGrupoProdutosId] = useState(null);
  const [produtosList, setProdutosList] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [gruposProdutos, setGruposProdutos] = useState([]);
  const [expanded, setExpanded] = useState({});
  const { cacheDto, setCacheDto } = useCacheContext();

  useEffect(() => {
    buscaGruposProdutos();
    buscarProdutos();
  }, []);

  const buscaGruposProdutos = async (query) => {
    const filter = {
      nonPaginated: true,
      ...query,
    };
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: grupoProdutosService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setGruposProdutos(result.data);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const buscarProdutos = async (filtro) => {
    const filtros = {
      ...filtro,
    };
    setLoading(true);
    const res = await produtosService.getAll(filtros);
    if (!res.isAxiosError) {
      setProdutosList(res.data.rows);
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const handleOpenDialog = (id) => {
    setGrupoProdutosId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setGrupoProdutosId(null);
    setOpenDialog(false);
  };

  const handleDeleteGrupoProduto = async (id) => {
    const existeSubordinados = gruposProdutos.some((item) => item.idPai === id);
    const produto = produtosList.some((item) => item.grupoProdutoId === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir esse grupo de produto?`
    );
    if (retornoAlerta.isConfirmed) {
      if (existeSubordinados) {
        notification.alertaGenericos(
          "Precisa ser removido os grupos subordinados antes do superior"
        );
        return;
      }
      if (produto) {
        notification.alertaGenericos(
          "Não se pode remover grupo inserido em outros cadastros"
        );
        return;
      }
      setLoading(true);
      const result = await grupoProdutosService.deletar(id);
      if (!result.isAxiosError) {
        buscaGruposProdutos();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  return (
    <ListLayout
      title="Grupos de Produtos"
      loading={loading}
      onClickCadastrar={handleOpenDialog}
      permisaoCadastrar={true}
    >
      <AcordeonList
        items={gruposProdutos}
        handleEditItem={handleOpenDialog}
        handleDeleteItem={handleDeleteGrupoProduto}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="md"
        open={openDialog}
        aria-labelledby="form-dialog-title"
      >
        <AddGrupoProdutos
          id={grupoProdutosId}
          setCentrosCustos={setGruposProdutos}
          handleCloseDialog={handleCloseDialog}
          openDialog={openDialog}
          buscaGruposProdutos={buscaGruposProdutos}
        />
      </Dialog>
    </ListLayout>
  );
};

export default ListGrupoProdutos;
