import { useState, useEffect } from "react";
import { format } from "date-fns";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { history, RegraDescontoService, notification } from "../../../services";
import { responseErros, formatDate } from "../../../utils";
import { FormFiltroAvancado } from "./components";

const ListRegraDesconto = () => {
  const regraDescontoService = new RegraDescontoService();
  const [loading, setLoading] = useState(false);
  const [regrasDesconto, setRegrasDesconto] = useState([]);
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 500,
      filtrar: true,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 700,
    },
    {
      field: "situacao",
      headerName: "Situação",
      flex: 500,
      valueGetter: (params) => (params.value ? "Ativado" : "Desativado"),
    },
    {
      field: "dataInicial",
      headerName: "Data inicial",
      flex: 500,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
    {
      field: "dataFinal",
      headerName: "Data final",
      flex: 500,
      valueGetter: (params) =>
        format(formatDate.received(params.value), "dd/MM/yyyy"),
    },
  ];

  useEffect(() => {
    buscarRegrasDesconto();
  }, []);

  const buscarRegrasDesconto = async function (filter) {
    setLoading(true);
    const result = await regraDescontoService.getAll(filter);
    if (!result.isAxiosError) {
      setRegrasDesconto(result.data.rows);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarRegraDesconto = function () {
    history.push("/estoque/regras-desconto/create");
  };

  const onClickEditRegraDesconto = function (id) {
    history.push(`/estoque/regras-desconto/${id}`);
  };

  const onClickDeleteRegraDesconto = async function (id) {
    const data = regrasDesconto.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await regraDescontoService.deletar(id);
      if (!result.isAxiosError) {
        buscarRegrasDesconto();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const buscarPesquisaAvancada = async (filter) => {
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    if (
      filtrosAvancadosTemp.dataInicialStart &&
      filtrosAvancadosTemp.dataInicialEnd
    ) {
      porIntervalo.push({
        coluna: "dataInicial",
        de: filtrosAvancadosTemp.dataInicialStart,
        ate: filtrosAvancadosTemp.dataInicialEnd,
      });
    }
    if (
      filtrosAvancadosTemp.dataFinalStart &&
      filtrosAvancadosTemp.dataFinalEnd
    ) {
      porIntervalo.push({
        coluna: "dataFinal",
        de: filtrosAvancadosTemp.dataFinalStart,
        ate: filtrosAvancadosTemp.dataFinalEnd,
      });
    }
    delete filtrosAvancadosTemp.dataInicialStart;
    delete filtrosAvancadosTemp.dataInicialEnd;
    delete filtrosAvancadosTemp.dataFinalStart;
    delete filtrosAvancadosTemp.dataFinalEnd;
    const result = await regraDescontoService.getAllFiltroAvancado({
      ...filtrosAvancadosTemp,
      restritiva: true,
      porIntervalo,
    });
    if (!result.isAxiosError) {
      setRegrasDesconto(result.data.rows);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarRegrasDesconto(props.filtros);
    } else {
      buscarPesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Regras de desconto"
      loading={loading}
      onClickCadastrar={onClickCadastrarRegraDesconto}
    >
      <DataGrid
        rows={regrasDesconto}
        columns={colunas}
        onClickEdit={onClickEditRegraDesconto}
        onClickDelete={onClickDeleteRegraDesconto}
        paginationMode={"server"}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListRegraDesconto;
