import { useState, useEffect } from "react";
import { ListLayout } from "../../../layouts";
import { DataGrid } from "../../../components";
import { FormFiltroAvancado } from "./components";
import { BancosCaixasService, history, notification } from "../../../services";
import { cacheApi, responseErros } from "../../../utils";
import { filtrosUrl } from "../../../helpers";
import { useCacheContext } from "../../../contexts/cache.context";

const ListBancosCaixas = () => {
  const [loading, setLoading] = useState(false);
  const [contas, setContas] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const { cacheDto, setCacheDto } = useCacheContext();
  const colunas = [
    {
      field: "nome",
      headerName: "Nome Conta",
      flex: 750,
      filtrar: true,
    },
    {
      field: "ativado",
      headerName: "Status",
      flex: 500,
      valueGetter: (params) => {
        return params.value == true ? "Ativado" : "Desativado";
      },
    },
  ];
  const bancosCaixasService = new BancosCaixasService();

  useEffect(() => {
    const filtros = filtrosUrl.get();
    buscaContas(filtros);
  }, []);

  const buscaContas = async function (filter) {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: bancosCaixasService.getAll(filter),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setContas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
    setLoading(false);
  };

  const onClickCadastrarConta = function () {
    history.push("/financeiro/bancos-caixas/create");
  };

  const onClickEditarConta = function (id) {
    history.push(`/financeiro/bancos-caixas/${id}`);
  };

  const onClickDeleteBanco = async function (id) {
    const data = contas.find((item) => item.id === id);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      `Tem certeza que deseja excluir ${data.nome}`
    );
    if (retornoAlerta.isConfirmed) {
      setLoading(true);
      const result = await bancosCaixasService.deletar(id);
      if (!result.isAxiosError) {
        buscaContas();
        notification.deletadoSucesso();
      } else {
        setLoading(false);
        responseErros(result);
      }
    }
  };

  const handlePesquisaAvancada = async (filter) => {
    const label = filtrosUrl.getUrl(filter);
    setLoading(true);
    const porIntervalo = [];
    const filtrosAvancadosTemp = {
      ...filter,
    };
    const cacheBody = {
      dto: { cacheDto, setCacheDto },
      apiFunction: bancosCaixasService.getAllFiltroAvancado({
        ...filtrosAvancadosTemp,
        restritiva: true,
        porIntervalo,
      }),
    };
    const result = await cacheApi(label, cacheBody);
    if (!result.isAxiosError) {
      setContas(result.data.rows);
      setRowCount(result.data.count);
      filtrosUrl.set(filter);
    } else {
      responseErros(result);
    }
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscaContas(props.filtros);
    } else {
      handlePesquisaAvancada(props.filtros);
    }
  };

  return (
    <ListLayout
      title="Bancos e Caixas"
      loading={loading}
      onClickCadastrar={onClickCadastrarConta}
    >
      <DataGrid
        rows={contas}
        columns={colunas}
        onClickEdit={onClickEditarConta}
        onClickDelete={onClickDeleteBanco}
        paginationMode={"server"}
        rowCount={rowCount}
        sendServer={(props) => sendServerDatagrid(props)}
        FormFiltroAvancado={FormFiltroAvancado}
      />
    </ListLayout>
  );
};

export default ListBancosCaixas;
