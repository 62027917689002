import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Switch,
  Paper,
  Tabs,
  Tab,
  DatePicker,
  Autocomplete,
  TabPanel,
  CurrencyInput,
  CircularProgress,
  Button,
} from "../../../components";
import { AddLayout } from "../../../layouts";
import {
  MeioPagamentoService,
  notification,
  ContasReceberService,
  //PlanosFinanceirosService,
  //CentroCustoService,
  PessoaService,
  UsuarioService,
  BancosCaixasService,
  ContasReceberObservacoesService,
  CaixaService,
  history,
  ConfiguracoesService,
  ColaboradorService,
  SessionService,
} from "../../../services";
import {
  LinkTelasHelper,
  InputErros,
  value,
  PermisoesHelper,
} from "../../../helpers";
import {
  formatDate,
  userLogs,
  responseErros,
  formatPrice,
  unformatPrice,
  voltar,
} from "../../../utils";
import {
  DadosPagamento,
  ButtonMenu,
  Observacoes,
  DesdobrarTitulo,
  ReciboPrintLayout,
  MenuAddActions,
} from "./components";
import shortid from "shortid";
import contasReceberValidator from "./middlewares/add-contas-receber.validator";
import {
  tabProps,
  calcularValorTotal,
  calcularValorTotalAtualizado,
} from "./add-contas-receber";
import { useImportContext } from "../../../contexts/import.context";
import { ContaReceber } from "./entities";
import { calculaMultaJurosBaixa } from "../list-contas-receber/list-contas-receber";

const meioPagamentoService = new MeioPagamentoService();
const contasReceberService = new ContasReceberService();
//const planosFinanceiroService = new PlanosFinanceirosService();
//const centroCustoService = new CentroCustoService();
const pessoaService = new PessoaService();
const bancosCaixasService = new BancosCaixasService();
const usuarioService = new UsuarioService();
const linkTelasHelper = new LinkTelasHelper();
const contasReceberObservacoesService = new ContasReceberObservacoesService();
const caixaService = new CaixaService();
const configuracoesService = new ConfiguracoesService();
const sessionService = new SessionService();
const permisoesHelper = new PermisoesHelper();
const colaboradorService = new ColaboradorService();

const AddContaReceber = ({ match }) => {
  const { id } = match.params;
  const { contaReceberDto, initialCollapses } = useImportContext();
  const inputPagador = useRef();
  const printComponentRef = useRef(null);
  const [reciboData, setReciboData] = useState(null);
  const [abaValue, setAbaValue] = useState(0);
  const [inputErro, setInputErro] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAutoCompletePagador, setLoadingAutoCompletePagador] =
    useState(false);
  const [meioPagamento, setMeioPagamento] = useState({
    baixaAutomatica: false,
  });
  const [listaMeiosPagamentos, setListaMeiosPagamentos] = useState([]);
  const [listaContas, setListaContas] = useState([]);
  const [contaReceber, setContaReceber] = useState({
    pago: false,
    dataLancamento: new Date().toISOString(),
  });
  const [pagamento, setPagamento] = useState();
  const [dadosPagamentoList, setDadosPagamentoList] = useState([]);
  const [userLog, setUserLog] = useState({});
  const [observacao, setObservacao] = useState("");
  const [observacaoList, setObservacaoList] = useState([]);
  const [pagadorList, setPagadorList] = useState([]);
  const [parametrosFiscais, setParametrosFiscais] = useState({});
  const [colaborador, setColaborador] = useState({});
  const dadosUsuario = sessionService.getUsuario();
  //const [listaPlanosFinanceiros, setListaPlanosFinanceiros] = useState([]);
  //const [centroCustoList, setCentroCustoList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-add-actions-menu";
  const DADOS_PAGAMENTO = 0;
  const OBSERVACOES = 1;
  const contasReceberValidatorService = contasReceberValidator(meioPagamento);
  const inputErros = new InputErros(inputErro, setInputErro);

  async function buscarDadosIniciais() {
    await buscarConfiguracoes();
    await buscarPessoaVendedor();
    await buscarContaReceber();
    buscarMeiosPagamentos();
    buscarContas();
  }

  useEffect(() => {
    buscarDadosIniciais();
  }, []);

  async function buscarPessoaVendedor(filtros) {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      vendedor: true,
    };
    const result = await colaboradorService.getAll(filtro);
    if (!result.isAxiosError) {
      const colaboradorVendedorUsuario = result.data.find(
        (colaborador) => colaborador.usuarioId === dadosUsuario.id
      );
      const permissoes = await permisoesHelper.getPermisoesModulo("caixa");
      if (colaboradorVendedorUsuario && permissoes?.utilizarCaixaColaborador) {
        setPagamento((prevState) => ({
          ...prevState,
          bancoCaixaId: colaboradorVendedorUsuario.bancoCaixaId,
        }));
        setColaborador(colaboradorVendedorUsuario);
      }
    } else {
      responseErros(result);
    }
  }

  const buscaUsuario = async (listaIdUsuario) => {
    if (listaIdUsuario.length) {
      const filtro = {
        nonPaginated: true,
        id: listaIdUsuario,
      };
      usuarioService.getAll(filtro).then((result) => {
        if (!result.isAxiosError) {
          setObservacaoList((prevState) => {
            const observacaoAlterada = prevState.map((observacaoItem) => {
              const usuario = result.data.find(
                (item) => item.id == observacaoItem.insertUserId
              );
              observacaoItem.insertUserId = usuario.nome;
              return observacaoItem;
            });
            return observacaoAlterada;
          });
        } else {
          responseErros(result);
        }
      });
    }
  };

  const buscarContaReceber = async () => {
    if (id) {
      setLoading(true);
      const result = await contasReceberService.getById(id);
      if (!result.isAxiosError) {
        if (!result.data) {
          initialCollapses();
          voltar();
          return;
        }
        buscarPagador({ id: result.data.pagadorId });
        setDadosContasReceber(result.data);
        userLogs(result.data).then((logs) => {
          setUserLog(logs);
        });
        setDadosPagamentoList(
          result.data.pagamentos.map((pagamento) => ({
            ...pagamento,
            horaPagamento: pagamento.dataPagamento,
          }))
        );
        setObservacaoList(result.data.observacaoList);
        const listaIdUsuario = result.data.observacaoList.map(
          (observacao) => observacao.insertUserId
        );
        buscaUsuario(listaIdUsuario);
        buscaMeioPagamentoById(result.data.meioPagamentoId);
      } else {
        responseErros(result);
      }
      setLoading(false);
    } else {
      const linkBody = linkTelasHelper.get();
      if (linkBody) {
        setContaReceber(linkBody);
      } else {
        setContaReceber(contaReceberDto);
      }
    }
  };

  const buscarConfiguracoes = async () => {
    if (!id) return;
    configuracoesService.get().then((result) => {
      if (!result.isAxiosError) {
        const data = result.data.rows[0];
        const parametrosFiscaisTemp = {
          jurosPorcentagem: data?.jurosPorcentagem ?? 0,
          multaPorcentagem: data?.multaPorcentagem ?? 0,
          carencia: data?.carencia ?? 0,
        };
        setParametrosFiscais(parametrosFiscaisTemp);
      }
    });
  };

  /* const buscarCentrosCusto = async function () {
    const filtros = {
      nonPaginated: true,
    };
    const result = await centroCustoService.getAll(filtros);
    if (!result.isAxiosError) {
      setCentroCustoList(result.data);
    } else {
      responseErros(result);
    }
  }; */

  const buscarContas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
    };
    const result = await bancosCaixasService.getAll(filtros);
    if (!result.isAxiosError) {
      setListaContas(result.data);
    } else {
      responseErros(result);
    }
  };

  const buscarPagador = async (filtros) => {
    const filtro = {
      ...filtros,
      nonPaginated: true,
      ativado: true,
    };
    pessoaService.getAll(filtro).then((res) => {
      setLoadingAutoCompletePagador(false);
      if (!res.isAxiosError) {
        setPagadorList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  /* const buscarPlanosFinanceiros = () => {
    const filtros = {
      nonPaginated: true,
    };
    planosFinanceiroService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setListaPlanosFinanceiros(res.data);
      } else {
        responseErros(res);
      }
    });
  }; */

  const buscarMeiosPagamentos = () => {
    const filtros = {
      ativado: true,
      nonPaginated: true,
    };
    if (contaReceberDto?.tipo === "NOTA_CREDITO") {
      filtros.nfePagamento = "fpCreditoLoja";
    }
    meioPagamentoService.getAll(filtros).then((res) => {
      if (!res.isAxiosError) {
        setListaMeiosPagamentos(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  async function buscaMeioPagamentoById(id) {
    meioPagamentoService.getById(id).then((result) => {
      if (!result.isAxiosError) {
        setMeioPagamento(result.data);
      } else {
        responseErros(result);
      }
    });
  }

  function setDadosContasReceber(contaReceber) {
    const valorTotal = calcularValorTotal(contaReceber);
    const contaReceberTemp = {
      ...contaReceber,
      valorTotal,
      saldoReceber: calcularValorTotalAtualizado(contaReceber),
    };
    contaReceberTemp.saldoReceber = calculaMultaJurosBaixa(
      contaReceberTemp,
      parametrosFiscais
    );
    setContaReceber(contaReceberTemp);
    const pagamentoTemp = {
      ...parametrosFiscais,
      tipoBaixa: "BAIXA_NORMAL",
      dataPagamento: new Date(),
      bancoCaixaId: meioPagamento?.bancoCaixaId,
      valor: calcularValorTotalAtualizado(contaReceber),
    };
    if (contaReceber.status === "BAIXADO") {
      const ultimoPagamento =
        contaReceber.pagamentos[contaReceber.pagamentos.length - 1];
      const { bancoCaixaId, dataPagamento, meioPagamentoId, juros, multa } =
        ultimoPagamento;
      Object.assign(pagamentoTemp, {
        bancoCaixaId,
        dataPagamento,
        meioPagamentoId,
        juros,
        multa,
      });
    }
    setPagamento(pagamentoTemp);
  }

  const onSearchChangePagador = (event) => {
    if (
      event.key === "Enter" &&
      inputPagador.current === document.activeElement
    ) {
      const eventValue = event.target?.value;
      let filtros = {
        nomeRazaoSocial: eventValue,
        apelidoFantasia: eventValue,
      };
      setLoadingAutoCompletePagador(true);
      buscarPagador(filtros);
    }
  };

  const onClickSalvar = (indiceSelecionado) => {
    const body = {
      ...contaReceber,
      observacaoList,
      bancoCaixaId: meioPagamento?.bancoCaixaId || contaReceber?.bancoCaixaId,
      pagamentos: dadosPagamentoList,
    };
    validarContaReceber(body, indiceSelecionado);
  };

  const validarStatusCaixa = async (dataPagamento, bancoCaixaId) => {
    const result = await caixaService.caixaAberto(dataPagamento, bancoCaixaId);
    if (!result.isAxiosError) {
      return result.data;
    } else {
      responseErros(result);
    }
  };

  const validarContasReceber = (body) => {
    if (!meioPagamento?.baixaAutomatica && !body.pagadorId) {
      notification.alertaGenericos("É necessário informar cliente pagador");
      setLoading(false);
      return false;
    }
    return true;
  };

  const validarContaReceber = (body, indiceSelecionado) => {
    setInputErro([]);
    contasReceberValidatorService
      .validate(body, { abortEarly: false })
      .then(async () => {
        const isValid = validarContasReceber(body);
        if (!isValid) return;
        const dadosContaReceber = new ContaReceber(
          body,
          meioPagamento,
          colaborador
        );
        if (id) {
          setLoading(true);
          await atualizarContaReceber(id, dadosContaReceber);
          setLoading(false);
        } else {
          if (dadosContaReceber?.baixaAposInclusao) {
            const caixaAberto = await validarStatusCaixa(
              dadosContaReceber.dataLancamento,
              dadosContaReceber.bancoCaixaId
            );
            if (!caixaAberto) {
              const caixaSelecionado = listaContas.find(
                (conta) => conta.id === dadosContaReceber?.bancoCaixaId
              );
              if (caixaSelecionado?.aberturaAutomatica) {
                await cadastrarCaixa(
                  new Date(),
                  dadosContaReceber?.bancoCaixaId,
                  true
                );
              } else return notification.alertaGenericos("Caixa Fechado!");
            }
            setLoading(true);
            await salvarContasReceber(dadosContaReceber, indiceSelecionado);
            setLoading(false);
          } else {
            setLoading(true);
            await salvarContasReceber(dadosContaReceber, indiceSelecionado);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        inputErros.set(err);
      });
  };

  const cadastrarCaixa = async (dataCadastro, bancoCaixaId, aberto) => {
    setLoading(true);
    const body = {
      data: dataCadastro,
      bancoCaixaId,
      aberto,
    };
    const result = await caixaService.cadastrar(body);
    if (result.isAxiosError) {
      setLoading(false);
      responseErros(result);
    }
  };

  const onChangeAba = (e, value) => {
    setAbaValue(value);
  };

  const onChangeAutocomplete = (name, value) => {
    setContaReceber({
      ...contaReceber,
      [name]: value ? value.id : undefined,
    });
  };

  const onBlurInput = (event) => {
    const contaReceberTemp = {
      ...contaReceber,
      [event.target.name]: unformatPrice(event.target.value),
    };
    contaReceberTemp.valorTotal = calcularValorTotal(contaReceberTemp);
    contaReceberTemp.saldoReceber =
      contaReceberTemp.valorTotal -
      parseFloat(contaReceberTemp.pagamentosParciais ?? 0);
    setContaReceber(contaReceberTemp);
  };

  const onChangeInput = (event) => {
    setContaReceber({
      ...contaReceber,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date, fieldName) => {
    if (!date) {
      date = "Invalid Date";
    }
    setContaReceber((prevState) => ({
      ...prevState,
      [fieldName]: date != "Invalid Date" ? formatDate.toSend(date) : null,
    }));
  };

  const handleObservacao = async (event) => {
    event.preventDefault();
    if (id) {
      cadastrarObservacao(id);
    } else {
      setObservacaoList((prevState) => [
        ...prevState,
        {
          id: shortid.generate(),
          observacao,
        },
      ]);
      setObservacao("");
    }
  };

  const buscarObservacoes = (contasReceberId) => {
    const filter = {
      nonPaginated: true,
      contasReceberId,
    };
    contasReceberObservacoesService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setObservacaoList(res.data);
        const listaIdUsuario = res.data.map(
          (observacao) => observacao.insertUserId
        );
        buscaUsuario(listaIdUsuario);
      } else {
        responseErros(res);
      }
    });
  };

  const cadastrarObservacao = async (id) => {
    setLoading(true);
    const body = {
      observacao,
      contasReceberId: id,
    };
    const res = await contasReceberObservacoesService.cadastrar(body);
    setLoading(false);
    if (!res.isAxiosError) {
      setObservacao("");
      buscarObservacoes(id);
      notification.cadastroSucesso();
    } else {
      responseErros(res);
    }
  };

  const handleChangeObservacao = (e) => {
    setObservacao(e.target.value);
  };

  const handleChangeSwitch = (event) => {
    setInputErro([]);
    const contaReceberTemp = {
      ...contaReceber,
      [event.target.name]: event.target.checked,
    };
    setContaReceber(contaReceberTemp);
  };

  const removerObservacao = async (id) => {
    setLoading(true);
    const res = await contasReceberObservacoesService.deletar(id);
    setLoading(false);
    if (!res.isAxiosError) {
      const data = observacaoList.filter((item) => item.id !== id);
      setObservacaoList(data);
      notification.deletadoSucesso();
    } else {
      responseErros(res);
    }
  };

  const limparFormularios = () => {
    setAbaValue(0);
    setInputErro([]);
    setContaReceber({});
    setObservacaoList([]);
    setDadosPagamentoList([]);
  };

  const salvarContasReceber = async (dadosContaReceber, indiceSelecionado) => {
    const cliente = pagadorList.find(
      (item) => item.id == contaReceber.pagadorId
    );
    setLoading(true);
    const res = await contasReceberService.cadastrar(dadosContaReceber);
    setLoading(false);
    if (!res.isAxiosError) {
      if (id || contaReceber.baixaAposInclusao) {
        setReciboData({
          cliente,
          titulo: res.data,
          pagamentos: res.data?.pagamentos ? [res.data.pagamentos[0]] : [],
        });
        handlePrint();
      }
      notification.cadastroSucesso();
      if (indiceSelecionado === 0) {
        history.goBack();
      } else {
        limparFormularios();
      }
    } else {
      responseErros(res);
    }
  };

  const atualizarContaReceber = async (idContaReceber, dadosContaReceber) => {
    dadosContaReceber.removerObservacaoList(idContaReceber);
    const res = await contasReceberService.atualizar(
      idContaReceber,
      dadosContaReceber
    );
    if (!res.isAxiosError) {
      notification.alteracaoSucesso();
      history.push("/financeiro/contas-receber");
    } else {
      const error = res.response ? res.response.data.error : res.message;
      notification.erroValidacao(error);
    }
    setLoading(false);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle: `
      @page {
        size: auto;
        margin: 8mm;
      }
    `,
  });

  const handleAddActionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <AddLayout
      id={id}
      title={id ? "Editar Conta a Receber" : "Cadastrar Conta a Receber"}
      onClickSalvar={onClickSalvar}
      loading={loading}
      userLog={id ? userLog : null}
      codigo={id ? `Nº Título - ${contaReceber.numeroTitulo}` : null}
      disableAtualizar={contaReceber?.status === "RENEGOCIADO"}
      ButtonMenu={
        <ButtonMenu
          contaReceber={contaReceber}
          setContaReceber={setContaReceber}
        />
      }
      actions={
        <div>
          {!contaReceber?.id ? (
            <FormControlLabel
              control={
                <Switch
                  id="baixaAposInclusao"
                  name="baixaAposInclusao"
                  checked={
                    contaReceber?.baixaAposInclusao
                      ? contaReceber?.baixaAposInclusao
                      : false
                  }
                  onChange={handleChangeSwitch}
                  color="primary"
                />
              }
              label={`Baixa após inclusão`}
            />
          ) : (
            <Button
              aria-label="add action conta receber"
              aria-controls={menuId}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : "false"}
              onClick={handleAddActionsOpen}
              color="inherit"
              style={{
                borderRadius: 5,
                backgroundColor: "#E5E5E5",
              }}
            >
              <span style={{ fontSize: 10 }}>mais ações</span>
            </Button>
          )}
        </div>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="pagadorId"
              name="pagadorId"
              options={pagadorList}
              autoHighlight
              loading={loadingAutoCompletePagador}
              disabled={contaReceber?.id ? true : false}
              getOptionLabel={(option) =>
                option.nomeRazaoSocial
                  ? `${option.id}` + ` - ` + `${option.nomeRazaoSocial}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option?.nomeRazaoSocial === value?.nomeRazaoSocial
              }
              noOptionsText="Digite e pressione Enter"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pagador"
                  variant={contaReceber?.id ? "filled" : "outlined"}
                  onKeyDown={(e) => onSearchChangePagador(e)}
                  inputRef={inputPagador}
                  error={inputErros.get("pagadorId")}
                  required={!meioPagamento?.baixaAutomatica}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        <React.Fragment>
                          {loadingAutoCompletePagador ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      </>
                    ),
                  }}
                />
              )}
              onChange={(e, value) => onChangeAutocomplete("pagadorId", value)}
              value={value.autoComplete(pagadorList, contaReceber?.pagadorId)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="numeroTitulo"
            name="numeroTitulo"
            label="Nº Título"
            variant="filled"
            margin="normal"
            disabled
            value={value.text(contaReceber?.numeroTitulo)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="origem"
            name="origem"
            label="Origem"
            variant="filled"
            margin="normal"
            disabled
            value={value.text(contaReceber?.origem)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataLancamento"
            name="dataLancamento"
            label="Data de Lançamento"
            format="dd/MM/yyyy"
            variant="filled"
            margin
            disabled
            value={
              contaReceber?.dataLancamento ? contaReceber.dataLancamento : null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            id="dataVencimento"
            name="dataVencimento"
            label="Data de Vencimento"
            format="dd/MM/yyyy"
            variant={
              id &&
              contaReceber?.origem !== "MANUAL" &&
              contaReceber?.status !== "ABERTO"
                ? "filled"
                : "outlined"
            }
            disabled={
              id &&
              contaReceber?.origem !== "MANUAL" &&
              contaReceber?.status !== "ABERTO"
                ? true
                : false
            }
            margin
            error={inputErros.get("dataVencimento")}
            required
            value={
              contaReceber?.dataVencimento ? contaReceber.dataVencimento : null
            }
            onChange={(date) => handleDateChange(date, "dataVencimento")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="meioPagamentoId"
              name="meioPagamentoId"
              noOptionsText="Sem opções"
              options={listaMeiosPagamentos}
              autoHighlight
              getOptionLabel={(option) =>
                option?.descricao ? option?.descricao : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Meio de Pagamento"
                  variant="outlined"
                  required
                  error={inputErros.get("meioPagamentoId")}
                />
              )}
              onChange={(e, value) => {
                onChangeAutocomplete("meioPagamentoId", value);
                setMeioPagamento(value);
              }}
              value={value.autoComplete(
                listaMeiosPagamentos,
                contaReceber?.meioPagamentoId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="valor"
            name="valor"
            label="Valor Bruto (R$)"
            variant={
              id &&
              contaReceber?.origem !== "MANUAL" &&
              contaReceber?.status !== "ABERTO"
                ? "filled"
                : "outlined"
            }
            disabled={
              id &&
              contaReceber?.origem !== "MANUAL" &&
              contaReceber?.status !== "ABERTO"
                ? true
                : false
            }
            error={inputErros.get("valor")}
            required
            value={value.text(contaReceber?.valor)}
            onBlur={onBlurInput}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="acrescimo"
            name="acrescimo"
            label="Acréscimo (R$)"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            value={contaReceber.acrescimo ?? "0"}
            onBlur={onBlurInput}
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="desconto"
            name="desconto"
            label="Desconto (R$)"
            variant={id ? "filled" : "outlined"}
            disabled={id ? true : false}
            value={contaReceber?.desconto ?? "0"}
            onBlur={onBlurInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyInput
            id="pagamentosParciais"
            name="pagamentosParciais"
            label="Pagamento Parcial (R$)"
            variant="filled"
            fullWidth
            value={contaReceber?.pagamentosParciais ?? "0"}
            disabled
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="planoFinanceiroId"
              name="planoFinanceiroId"
              options={listaPlanosFinanceiros}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao
                  ? `${option.id}` + ` - ` + `${option.descricao}`
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Plano Financeiro"
                  variant="outlined"
                  error={inputErros.get("planoFinanceiroId")}
                />
              )}
              onChange={(e, value) =>
                onChangeAutocomplete("planoFinanceiroId", value)
              }
              value={value.autoComplete(
                listaPlanosFinanceiros,
                contaReceber.planoFinanceiroId
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              required
              id="centroCustoId"
              name="centroCustoId"
              options={centroCustoList}
              autoHighlight
              getOptionLabel={(option) =>
                option.descricao
                  ? `${option.id}` + ` - ` + `${option.descricao}`
                  : ""
              }
              getOptionSelected={(option, value) =>
                option.descricao === value.descricao
              }
              noOptionsText="Sem opções"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Centro Custo"
                  variant="outlined"
                  error={inputErros.get("centroCustoId")}
                />
              )}
              onChange={(e, newValue) => {
                e.persist();
                onChangeAutocomplete("centroCustoId", newValue);
              }}
              value={value.autoComplete(
                centroCustoList,
                contaReceber.centroCustoId
              )}
            />
          </FormControl>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="historico"
            name="historico"
            label="Histórico"
            variant="outlined"
            margin="normal"
            value={value.text(contaReceber?.historico)}
            onChange={(e) => onChangeInput(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} className="mt-3">
          <Paper
            className="py-2 px-2 text-white text-center"
            elevation={4}
            sx={{ backgroundColor: "#2196F3" }}
          >
            Valor Total
            <br />
            <span className="fs-6">
              {formatPrice(parseFloat(contaReceber?.valorTotal)) || "R$ 0,00"}
            </span>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <CurrencyInput
            id="saldoReceber"
            name="saldoReceber"
            label="Saldo a Receber (R$)"
            variant="filled"
            fullWidth
            value={value.text(contaReceber?.saldoReceber) || "0"}
            disabled
          />
        </Grid>
      </Grid>
      {!id && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DesdobrarTitulo
              contaReceber={contaReceber}
              meioPagamento={meioPagamento}
              dadosPagamentoList={dadosPagamentoList}
              observacaoList={observacaoList}
              inputErros={inputErros}
              setInputErro={setInputErro}
            />
          </Grid>
        </Grid>
      )}
      {contaReceber?.id && (
        <Grid container spacing={2} className="mt-3">
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={abaValue}
                onChange={onChangeAba}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                scrollButtons="auto"
              >
                <Tab
                  label="Dados do Pagamento"
                  {...tabProps(DADOS_PAGAMENTO)}
                />
                <Tab label="Observações" {...tabProps(OBSERVACOES)} />
              </Tabs>
            </Paper>
            <TabPanel value={abaValue} index={DADOS_PAGAMENTO}>
              <DadosPagamento
                id={id}
                setLoading={setLoading}
                contaReceber={contaReceber}
                setContaReceber={setContaReceber}
                setDadosContaReceber={setDadosContasReceber}
                pagamento={pagamento}
                setPagamento={setPagamento}
                pagadorList={pagadorList}
                listaContas={listaContas}
                listaMeiosPagamentos={listaMeiosPagamentos}
                dadosPagamentoList={dadosPagamentoList}
                setDadosPagamentoList={setDadosPagamentoList}
                validarStatusCaixa={validarStatusCaixa}
                setReciboData={setReciboData}
                handlePrint={handlePrint}
                parametrosFiscais={parametrosFiscais}
                cadastrarCaixa={cadastrarCaixa}
                colaborador={colaborador}
              />
            </TabPanel>
            <TabPanel value={abaValue} index={OBSERVACOES}>
              <Observacoes
                observacao={observacao}
                handleChangeObservacao={handleChangeObservacao}
                handleObservacao={handleObservacao}
                removerObservacao={removerObservacao}
                observacaoList={observacaoList}
              />
            </TabPanel>
          </Grid>
        </Grid>
      )}
      <div style={{ display: "none" }}>
        <div ref={printComponentRef}>
          <ReciboPrintLayout data={reciboData} />
        </div>
      </div>
      <MenuAddActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        conta={contaReceber}
        pagamento={pagamento}
        listaMeiosPagamentos={listaMeiosPagamentos}
        dadosPagamentoList={dadosPagamentoList}
        listaContas={listaContas}
        pagadorList={pagadorList}
        setReciboData={setReciboData}
        handlePrint={handlePrint}
      />
    </AddLayout>
  );
};

export default AddContaReceber;
